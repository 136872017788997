import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

const getDefaultState = () => {
    return {
        isLoaded: false,
        address: {
            // maybe use names like streetName instead of streetname
            streetname: null,
            streetnumber: null,
            municipality: null,
            postalcode: null,
            boxnumber: null,
        },
        features: {
            buildingnotfound: false,
            parcelnotfound: false,
            main_parcel_not_found: false,
            extra_parcels_not_found: false,
            building_id: null,
            building_ids: [],
            secondary_building_ids: [],
            parcel_ids: [],
            parcels: [],
            f_epc_numeric: null,
            f_epc_label: null,
            f_x: null,
            f_y: null,
            f_lat: null,
            f_lng: null,
            f_building_type: null,
            f_parcel_area: null,
            f_building_area: null,
            f_garden_area: null,
            f_touching_sides: null,
            f_bedrooms: 0,
            f_epc_label: null,
            f_living_area: null,
            is_epc_number: true,
            region: null,
            renovation_cost: 0,
            construction_cost: null,
            parcel_cost: null,
            f_bedrooms: 0,
            f_balcony_present: null,
            f_bottom_floor: 0,
            f_top_floor: 0,
            f_floors: [],
            f_garden_common: null,
            f_garden_private: null,
            f_n_closed_garage: 0,
            f_n_parking_spot: 0,
            f_cellar_attic: null,
            f_construction_year: null,
        },
        estimateLoaded: false,
        avm_transaction: {
            body: {},
            status: null,
        },
        avm_rental: {
            body: {},
            status: null,
        },
        valuation: {
            date: null,
            market_value: null,
            reconstruction_value: null,
            forced_sale_value: null,
            rental_value: null,
            market_value_post_renovation: null,
            forced_sale_value_post_renovation: null,
            rental_value_post_renovation: null,
            renovation_adjustment: 0,
            cadastral_income: null,

            type: null,
            valuer: null,
            override_price: false,
            total_influence: 0,

            remarks: '',
            internal_remarks: '',

            influence: {
                style_of_house: 0,
                exterior_state: 0,
                facade_material: 0,
                roof_material: 0,

                n_roof_windows: 0,
                n_roof_dormers: 0,
                solar_panel_area: 0,

                f_building_listed: 0,
                garden_orientation: 0,
                outsidePool: 0,
                pool_size: 0,
                direct_traffic_level: 0,
                indirect_traffic_level: 0,
                surroundings: 0,
                environment: 0,
                other_positive: 0,
                other_negative: 0,
                marketCorrection: 0,
            },
        },
        map_scene2d: {
            parcel_geojson: null,
            building_geojson: null,
            address_geojson: null,
            flood_geojson: null,
        },
        map_scene3d: {},
        request: {},
        last_dvm_rejection: { incoherent_features: [] },
        dvm_rejections: [],
        view_options: {
            gsv_available: false, //GoogleStreetView available
            gsv_ph: null, // GoogleStreetView pov heading
            gsv_pp: null, // GoogleStreetView pov pitch
            gsv_pz: null, // GoogleStreetView pov zoom
            gsv_p: null, // GoogleStreetView pano id
            gsv_lat: null,
            gsv_lng: null,
        },
        dvm_features: {
            style_of_house: null,
            exterior_state: null,
            facade_material: [],
            roof_material: [],

            n_roof_windows: 0,
            n_roof_dormers: 0,
            solar_panel_area: 0,

            f_building_listed: false,
            garden_orientation: null,
            outsidePool: false,
            pool_size: 0,
            direct_traffic_level: null,
            indirect_traffic_level: null,
            surroundings: null,
            environment: null,
            other_positive: [],
            other_positive_details: '',
            other_negative: [],
            other_negative_details: '',
        },
        ovm_features: {
            garages: [],
            pool_house: false,
            pool_house_area: null,
            barn: false,
            barn_area: null,
            stables: false,
            stables_area: null,
        },
        prices_override: {},
        other_data: {
            extra_info_edited: false,
        },
        created_at: null,
    }
}

const state = getDefaultState()
const address = {
    namespaced: true,
    state,
    getters: {
        getAddress(state) {
            return {
                ...state.address,
                full_address: utils.full_address(state.address, state.features),
                via_address: utils.via_address(state.address, state.features),
                short_address: utils.short_address(state.address, state.features),
                estimationResultsData: state.avm_transaction?.body,
            }
        },
        get_epc(state) {
            let f_epc = null
            if (state.features.f_epc_numeric) {
                f_epc = state.features.f_epc_numeric
            } else if (state.features.region === null) {
                f_epc = null
            } else {
                f_epc =
                    utils.epc_mapping[state.features.region][
                        state.features.f_epc_label
                    ] ?? null
            }
            return f_epc
        },
        getFeatures(state, getters) {
            let f_number_of_facades = null
            if (state.features.f_touching_sides === 0) {
                f_number_of_facades = 'detached'
            } else if (state.features.f_touching_sides === 1) {
                f_number_of_facades = 'semi'
            } else if (state.features.f_touching_sides >= 2) {
                f_number_of_facades = 'attached'
            }

            if (!state.features.parcel_ids) {
                state.features.parcel_ids = []
            }

            return {
                ...state.features,
                f_epc: getters.get_epc,
                f_number_of_facades: f_number_of_facades,
            }
        },
        getDVMFeatures(state) {
            return state.dvm_features
        },
        getOVMFeatures(state) {
            return state.ovm_features
        },
        getAvmTransaction(state) {
            return state.avm_transaction
        },
        getAvmTransactionEstimations(state) {
            return state.avm_transaction.body.estimation
        },
        getAvmRentalEstimations(state) {
            return state.avm_rental.body.estimation
        },
        getEstimate(state) {
            return state.estimateLoaded
        },
        getFullBuildingData(state, getters) {
            let data = {
                ...state,
            }
            data.features.f_epc = getters.get_epc
            return data
        },
        getValuation(state, getters, rootState, rootGetters) {
            let valuation = { ...state.valuation }
            const status = rootGetters['valuationStore/status']
            const valuation_type = rootGetters['valuationStore/getValuationType']
            valuation.package_type =
                state.features.renovation_cost >= 10000 ? 'renovation_light' : 'classic'
            if (valuation.market_value === null && state.avm_transaction.status == 200) {
                valuation.type = 'avm'
                valuation.market_value =
                    Math.round(
                        state.avm_transaction.body.estimation.asking_price_q50 / 1000
                    ) * 1000
                valuation.valuer = '-'
                valuation.date = new Date(Date.now())
                if (state.avm_rental.status == 200 && valuation.rental_value === null) {
                    valuation.rental_value =
                        Math.round(
                            state.avm_rental.body.estimation.asking_price_q50 / 10
                        ) * 10
                }
                if (
                    state.avm_transaction.status == 200 &&
                    valuation.market_value &&
                    valuation.forced_sale_value === null
                ) {
                    valuation.forced_sale_value = utils.forced_sale_value(
                        valuation.market_value
                    )
                }
            }
            // temporary use only to develop ovm report
            // TODO: remove once not needed.
            if (
                valuation_type === 'ovm' &&
                status !== 'valued' &&
                state.avm_transaction.status == 200
            ) {
                valuation.type = 'ovm'
                if (
                    state.avm_transaction.status == 200 &&
                    valuation.market_value === null
                ) {
                    valuation.market_value =
                        Math.round(
                            state.avm_transaction.body.estimation.asking_price_q50 / 1000
                        ) * 1000
                }
                valuation.valuer = rootGetters['valuationStore/valuer'].email
                valuation.date = new Date(Date.now())
                if (state.avm_rental.status == 200 && valuation.rental_value === null) {
                    valuation.rental_value =
                        Math.round(
                            state.avm_rental.body.estimation.asking_price_q50 / 10
                        ) * 10
                } else if (valuation.rental_value) {
                    Math.round(valuation.rental_value / 10) * 10
                }
                if (
                    state.avm_transaction.status == 200 &&
                    valuation.market_value &&
                    valuation.forced_sale_value === null
                ) {
                    valuation.forced_sale_value = utils.forced_sale_value(
                        valuation.market_value
                    )
                }
            }
            if (
                !valuation.market_value_post_renovation &&
                valuation.date &&
                new Date(valuation.date) < new Date(2023, 8, 17, 0, 0, 0) &&
                valuation.package_type === 'renovation_light'
            ) {
                if (valuation.date < new Date(2022, 5, 10, 0, 0, 0)) {
                    valuation.market_value_post_renovation =
                        valuation.market_value + state.features.renovation_cost / 4
                    valuation.market_value =
                        valuation.market_value - state.features.renovation_cost / 2
                } else {
                    valuation.market_value_post_renovation =
                        valuation.market_value + (state.features.renovation_cost * 3) / 4
                }
                valuation.forced_sale_value_post_renovation = utils.forced_sale_value(
                    valuation.market_value_post_renovation
                )
            }

            if (
                !valuation.market_value_pre_construction &&
                valuation.date &&
                new Date(valuation.date) < new Date(2023, 8, 17, 0, 0, 0) &&
                state.features.f_parcel_cost
            ) {
                valuation.market_value_pre_construction = state.features.f_parcel_cost
                valuation.forced_sale_value_pre_construction = utils.forced_sale_value(
                    state.features.f_parcel_cost
                )
            }

            if (
                !valuation.construction_cost &&
                valuation.date &&
                new Date(valuation.date) < new Date(2023, 8, 17, 0, 0, 0) &&
                state.features.construction_cost
            ) {
                valuation.construction_cost = state.features.construction_cost
            }

            return valuation
        },
        getView(state) {
            return state.view_options
        },
        errorMessage(state) {
            if (state.avm_transaction.body.errors.length > 0) {
                return state.avm_transaction.body.errors[0].message
            } else {
                return null
            }
        },
        get_map_scene2d(state) {
            return state.map_scene_2d ? state.map_scene_2d : state.map_scene2d
        },
        get_map_scene3d(state) {
            let map_scene3d = { ...state.map_scene3d }
            map_scene3d.attributions = []
            return map_scene3d
        },
        isExtraInfoEdited(state) {
            return state.other_data.extra_info_edited
        },
        getRejections(state) {
            return state.dvm_rejections
        },
        getPricesOverride(state) {
            return state.prices_override
        },
        getPriceOverrideStatus(state) {
            return state.valuation.override_price
        },
        isRequestLoaded(state) {
            return state.isLoaded
        },
    },
    mutations: {
        SET_LOADED_STATE(state, loaded) {
            state.isLoaded = loaded
        },
        SET_ADDRESS(state, address) {
            Object.assign(state.address, address)
        },
        SET_CREATED_AT(state, created_at) {
            state.created_at = created_at
        },
        SET_FEATURES(state, features) {
            Object.assign(state.features, features)
        },
        SET_DVM_FEATURES(state, dvm_features) {
            Object.assign(state.dvm_features, dvm_features)
        },
        SET_OVM_FEATURES(state, ovm_features) {
            Object.assign(state.ovm_features, ovm_features)
        },
        RESET_INTERNAL_REMARKS(state) {
            Object.assign(state.valuation, { internal_remarks: '' })
        },
        RESET_VALUER_REMARKS(state) {
            Object.assign(state.valuation, { remarks: '' })
        },
        SET_INFLUENCE(state, influence) {
            Object.assign(state.valuation.influence, influence)
        },
        SET_TOTAL_INFLUENCE(state, total_influence) {
            state.valuation.total_influence = total_influence
        },
        SET_REJECTIONS(state, rejections) {
            state.dvm_rejections = rejections
        },
        SET_AVM(state, avm_results) {
            Object.assign(state.avm_transaction, avm_results.avm_transaction)
            Object.assign(state.avm_rental, avm_results.avm_rental)
        },
        SET_MAP_SCENE2D(state, map_scene2d) {
            state.map_scene2d = getDefaultState().map_scene2d
            Object.assign(state.map_scene2d, map_scene2d)
            state.map_scene2d.map_data_ready =
                state.map_scene2d.building_geojson !== null &&
                state.map_scene2d.parcel_geojson !== null &&
                state.map_scene2d.address_geojson !== null
        },
        SET_MAP_SCENE3D(state, map_scene3d) {
            state.map_scene3d = map_scene3d
        },
        SET_LAST_DVM_REJECTION(state, last_dvm_rejection) {
            Object.assign(state.last_dvm_rejection, last_dvm_rejection)
        },
        SET_VALUATION(state, valuation) {
            Object.assign(state.valuation, valuation)
        },
        SET_REQUEST(state, request) {
            Object.assign(state.request, request)
        },
        SET_VIEW(state, view) {
            state.view_options = view
        },
        SET_UNIVERSAL(state, { key, values }) {
            Object.assign(state[key], values)
        },
        RESET_STORE(state) {
            // for some reason, state = ... doesn't work, it isn't recognised as a use of the state parameter
            let {
                address,
                features,
                estimateLoaded,
                avm_transaction,
                avm_rental,
                valuation,
                map_scene2d,
                map_scene3d,
                request,
                last_dvm_rejection,
                dvm_rejections,
                view_options,
                dvm_features,
                deviation,
                deviation_override,
                other_data,
                isLoaded,
            } = getDefaultState()

            state.isLoaded = isLoaded
            state.address = address
            state.features = features
            state.estimateLoaded = estimateLoaded
            state.avm_transaction = avm_transaction
            state.avm_rental = avm_rental
            state.valuation = valuation
            state.map_scene2d = map_scene2d
            state.map_scene3d = map_scene3d
            state.request = request
            state.last_dvm_rejection = last_dvm_rejection
            state.dvm_rejections = dvm_rejections
            state.view_options = view_options
            state.dvm_features = dvm_features
            state.deviation = deviation
            state.deviation_override = deviation_override
            state.other_data = other_data
        },
        RESET_ADDRESS(state) {
            Object.assign(state.address, getDefaultState().address)
        },
        RESET_FEATURES(state, ignored = []) {
            for (const feature of Object.keys(state.features)) {
                if (!['f_building_type', ...ignored].includes(feature))
                    state.features[feature] =
                        typeof getDefaultState().features[feature] !== 'undefined'
                            ? getDefaultState().features[feature]
                            : null
            }
        },
        RESET_MAP(state) {
            state.map_scene2d = getDefaultState().map_scene2d
            state.avm_transaction = getDefaultState().avm_transaction
            state.avm_rental = getDefaultState().avm_rental
        },
        RESET_FULL_DATA(state) {
            Object.assign(state, getDefaultState())
        },
        SELECT_MAIN_BUILDING_ID(state, building_id) {
            state.features.building_id = building_id
            state.features.building_ids = [building_id]
            // Remove the main buildings from secondary buildings if applicable
            var index = state.features.secondary_building_ids.indexOf(building_id)
            if (index !== -1) {
                state.features.secondary_building_ids.splice(index, 1)
            }
        },
        TOGGLE_SECONDARY_BUILDING_ID(state, building_id) {
            // Never set the main building as a secondary building
            if (state.features.building_id === building_id) return
            var index = state.features.secondary_building_ids.indexOf(building_id)
            if (index === -1) {
                state.features.secondary_building_ids.push(building_id)
            } else {
                state.features.secondary_building_ids.splice(index, 1)
            }
        },
        SET_PARCEL_IDS(state, parcel_ids) {
            state.features.parcel_ids = parcel_ids
            let parcel_features = parcel_ids.map((id) =>
                state.map_scene2d.parcel_geojson.features.find(
                    (parcel) => parcel.properties.parcel_id === id
                )
            )
            let parcel_area = parcel_features
                .map((p) => p.properties.parcel_area)
                .reduce((a, b) => a + b, 0)
            state.features.f_parcel_area = Math.round(parcel_area * 10) / 10
        },
        SELECT_PARCELS(state, parcels) {
            state.features.parcel_ids = parcels.map(
                (parcel) => parcel.properties.parcel_id
            )
            let parcel_area = state.features.parcels
                .map((p) => p.properties.parcel_area)
                .reduce((a, b) => a + b, 0)
            state.features.f_parcel_area = Math.round(parcel_area * 10) / 10
        },
        TOGGLE_PARCEL(state, parcel) {
            let parcel_id = parcel.properties.parcel_id
            var index = state.features.parcel_ids.indexOf(parcel_id)
            if (index === -1) {
                state.features.parcel_ids.push(parcel_id)
                state.features.parcels.push(parcel)
            } else {
                state.features.parcel_ids.splice(index, 1)
                state.features.parcels.splice(index, 1)
            }
            let parcel_area = state.features.parcels
                .map((p) => p.properties.parcel_area)
                .reduce((a, b) => a + b, 0)
            state.features.f_parcel_area = Math.round(parcel_area * 10) / 10
        },
        SET_ESTIMATION_STATUS(state, status) {
            state.estimateLoaded = status
        },
        UPDATE_DVM_REJECTIONS(state, rejection) {
            if (!state.dvm_rejections || !Array.isArray(state.dvm_rejections)) {
                state.dvm_rejections = [rejection]
            } else {
                state.dvm_rejections.push(rejection)
            }
        },
        SET_OTHER_DATA(state, other_data) {
            Object.assign(state.other_data, other_data)
        },
        SET_PRICES_OVERRIDE(state, prices) {
            Object.assign(state.prices_override, prices)
        },
        SET_SPECIFIC_PRICE_OVERRIDE(state, data = {}) {
            let { key, price } = data

            if (typeof key !== 'undefined' && typeof price === 'number') {
                state.prices_override[key] = price
            }
        },
        SET_PRICES_OVERRIDE_STATE(state, val) {
            state.valuation.override_price = val
        },
        SET_EPC(state, val) {
            state.features.f_epc = +val
            state.features.f_epc_numeric = +val
        },
    },
    actions: {
        reset_store(context) {
            context.commit('RESET_STORE')
            return new Promise((res, rej) => {
                res()
            })
        },
        fetch_map_data(context) {
            return axios
                .get('/map/scene2d', {
                    params: {
                        building_id:
                            context.state.features.building_id ||
                            context.state.features.building_ids[0] ||
                            context.state.address.matched_building_id,
                    },
                })
                .then((response) => {
                    context.commit('SET_MAP_SCENE2D', response.data)
                })
        },
        fetch_map_data_parcel(context) {
            return axios
                .get('/map/scene2d', {
                    params: {
                        parcel_id: context.state.features.parcel_ids.join(';'),
                    },
                })
                .then((response) => {
                    context.commit('SET_MAP_SCENE2D', response.data)
                })
        },
        auto_select_parcels(context) {
            if (!context.state.map_scene2d?.building_geojson) {
                return
            }
            for (
                let i = 0;
                i < context.state.map_scene2d.building_geojson.features.length;
                i++
            ) {
                if (
                    [
                        context.state.features.building_id,
                        context.state.address.matched_building_id,
                    ].includes(
                        context.state.map_scene2d.building_geojson.features[i].properties
                            .building_id
                    )
                ) {
                    let parcel_ids =
                        context.state.map_scene2d.building_geojson.features[i].properties
                            .parcel_ids
                    context.commit('SET_PARCEL_IDS', parcel_ids)
                }
            }
            return new Promise((res, rej) => {
                res()
            })
        },
        match_address(context) {
            return axios
                .get('/features', {
                    params: {
                        streetnumber: context.state.address.streetnumber,
                        streetname: context.state.address.streetname,
                        postalcode: context.state.address.postalcode,
                        minimum_level: '0',
                    },
                })
                .then((response) => {
                    context.commit('RESET_FEATURES')
                    context.commit('RESET_MAP')
                    context.commit('SET_FEATURES', {
                        region: response.data.region,
                        level: response.data.level,
                        building_id: response.data.building_id,
                        building_ids: response.data.building_ids || [],
                        f_lat: response.data.f_lat,
                        f_lng: response.data.f_lng,
                    })
                })
        },
        fetch_features(context) {
            return axios
                .get(`/features/${context.state.features.building_id}`)
                .then((response) => {
                    response.data.f_building_type = context.state.features.f_building_type
                    response.data.building_id = context.state.features.building_id
                    response.data.building_ids = context.state.features.building_ids
                    response.data.parcel_ids = context.state.features.parcel_ids
                    response.data.f_parcel_area = context.state.features.f_parcel_area
                    response.data.region = context.state.features.region
                    context.commit('RESET_FEATURES')
                    context.commit('SET_FEATURES', response.data)
                })
        },
        fetchStreetviewInfo(context) {
            return axios
                .get('/meta/streetview', {
                    params: {
                        lat: context.state.features.f_lat,
                        lng: context.state.features.f_lng,
                    },
                })
                .then((response) => {
                    context.commit('SET_VIEW', {
                        gsv_available: response.status === 200,
                        gsv_ph: response.data.heading ? response.data.heading : null,
                        gsv_pp: 0,
                        gsv_pz: 1,
                        gsv_p: response.data.pano_id ? response.data.pano_id : null,
                        gsv_lat: response.data.lat ? response.data.lat : null,
                        gsv_lng: response.data.lng ? response.data.lng : null,
                    })
                })
                .catch((error) => {
                    context.commit('SET_VIEW', getDefaultState().view_options)
                })
        },
    },
}

export default address

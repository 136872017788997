<template>
    <b-dropdown v-if="submitableModules.length > 1" aria-role="list">
        <template #trigger>
            <slot name="default"></slot>
        </template>
        <b-dropdown-item
            v-for="m in submitableModules"
            :key="`appToggle_${m}`"
            aria-role="listitem"
            class="new_request-dropdown-item"
            has-link
        >
            <router-link :to="pathTo(m)">
                <svg-icon :icon="m" scale="1" />
                {{ $t(`value.short.${m}`) }}
            </router-link>
        </b-dropdown-item>
    </b-dropdown>
    <router-link
        v-else-if="submitableModules.length === 1"
        :to="pathTo(submitableModules[0])"
    >
        <slot name="default"></slot>
    </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'NewRequestButton',
    computed: {
        ...mapGetters('auth', ['submitableModules']),
    },
    methods: {
        pathTo(m) {
            if (m === 'ers') {
                return {
                    name: 'ers.welcome',
                    query: { ...this.$route.query },
                    params: { lang: this.$route.params.lang },
                }
            } else {
                return {
                    name: 'valuation.request',
                    query: { ...this.$route.query, valuation_type: m },
                    params: { lang: this.$route.params.lang, action: 'start' },
                }
            }
        },
    },
}
</script>

<template>
    <div class="valuer_tool-default-layout">
        <div
            v-if="getFeatures && getFeatures.building_id"
            class="valuer_tool-default-layout-map_container"
        >
            <re-map
                :building-id="getFeatures.building_id"
                :parcel-ids="getFeatures.parcel_ids"
                :level="getFeatures.level"
                :region="getFeatures.region"
                :center="{
                    lat: getFeatures.f_lat,
                    lng: getFeatures.f_lng,
                }"
                :map-scene2d="get_map_scene2d"
                :read-only="true"
                :focus-layer="'building'"
                :layers-control-visible="false"
                :options="{
                    zoomControl: true,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    doubleClickZoom: true,
                    boxZoom: true,
                    dragging: true,
                }"
                :aerial-tile-visible="false"
                :cadastral-tile-visible="false"
                class="has-ratio"
                @update:mapScene2d="SET_MAP_SCENE2D($event)"
            ></re-map>
        </div>
        <div class="valuer_tool-default-layout-grid">
            <h1 class="valuer_tool-title">Common parts</h1>
            <grid-card title="Certificates common parts" />
            <multi-choice-card
                feature="common_parts_state"
                config-location="ovm_features"
                small
            />
            <single-input-card
                feature="apartment_quotity"
                config-location="ovm_features"
            />
            <single-input-card feature="reserve_savings" config-location="ovm_features" />
            <common-garden />
            <common-solar-panels />
            <checkboxes-card feature="comfort" config-location="ovm_features" />
            <costs />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ReMap from '@/components/common/Map.vue'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import MultiChoiceCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/MultiChoiceCard.vue'
import CheckboxesCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/CheckboxesCard.vue'
import SingleInputCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard/variants/SingleInputCard.vue'
import CommonSolarPanels from './cards/CommonSolarPanels.vue'
import CommonGarden from './cards/CommonGarden.vue'
import Costs from './cards/Costs.vue'

export default {
    name: 'Commons',
    components: {
        ReMap,
        GridCard,
        CheckboxesCard,
        MultiChoiceCard,
        SingleInputCard,
        CommonSolarPanels,
        CommonGarden,
        Costs,
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures', 'get_map_scene2d']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_MAP_SCENE2D']),
    },
}
</script>

<template>
    <div class="renovations">
        <renovation-item
            v-for="(item, i) in computedRenovationList"
            :key="item.name"
            :selected="getRenovations.find((r) => r.name === item.name).selected"
            :report-index="i + 1"
            :tooltip="item.tooltip"
            :selectable="getSelectedPackage === 'custom'"
            :price="item.price"
            :name="item.name"
            :icon="getRenovationsAsset(`${item.index}-`)"
            @click="handleClick(item)"
        />
        <div
            v-if="getSelectedPackage !== 'custom' && !onReport && getScenarios.length > 2"
            class="add-button"
            @click="handleAddRemoveButton"
        >
            <img class="add-button__icon" src="@/assets/icons/plus-icon.svg" alt="" />
            <strong class="add-button__text">
                {{ $t('energy.result.button.add_remove') }}
            </strong>
        </div>
    </div>
</template>

<script>
import RenovationItem from './RenovationItem'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'RenovationGrid',
    components: {
        RenovationItem,
    },
    props: {
        onReport: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            icons: this.assets(),
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getRenovations',
            'getScenarios',
            'getScenariosNames',
            'getSelectedScenario',
            'getSelectedPackage',
            'getRenovationOptions',
            'getConstructionYear',
            'getFeatures',
            'getRenovationsSummary',
            'getCurrentScenario',
            'getVisibleRenovationList',
            'getSelectedRenovationList',
        ]),
        computedRenovationList() {
            if (!this.onReport) return this.getVisibleRenovationList
            else {
                return this.getVisibleRenovationList.filter((item) => item.selected)
            }
        },
    },
    watch: {
        getSelectedPackage(val) {
            if (val === 'custom') this.findScenario()
        },
    },
    methods: {
        ...mapActions('ersStore', ['track_event']),
        ...mapMutations('ersStore', [
            'SET_RENOVATION_SELECTED',
            'SET_SELECTED_SCENARIO',
            'SET_SELECTED_PACKAGE',
        ]),
        handleAddRemoveButton() {
            if (this.$config.TRACK) {
                this.track_event({
                    route: $route,
                    event: 'add_remove_button_clicked',
                })
            }
            this.SET_SELECTED_PACKAGE({
                packageName: 'custom',
            })
        },
        importAll(r) {
            return r.keys().map(r)
        },
        assets() {
            return this.importAll(
                require.context(`./renovations`, false, /\.(png|jpe?g|svg)$/)
            )
        },
        getRenovationsAsset(fileName) {
            return this.icons.filter((asset) => asset.indexOf(fileName) > -1)[0]
        },
        handleClick(item) {
            if (this.getSelectedPackage === 'custom') {
                const isCurrentRenovationSelected = this.getRenovations.find(
                    (r) => r.index === item.index
                ).selected
                if (
                    // exit case to avoid the case where no renovation are selected
                    this.getSelectedRenovationList.length === 1 &&
                    isCurrentRenovationSelected
                ) {
                    return
                }
                for (const [key, renovations] of Object.entries(
                    this.getRenovationOptions
                )) {
                    if (renovations.includes(item.name)) {
                        renovations.map((renovationName) => {
                            const index = this.getRenovations.find(
                                (r) => r.name === renovationName
                            ).index
                            this.SET_RENOVATION_SELECTED({
                                index: index,
                                value:
                                    renovationName === item.name &&
                                    !isCurrentRenovationSelected,
                            })
                        })
                    }
                }

                this.findScenario()
            }
        },
        findScenario() {
            // Select the right scenario for click & choose state
            if (!this.getRenovationsSummary.renovations.length) {
                // No renovation => Scenario is current state of the house
                this.SET_SELECTED_SCENARIO(this.getCurrentScenario)
            } else {
                // Get list of scenarios
                let possibleScenarios = [...this.getScenarios]

                let isSolarSelected = false
                let { renovations } = this.getRenovationsSummary

                // Remove scenarios related to more options than what's been selected
                possibleScenarios = possibleScenarios.filter(
                    (sceno) => sceno.name.split('_').length === renovations.length
                )

                // Remove scenarios related to options that aren't selected
                renovations.forEach((reno) => {
                    possibleScenarios = possibleScenarios.filter((sceno) => {
                        return sceno.name.split('_').includes(reno.name)
                    })
                })

                let newScenario = { ...possibleScenarios[0] }

                // Should be left with exactly one scenario, so take the first one of the array
                // Tested with a few different requests click & choosing a bunch of things
                this.SET_SELECTED_SCENARIO(newScenario)
                this.$router.push({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: {
                        ...this.$route.query,
                        package: this.getSelectedPackage,
                        scenario: this.getSelectedScenario.name,
                    },
                })
            }
        },
        getRenovationVatExcl(name, price) {
            const isOlderThanTenYo =
                new Date().getFullYear() - this.getFeatures.f_construction_year > 10
            const isEligibleRenovation = ['heatPump', 'unitPv'].includes(name)
            const priceVat =
                isOlderThanTenYo || isEligibleRenovation
                    ? Math.round(price / 1.06)
                    : Math.round(price / 1.21)
            return priceVat
        },
        getRenovationByName(renovationName) {
            const currentScenario = this.getScenarios.find(
                (scenario) => scenario.name === renovationName
            )
            const currentRenovation = this.getRenovations.find(
                (renovation) => renovation.name === renovationName
            )
            const price = currentScenario.total_investment_cost
            const index = currentRenovation.index
            const title = this.$t(`energy.result.renovation.${renovationName}`)
            const tooltip = this.$t(
                `energy.result.renovation.${renovationName}_tooltip_popup`
            )
            return {
                name: renovationName,
                price,
                subsidy: 123,
                index,
                tooltip,
                title,
                selected: false,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.renovations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 15px;
    width: 100%;
}

@media only screen and (max-width: 1400px) {
    .renovations {
        grid-template-columns: repeat(auto-fit, minmax(254.67px, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(254.67px, 1fr));
    }
}

@media print {
    .renovations {
        grid-template-rows: repeat(3, auto);
        align-items: start;
        height: fit-content;
        margin-block-start: 0;
    }
}

.add-button {
    display: flex;
    cursor: pointer;
    flex-grow: 1;
    place-self: baseline;
    grid-column-start: 1;
    margin-block-start: 1.5rem;
}

.add-button__text {
    margin-block: auto;
    margin-inline-start: 20px;
    color: #009cff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
</style>

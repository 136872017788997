<i18n>
    {
        "en-BE": {

        }
    }
</i18n>
<template>
    <div class="valuer-settings">
        <h1 class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold">
            Valuer settings
        </h1>
        <div class="columns is-multiline mt-4">
            <div class="column is-12">
                <b-tabs v-model="valuerTab" position="is-centered" class="block">
                    <b-tab-item :label="$t('availability')" value="availability">
                        <template v-if="loading">
                            <b-loading :is-full-page="false" :active="true"></b-loading>
                        </template>
                        <template v-else>
                            <cronofy-sync
                                :element-token="
                                    cronofyUser ? cronofyUser.cronofy_element_token : null
                                "
                                :authorization-url="cronofyRedirect"
                            />
                            <template v-if="cronofyUser">
                                <AvailabilityRules :cronofy-user="cronofyUser" />
                            </template>
                        </template>
                    </b-tab-item>
                    <b-tab-item :label="$t('appointments')" value="appointments">
                        <Summary :display-list="true"></Summary>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'
import CronofySync from './cronofyElements/CronofySync'
import AvailabilityRules from './AvailabilityRules'
import Summary from '../request/ovm/Summary'

export default {
    name: 'ValuerSettings',
    components: {
        CronofySync,
        AvailabilityRules,
        Summary,
    },
    data: () => ({
        cronofyUser: null,
        cronofyRedirect: '',
        loading: true,
        effectiveUserId: null,
        valuerTab: 'appointments',
    }),
    computed: {
        ...mapGetters('auth', ['userId']),
        queryUserId() {
            return this.$route.query.id
        },
    },
    async mounted() {
        if (!(this.queryUserId || this.userId)) {
            await this.$store.dispatch('auth/check_authentication')
        }
        await this.cronofyLoginCallback()
        await this.loadCronofyUser()
        this.loading = false
    },
    methods: {
        async loadCronofyUser() {
            const res = await this.$axios.get(
                utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'availability',
                    'user',
                    this.queryUserId || this.userId,
                ]),
                {
                    validateStatus: (s) => s == 200 || s == 307,
                }
            )

            const cronofyUrl = new URL(res.data.cronofy_url)
            cronofyUrl.searchParams.append('redirect_uri', window.location.href)
            this.cronofyRedirect = cronofyUrl.href
            if (res.status === 200) {
                this.cronofyUser = res.data
            }
        },
        async cronofyLoginCallback() {
            if (!this.$route.query.code) return

            const code = this.$route.query.code
            const state = this.$route.query.state
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    code: undefined,
                    state: undefined,
                },
            })

            await this.$axios.post(
                utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'availability',
                    'user',
                    this.userId,
                ]),
                {
                    code,
                    state,
                    redirect_uri: window.location.href,
                },
                {
                    validateStatus: () => true,
                }
            )
        },
    },
}
</script>

<style>
.valuer-settings {
    margin: auto;
    width: min(100%, 650px);
    padding: 1rem;
    height: 100%;
}
</style>

<i18n>
{
  "nl-BE": {
    "customer_ref_title": "Wat is de interne referentie van de lening?",
    "tooltip_text": "Hiermee wordt de link gelegd tussen de aanvraag en de lening.",
    "customer_ref_conflict": "Er bestaat reeds een aanvraag met dezelfde interne referentie. Om deze bestaande aanvraag te bekijken, klikt u op de taxatieverzoeken referentie hieronder:",
    "previous": "Vorige",
    "next": "Volgende",
    "field_required": "Dit veld is verplicht",
    "only_numbers": "Gelieve enkel cijfers in te vullen",
    "text_too_long": "Dit veld is te lang",
    "conflict_modal": {
        "title": "Er bestaat reeds een aanvraag met dezelfde interne referentie.",
        "content": "Om deze bestaande aanvraag te bekijken, klikt u op de Dashboard knop hieronder:",
        "cta": "Wilt u doorgaan met de gedupliceerde referentie?",
        "continue": "Doorgaan",
        "back": "Terug",
        "dashboard": "Bekijk in dashboard"
    },
    "ing": {
        "customer_ref_title": "Wat is de LOP ID van de lening?",
        "forbidden_substring": "Dit veld kan niet 0000000 bevatten",
        "customer_ref_conflict": "Er bestaat reeds een aanvraag met dezelfde LOP ID. Om dit bestaande aanvraag te bekijken, klikt u op de taxatieverzoeken referentie hieronder:"
    }
  },
  "en-BE": {
    "customer_ref_title": "What is the internal reference of the loan?",
    "tooltip_text": "This allows to link the valuation request with the loan.",
    "customer_ref_conflict": "There already is a valuation request with the same internal reference. To view this existing request, click on the valuation request reference below:",
    "previous": "Previous",
    "next": "Next",
    "field_required": "This field is required",
    "only_numbers": "Please enter only numeric values",
    "text_too_long": "This field is too long",
    "conflict_modal": {
        "title": "There already is a valuation request with the same internal reference.",
        "content": "To view this existing request, click on the Dashboard button below:",
        "cta": "Do you want to proceed with the duplicated reference?",
        "continue": "Continue",
        "back": "Back",
        "dashboard": "View in dashboard"
    },
    "ing": {
        "customer_ref_title": "What is the LOP ID of the loan?",
        "forbidden_substring": "This field cannot contain 0000000",
        "customer_ref_conflict": "There already is a valuation request with the same LOP ID. To view this existing request, click on the valuation request reference below:"
    }
  },
  "fr-BE": {
    "customer_ref_title": "Quelle est la référence interne du prêt ?",
    "tooltip_text": "Ceci permet de relier la demande d'évaluation au prêt.",
    "customer_ref_conflict": "Il existe déjà une demande d'évaluation avec cette référence interne. Pour visualiser cette demande existante, cliquez sur la référence de demande d'évaluation ci-dessous :",
    "previous": "Précédent",
    "next": "Suivant",
    "field_required": "Ce champ est obligatoire",
    "only_numbers": "Veuillez entrer uniquement des chiffres",
    "text_too_long": "Ce champ est trop long",
    "conflict_modal": {
        "title": "Il existe déjà une demande d'évaluation avec cette référence interne.",
        "content": "Pour visualiser cette demande déjà formulée, cliquez sur le bouton du tableau de bord ci-dessous:",
        "cta": "Voulez-vous poursuivre avec la référence en doublon?",
        "continue": "Continuer",
        "back": "Retour",
        "dashboard": "Voir dans le tableau de bord"
    },
    "ing": {
        "customer_ref_title": "Quel est le LOP ID du prêt ?",
        "forbidden_substring": "Ce champ ne peut contenir 0000000",
        "customer_ref_conflict": "Il existe déjà une demande d'évaluation avec ce LOP ID. Pour visualiser cette demande existante, cliquez sur la référence de demande d'évaluation ci-dessous :"
    }
  }
}
</i18n>

<template>
    <div class="container section is-fluid">
        <title-with-tooltip :label="t('tooltip_text')">
            <template #title>{{ $t('customer_ref_title') }}</template>
        </title-with-tooltip>
        <div class="columns is-mobile is-multiline">
            <div class="column is-9-fullhd is-10-tablet is-12-mobile">
                <form @submit.prevent="sendRequest">
                    <b-field
                        :message="[
                            !$v.customer_ref.required ? $t('field_required') : null,
                            !$v.customer_ref.numeric_validation
                                ? $t('only_numbers')
                                : null,
                            !$v.customer_ref.forbidden_substring
                                ? t('forbidden_substring')
                                : null,
                            !$v.customer_ref.maxLength ? $t('text_too_long') : null,
                        ]"
                        :type="{
                            'is-danger':
                                $v.customer_ref.$error ||
                                (!$v.$anyDirty &&
                                    getFullValuationStore.conflicting_valuation_requests
                                        .length > 0),
                            'is-success': !$v.customer_ref.$invalid,
                        }"
                    >
                        <b-input
                            v-model="customer_ref"
                            :icon-right="ckeckIcon"
                            :placeholder="customer_ref_placeholder"
                            :disabled="
                                getFullValuationStore.valuation_request_ref !== null
                            "
                            @blur="$v.customer_ref.$touch()"
                            @input="$v.customer_ref.$touch()"
                        ></b-input>
                    </b-field>
                </form>
            </div>
            <div class="column is-9-fullhd is-10-tablet is-12-mobile">
                <div class="is-flex is-justify-content-space-between">
                    <b-button
                        tag="router-link"
                        size="is-medium"
                        type="is-dark"
                        icon-left="arrow-left"
                        :to="{
                            name: 'valuation.request',
                            params: {
                                lang: $route.params.lang,
                                action: 'start',
                            },
                            query: $route.query,
                        }"
                    >
                        <span>{{ $t('previous') }}</span>
                    </b-button>

                    <b-button
                        size="is-medium"
                        type="is-primary"
                        icon-right="arrow-right"
                        :disabled="$v.customer_ref.$invalid"
                        @click="sendRequest"
                    >
                        <span>{{ $t('next') }}</span>
                    </b-button>
                </div>
            </div>
        </div>
        <b-modal
            v-model="isConflictModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="isConflictModalActive" class="exit-modal">
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('conflict_modal.title') }}
                </h2>
                <p>{{ $t('conflict_modal.content') }}</p>
                <p>{{ $t('conflict_modal.cta') }}</p>
                <div>
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        @click="SET_CONFLICTING_VALUATION_REQUESTS([])"
                    >
                        {{ $t('conflict_modal.back') }}
                    </b-button>
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        tag="router-link"
                        :to="{
                            name: 'dashboard',
                            params: { lang: $route.params.lang },
                            query: {
                                valuation_request_refs: getFullValuationStore.conflicting_valuation_requests.join(
                                    ','
                                ),
                            },
                        }"
                    >
                        {{ $t('conflict_modal.dashboard') }}
                    </b-button>
                    <b-button size="is-medium" type="is-primary" @click="ignoreConflict">
                        {{ $t('conflict_modal.continue') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue'
import { decimal, required } from 'vuelidate/lib/validators'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { localeMixin } from '@/components/mixins/locale.js'

let numeric_validation = (value) => {
    return Vue.prototype.$config?.CUSTOMER_REF_NUMERIC ? decimal(value) : true
}

let maxLength = (value) => {
    const maxLength = Vue.prototype.$config?.CUSTOMER_REF_MAX_LENGTH
    return !maxLength || maxLength >= String(value).length
}

let forbidden_substring = (value) => {
    // ING specific forbidden substring
    return Vue.prototype.$config?.CUSTOMER_REF_FORBIDDEN_SUBSTRING
        ? !value.includes('0000000')
        : true
}

export default {
    name: 'Enter',
    mixins: [localeMixin],
    data() {
        return {
            isActive: true,
            message: '',
            type: '',
            customer_ref: '',
            valuation_type: null,
        }
    },
    computed: {
        ...mapGetters('valuationStore', ['getFullValuationStore', 'isDraft']),
        ...mapGetters('auth', ['hasRole']),
        submitable_valuations() {
            return ['avm', 'dvm', 'ovm'].filter((m) => this.hasRole('submitter', m))
        },
        ckeckIcon() {
            return this.$v.customer_ref.$invalid ? '' : 'check'
        },
        customer_ref_placeholder() {
            return this.$config.CUSTOMER_REF_PLACEHOLDER
        },
        isConflictModalActive() {
            return (
                !this.$v.$anyDirty &&
                this.getFullValuationStore.conflicting_valuation_requests.length > 0
            )
        },
    },
    created() {
        if (this.getFullValuationStore.customer_ref) {
            this.customer_ref = this.getFullValuationStore.customer_ref
        }
        this.SET_CONFLICTING_VALUATION_REQUESTS([])
        if (this.getFullValuationStore.valuation_type) {
            this.customer_ref = this.getFullValuationStore.customer_ref
        }
        this.valuation_type =
            this.submitable_valuations.length === 1 || !this.$route.query.valuation_type
                ? this.submitable_valuations[0]
                : this.$route.query.valuation_type
    },
    validations: {
        customer_ref: {
            required,
            numeric_validation,
            forbidden_substring,
            maxLength,
        },
    },
    methods: {
        ...mapMutations('valuationStore', [
            'SET_CUSTOMER_REF',
            'SET_CONFLICTING_VALUATION_REQUESTS',
        ]),
        ...mapActions('valuationStore', ['create_valuation_request']),
        sendRequest() {
            if (this.$v.customer_ref.$invalid) return
            if (this.getFullValuationStore.valuation_request_ref === null) {
                this.SET_CONFLICTING_VALUATION_REQUESTS([])
                this.SET_CUSTOMER_REF(this.customer_ref)
                this.create_valuation_request({
                    customer_ref: this.customer_ref,
                    ignore_conflicts: false,
                    requested_valuation_type: this.valuation_type,
                }).then(() => {
                    this.$v.$reset()
                    if (
                        this.getFullValuationStore.conflicting_valuation_requests
                            .length === 0
                    ) {
                        this.$router.push({
                            name: 'valuation.request',
                            params: { lang: this.$i18n.locale, action: 'building-type' },
                            query: {
                                valuation_request_ref: this.getFullValuationStore
                                    .valuation_request_ref,
                            },
                        })
                    }
                })
            } else {
                this.$router.push({
                    name: 'valuation.request',
                    params: { lang: this.$i18n.locale, action: 'building-type' },
                    query: {
                        valuation_request_ref: this.getFullValuationStore
                            .valuation_request_ref,
                    },
                })
            }
        },
        ignoreConflict() {
            this.SET_CONFLICTING_VALUATION_REQUESTS([])
            this.SET_CUSTOMER_REF(this.customer_ref)
            this.create_valuation_request({
                customer_ref: this.customer_ref,
                ignore_conflicts: true,
            }).then(() => {
                this.$v.$reset()
                this.$router.push({
                    name: 'valuation.request',
                    params: { lang: this.$i18n.locale, action: 'building-type' },
                    query: {
                        valuation_request_ref: this.getFullValuationStore
                            .valuation_request_ref,
                    },
                })
            })
        },
    },
}
</script>

<style lang="scss">
.is {
    &-tootip-btn {
        height: 28px;
        width: 28px;
        border-radius: 100%;
        font-size: 18px;
    }
    &-valid {
        .icon {
            color: hsl(141, 71%, 48%) !important;
        }
    }
    &-invalid {
        .icon {
            color: hsl(348, 100%, 61%) !important;
        }
    }
}
.enter {
    .control.has-icons-right .icon.is-right i {
        border-radius: 100%;
        display: flex;
        width: 30px;
        height: 30px;
        color: hsl(141, 71%, 48%) !important;
        background-color: rgba(82, 216, 96, 0.2);
        justify-content: center;
        align-items: center;
    }
    .control.has-icons-right .icon.has-text-danger i {
        color: hsl(348, 100%, 61%) !important;
        background-color: hsla(348, 100%, 61%, 0.1) !important;
    }
}
</style>

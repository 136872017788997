<template>
    <div
        class="grid_card"
        :class="{
            'grid_card--full': isFull,
            'grid_card--no_padding': noPadding,
            'grid_card--stretch': !hasContent,
        }"
    >
        <div v-if="title || hasComment || hasHeaderSlot" class="grid_card-header">
            <h5 v-if="title" class="grid_card-title">
                {{ title }}
            </h5>
            <slot name="header" />
            <comment-button v-if="hasComment" />
        </div>
        <slot />
    </div>
</template>
<script>
import CommentButton from '../CommentButton.vue'
export default {
    name: 'GridCard',
    components: {
        CommentButton,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        isFull: {
            type: Boolean,
            default: false,
        },
        hasComment: {
            type: Boolean,
            default: false,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasHeaderSlot() {
            return this.$slots.hasOwnProperty('header')
        },
        hasContent() {
            return this.$slots.hasOwnProperty('default')
        },
    },
}
</script>
<style lang="scss" scoped>
.grid_card {
    padding: 24px;
    min-height: fit-content;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);
    overflow: hidden;
    align-self: start;

    &--stretch {
        align-self: stretch;
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        // height: 32px;
        margin-bottom: 24px;

        .grid_card-title {
            color: #001837;
            font-size: 18px;
            font-weight: 700;
            flex-grow: 2;
        }
    }

    &--full {
        grid-column: 1 / -1;
    }

    &--no_padding {
        padding: 0;
        padding-top: 24px;

        .grid_card-header {
            padding: 0 24px;
        }
    }

    & > *:only-child {
        height: 100%;
    }
}
</style>
<style lang="scss">
.grid_card-content_grid {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    &.three-cols {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .content_grid-item {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;

        &--full {
            grid-column: 1 / -1;
        }
    }
}

.grid_card-content_flex {
    display: flex;
    width: 100%;
    gap: 24px;
    flex-wrap: wrap;

    .content_flex-item {
        flex-grow: 1;
        &--full {
            flex-grow: 3;
        }
    }
}

.grid_card {
    .b-checkbox {
        margin-right: 0 !important;

        .control-label {
            color: #001837;
            font-size: 15px;
            font-weight: 500;
        }
    }

    .flex-label {
        .b-checkbox {
            margin-right: 0 !important;

            .control-label {
                display: none;
            }
        }
    }
}

.flex-label {
    display: flex;
    align-items: end;
    justify-content: flex-end;
    gap: 8px;
}
</style>

import { render, staticRenderFns } from "./Submitted.vue?vue&type=template&id=5c52f926&scoped=true"
import script from "./Submitted.vue?vue&type=script&lang=js"
export * from "./Submitted.vue?vue&type=script&lang=js"
import style0 from "./Submitted.vue?vue&type=style&index=0&id=5c52f926&prod&scoped=true&lang=scss"
import style1 from "./Submitted.vue?vue&type=style&index=1&id=5c52f926&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c52f926",
  null
  
)

export default component.exports
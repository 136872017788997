<template>
    <div
        :class="{
            'sidebar-section': true,
            'is-toggle': isToggle,
            interactive: hasClickListener,
            active: active,
        }"
        @click="$emit('click')"
    >
        <h2 class="sidebar-section-title">
            {{ title }}
        </h2>
        <img
            v-if="isToggle"
            :src="chevron"
            :class="{ toggled: toggled, chevron: true }"
        />
    </div>
</template>
<script>
import chevron from '@/shared/assets/icons/chevron.svg'

export default {
    name: 'SidebarSection',
    props: {
        title: {
            type: String,
            required: true,
        },
        isToggle: {
            type: Boolean,
            default: false,
        },
        toggled: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        chevron,
    }),
    computed: {
        hasClickListener() {
            return this.$listeners && this.$listeners.click
        },
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
.sidebar-section {
    border-bottom: 1px solid rgba(214, 226, 233, 1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 52px;
    padding: 0 16px;
    background: #fff;

    &.interactive {
        &:hover {
            cursor: pointer;
        }

        &:not(.is-toggle) {
            border-right: 0px solid #009cff;
            transition: all ease-out 0.2s;

            &:hover {
                border-right: 4px solid #009cff;
            }
        }
    }

    &.active {
        border-right: 4px solid #009cff !important;
        background: #f4f6f9;

        .sidebar-section-title {
            color: #009cff;
        }
    }

    .sidebar-section-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        color: #001837b2;
        transition: all ease-out 0.2s;
    }
}

img.chevron {
    width: 8px;
    &.toggled {
        transform: scaleY(-1);
    }
}
</style>

<template>
    <div class="terms-page">
        <div class="skyline" />
        <p class="is-size-5 mb-3">
            {{ $t('terms_page.welcome') }}
        </p>
        <p class="is-size-5 mb-3">
            {{ $t('terms_page.epc') }}
        </p>
        <p class="mb-5">
            {{ $t('terms_page.disclaimer') }}
        </p>
    </div>
</template>
<script>
export default {
    name: 'Terms',
}
</script>
<style lang="scss" scoped>
.terms-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #f4f6f9;
    background-size: cover;
    width: 100vw;
    min-height: 100vh;

    & > *:not(.skyline) {
        z-index: 1;
        max-width: 60%;
    }
}
</style>

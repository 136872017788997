var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{class:{ 'is-fixed-top': _vm.fixed },attrs:{"id":"navbar","type":"is-white"}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":_vm.email
                    ? {
                          path: '/',
                      }
                    : { name: 'login', params: { lang: _vm.$i18n.locale } }}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/icons/rockestate-logo-horizontal-dark.svg"),"width":"148"}})])],1),(_vm.showMenu)?_c('template',{slot:"start"},[(_vm.isValuer && _vm.isDispatchDashboard)?_c('div',{staticClass:"deployment-select mr-2"},[_c('b-select',{attrs:{"expanded":"","disabled":_vm.isLoading}},[_c('option',{attrs:{"disabled":""}},[_vm._v("Deployment")])])],1):_vm._e(),(_vm.isValuer)?_c('div',{ref:"reference_field",staticClass:"ref-search",class:{
                'apply-shake': _vm.shake,
            }},[_c('div',{staticClass:"ref-form"},[_c('b-input',{attrs:{"placeholder":"Go to DVM (enter ref)","disabled":_vm.isLoading},model:{value:(_vm.dvm_ref),callback:function ($$v) {_vm.dvm_ref=$$v},expression:"dvm_ref"}}),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary","disabled":_vm.isDvmRefEmpty || _vm.isLoading},on:{"click":function($event){return _vm.goToDvm(false)}}},[_vm._v(" Go ")]),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary","disabled":_vm.isDvmRefEmpty || _vm.isLoading},on:{"click":function($event){return _vm.goToDvm(true)}}},[_vm._v(" Go (new tab) ")])],1)]):_vm._e()]):_vm._e(),_c('template',{slot:"end"},[_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('new-request-button',[_c('b-button',{staticClass:"my-0",attrs:{"label":_vm.$t('dashboard.new_request.title'),"icon-left":"plus","size":"is-small","type":"is-primary"}})],1)],1),(_vm.hasRole('admin'))?_c('b-dropdown',{attrs:{"aria-role":"list","close-on-click":false,"right":"","paddingless":""},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-navbar-item',[_c('b-icon',{attrs:{"icon":"shield-account"}})],1)]},proxy:true}],null,false,1515181525)},[_c('b-dropdown-item',{staticClass:"roles-dropdown",attrs:{"aria-role":"listitem","paddingless":"","focusable":false,"custom":""}},[_c('admin-roles',{staticClass:"role-widget",attrs:{"roles":_vm.get_roles,"editable":true,"unremovable":['-:admin', '*:admin']},on:{"input":function($event){return _vm.update_roles($event)}}})],1)],1):_vm._e(),_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('div',{staticClass:"langs buttons has-addons my-0"},[_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': _vm.$i18n.locale === 'nl-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('nl-BE')}}},[_c('span',[_vm._v("NL")])]),_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': _vm.$i18n.locale === 'fr-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('fr-BE')}}},[_c('span',[_vm._v("FR")])]),_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': _vm.$i18n.locale === 'en-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('en-BE')}}},[_c('span',[_vm._v("EN")])]),(_vm.hasRole('developer'))?_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': _vm.$i18n.locale === 'technical',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('technical')}}},[_c('span',[_vm._v("Technical")])]):_vm._e()],1),_c('div',{staticClass:"mr-4 px-1 is-size-7 user",class:[_vm.email ? 'ml-6' : 'ml-4']},[_vm._v(" "+_vm._s(_vm.email)+" ")]),_c('div',{staticClass:"buttons"},[(_vm.email != null)?_c('a',{attrs:{"href":_vm.logout_url}},[_c('b-button',{attrs:{"size":"is-small","type":"is-primary","outlined":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('logout')))])])],1):_vm._e()])])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',{staticClass:"title is-size-2 mgt-medium is-family-secondary has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('PAYMENT_STATUS_TITLE'))+" ")]),_c('div',{staticClass:"payment"},[(_vm.errorMessage)?_c('div',{staticClass:"error-message p-2 has-text-centered"},[_c('p',[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),(_vm.stripeTestMode)?_c('b-message',{attrs:{"title":"Testing mode","type":"is-warning","aria-close-label":"Close message"}},[_vm._v(" This deployment is using the Stripe test mode. No real payment will be processed. "),_c('br'),_vm._v(" You can find the different test cards available "),_c('a',{attrs:{"href":"https://stripe.com/docs/testing","target":"_blank"}},[_vm._v("here.")])]):_vm._e(),_c('b-loading',{attrs:{"is-full-page":true,"active":!_vm.paymentSummary && !_vm.errorMessage}}),(_vm.paymentSummary)?[_c('p',{staticClass:"my-3"},[_c('em',[_vm._v("Ref: #"+_vm._s(_vm.valRef))])]),(_vm.paymentSummary.status && _vm.paymentSummary.status != 'started')?_c('nav',{class:[
                    'level',
                    'notification',
                    _vm.statusToClass(_vm.paymentSummary.status),
                ]},[_vm._v(" "+_vm._s(_vm.$t(`payment_status.${_vm.paymentSummary.status}`, { amount_paid: _vm.paymentSummary.total_paid_euro, }))+" "),(_vm.paymentProcessing)?_c('PulseLoader',{attrs:{"color":"white"}}):_vm._e()],1):_vm._e(),(_vm.paymentSummary.is_payment_needed)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('PAYMENT_NEEDED', { amount_due: _vm.amountDueEuro || _vm.paymentSummary.amount_due_euro, }))+" ")]):_vm._e(),(_vm.stripeClientSecret)?[_c('StripeElementPayment',{ref:"paymentRef",staticClass:"my-2",attrs:{"pk":_vm.STRIPE_PUBLISHABLE_KEY,"elements-options":{
                        appearance: {},
                        clientSecret: _vm.stripeClientSecret,
                    },"confirm-params":{ return_url: _vm.returnUrl }}})]:_vm._e(),_c('nav-buttons',{attrs:{"next-step":!_vm.paymentSummary.is_payment_needed ? _vm.nextStep : null,"prev-step":_vm.prevStep}},[(_vm.stripeClientSecret)?_c('b-button',{attrs:{"type":"is-primary","loading":_vm.isLoadingPayment},on:{"click":_vm.pay}},[_vm._v(" "+_vm._s(_vm.$t('PAY_BTN', { amount_due: _vm.amountDueEuro }))+" ")]):_vm._e()],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valuer_tool-default-layout"},[(_vm.getFeatures && _vm.getFeatures.building_id)?_c('div',{staticClass:"valuer_tool-default-layout-map_container"},[_c('re-map',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"level":_vm.getFeatures.level,"region":_vm.getFeatures.region,"center":{
                lat: _vm.getFeatures.f_lat,
                lng: _vm.getFeatures.f_lng,
            },"map-scene2d":_vm.get_map_scene2d,"read-only":true,"focus-layer":'building',"layers-control-visible":false,"options":{
                zoomControl: true,
                attributionControl: false,
                scrollWheelZoom: false,
                attributionControl: false,
                scrollWheelZoom: false,
                doubleClickZoom: true,
                boxZoom: true,
                dragging: true,
            },"aerial-tile-visible":false,"cadastral-tile-visible":false},on:{"update:mapScene2d":function($event){return _vm.SET_MAP_SCENE2D($event)}}})],1):_vm._e(),_c('div',{staticClass:"valuer_tool-default-layout-grid"},[_c('h1',{staticClass:"valuer_tool-title"},[_vm._v("Common parts")]),_c('grid-card',{attrs:{"title":"Certificates common parts"}}),_c('multi-choice-card',{attrs:{"feature":"common_parts_state","config-location":"ovm_features","small":""}}),_c('single-input-card',{attrs:{"feature":"apartment_quotity","config-location":"ovm_features"}}),_c('single-input-card',{attrs:{"feature":"reserve_savings","config-location":"ovm_features"}}),_c('common-garden'),_c('common-solar-panels'),_c('checkboxes-card',{attrs:{"feature":"comfort","config-location":"ovm_features"}}),_c('costs')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
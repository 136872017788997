<template>
    <grid-card title="Apartment features">
        <template slot="default">
            <div class="grid_card-content_flex">
                <div class="content_flex-item">
                    <b-field :label="$t('features.f_bottom_floor')">
                        <b-input v-model="getFeatures.f_bottom_floor" />
                    </b-field>
                </div>
                <div class="content_flex-item">
                    <b-field :label="$t('features.f_top_floor')">
                        <b-input v-model="getFeatures.f_top_floor" />
                    </b-field>
                </div>
                <div class="content_flex-item content_flex-item--full">
                    <b-field :label="$t('features.f_floors')">
                        <b-checkbox-button
                            v-for="(floor, i) in floors"
                            :key="`floor_button_${i}`"
                            v-model="getFeatures.f_floors"
                            :native-value="floor"
                        >
                            <span>{{ floor }}</span>
                        </b-checkbox-button>
                    </b-field>
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'ApartmentFeatures',
    components: {
        GridCard,
    },
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures']),
        floors() {
            let array = []
            for (
                let i = this.getFeatures.f_bottom_floor;
                i <= this.getFeatures.f_top_floor;
                i++
            ) {
                array.push(i)
            }
            return array
        },
    },
    mounted() {
        this.f_living_area = this.getFeatures.f_living_area
        this.f_parcel_area = this.getFeatures.f_parcel_area
        if (this.getFeatures.f_inner_volume) {
            this.f_inner_volume = this.getFeatures.f_inner_volume
        }
        if (this.is_house) {
            this.f_building_area = this.getFeatures.f_building_area
        }
    },
}
</script>

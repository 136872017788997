<template>
    <div class="general_page valuer_tool-default-layout">
        <b-loading v-if="!getFullValuationStore.Address.isLoaded" v-model="showLoading" />
        <template v-else>
            <div
                v-if="
                    getFullValuationStore.Address.features &&
                        getFullValuationStore.Address.features.building_id
                "
                class="valuer_tool-default-layout-map_container"
            >
                <re-map
                    :building-id="getFullValuationStore.Address.features.building_id"
                    :parcel-ids="getFullValuationStore.Address.features.parcel_ids"
                    :level="getFullValuationStore.Address.features.level"
                    :region="getFullValuationStore.Address.features.region"
                    :center="{
                        lat: getFullValuationStore.Address.features.f_lat,
                        lng: getFullValuationStore.Address.features.f_lng,
                    }"
                    :map-scene2d="getFullValuationStore.Address.map_scene2d"
                    :read-only="true"
                    :focus-layer="'building'"
                    :layers-control-visible="false"
                    :options="{
                        zoomControl: true,
                        attributionControl: false,
                        scrollWheelZoom: false,
                        attributionControl: false,
                        scrollWheelZoom: false,
                        doubleClickZoom: true,
                        boxZoom: true,
                        dragging: true,
                    }"
                    :aerial-tile-visible="false"
                    :cadastral-tile-visible="false"
                    class="has-ratio"
                    @update:mapScene2d="SET_MAP_SCENE2D($event)"
                ></re-map>
            </div>
            <div class="valuer_tool-default-layout-grid">
                <basic-info />
                <state />
                <size />
                <apartment-features v-if="is_apartment" />
                <value />
                <ref-points />
            </div>
        </template>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import ReMap from '@/components/common/Map.vue'
import { mapGetters, mapMutations } from 'vuex'
import ApartmentFeatures from './cards/ApartmentFeatures.vue'
import BasicInfo from './cards/BasicInfo.vue'
import State from './cards/State'
import Size from './cards/Size.vue'
import Value from './cards/Value'
import RefPoints from './cards/RefPoints'

export default {
    name: 'General',
    components: {
        ReMap,
        ApartmentFeatures,
        BasicInfo,
        State,
        Size,
        Value,
        RefPoints,
    },
    computed: {
        ...mapGetters('valuationStore', ['getFullValuationStore']),
        ...mapGetters('valuationStore/Address', ['getFeatures']),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
        showLoading() {
            return !this.getFullValuationStore.Address.isLoaded
        },
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_MAP_SCENE2D']),
    },
}
</script>
<style lang="scss" scoped></style>

<template>
    <div id="valuer_tool">
        <sidebar v-if="!isRouteFullWidth" />
        <div id="valuer_tool-content" :class="{ 'full-width': isRouteFullWidth }">
            <router-view />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Sidebar from './elements/Sidebar'

export default {
    name: 'ValuerTool',
    components: {
        Sidebar,
    },
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getFeatures',
            'getValuation',
            'getDVMFeatures',
            'getOVMFeatures',
        ]),
        isRouteFullWidth() {
            return this.$route.name === 'valuation.valuer-app.ref_map'
        },
    },
    watch: {
        '$route.name': function(val) {
            this.saveVal
        },
    },
    saveVal() {
        this.$axios
            .patch(
                utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'request',
                    this.getRequestRef,
                ]),
                {
                    valuation: this.getValuation,
                    dvm_features: this.getDVMFeatures,
                    ovm_info: this.getOVMFeatures,
                    features: this.getFeatures,
                }
            )
            .then((res) => {
                // TODO: Figure out if we want to let people know everytime we save
                this.$buefy.toast.open({
                    duration: 3000,
                    message: 'Valuation saved!',
                    position: 'is-bottom',
                    type: 'is-success',
                })
            })
    },
}
</script>
<style lang="scss">
#valuer_tool {
    display: grid;
    grid-template: auto / 300px auto;
    height: calc(100vh - 52px);

    & > *:not(#sidebar) {
        min-height: 100%;
    }

    #valuer_tool-content {
        overflow: scroll;
        background: #f4f6f9;

        &.full-width {
            grid-column: 1 / -1;
            align-self: stretch;
        }
    }

    .valuer_tool-title {
        color: #001837;
        font-size: 24px;
        font-weight: 700;
        grid-column: 1 / -1;

        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    .valuer_tool-default-layout {
        height: 100%;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;

        &-grid {
            display: grid;
            grid-template: auto / 1fr 1fr;
            padding: 64px;
            gap: 24px;
            width: 100%;
            height: auto;
            align-items: stretch;
        }

        &-map_container {
            height: 246px;
        }
    }

    .single_line_card {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        h5 {
            color: #798da6;
            font-size: 12px;
            font-weight: 500;
        }

        .is-expanded {
            flex-grow: 2;
        }
    }
}
</style>

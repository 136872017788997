var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exterior_page valuer_tool-default-layout"},[(
            _vm.getFullValuationStore.Address.features &&
                _vm.getFullValuationStore.Address.features.building_id
        )?_c('div',{staticClass:"valuer_tool-default-layout-map_container"},[_c('re-map',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFullValuationStore.Address.features.building_id,"parcel-ids":_vm.getFullValuationStore.Address.features.parcel_ids,"level":_vm.getFullValuationStore.Address.features.level,"region":_vm.getFullValuationStore.Address.features.region,"center":{
                lat: _vm.getFullValuationStore.Address.features.f_lat,
                lng: _vm.getFullValuationStore.Address.features.f_lng,
            },"map-scene2d":_vm.getFullValuationStore.Address.map_scene2d,"read-only":true,"focus-layer":'building',"layers-control-visible":false,"options":{
                zoomControl: true,
                attributionControl: false,
                scrollWheelZoom: false,
                attributionControl: false,
                scrollWheelZoom: false,
                doubleClickZoom: true,
                boxZoom: true,
                dragging: true,
            },"aerial-tile-visible":false,"cadastral-tile-visible":false},on:{"update:mapScene2d":function($event){return _vm.SET_MAP_SCENE2D($event)}}})],1):_vm._e(),_c('div',{staticClass:"valuer_tool-default-layout-grid"},[_c('h1',{staticClass:"valuer_tool-title"},[_vm._v("Exterior")]),_c('touching-sides'),_c('historical'),_c('multi-choice-card',{attrs:{"full":"","feature":"style_of_house"}}),_c('multi-choice-card',{attrs:{"full":"","feature":"exterior_state"}}),_c('multi-choice-card',{attrs:{"full":"","feature":"facade_material"}}),_c('multi-choice-card',{attrs:{"full":"","feature":"roof_material"}}),_c('roof-information'),_c('solar-panels'),_c('multi-choice-card',{attrs:{"full":"","feature":"window_type","config-location":"features"}}),_c('grid-card',{attrs:{"title":"Photos"}}),_c('grid-card',{attrs:{"title":"Plans"}}),_c('h1',{staticClass:"valuer_tool-title"},[_vm._v("Parcel")]),_c('garden'),_c('swimming-pool'),_c('grid-card',{attrs:{"title":"Photos"}}),_c('grid-card',{attrs:{"title":"Plans"}}),_c('h1',{staticClass:"valuer_tool-title"},[_vm._v("Annexes")]),_c('garage'),_c('annex',{attrs:{"feature":"pool_house"}}),_c('annex',{attrs:{"feature":"stables"}}),_c('annex',{attrs:{"feature":"barn"}}),_c('grid-card',{attrs:{"title":"Photos"}}),_c('grid-card',{attrs:{"title":"Plans"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <grid-card no-padding>
        <template slot="header">
            <h5 class="grid_card-title">
                {{ title }}
            </h5>
            <clear-button
                v-if="!postReno && !isEditing"
                class="edit-button"
                @click="editValues"
            >
                <svg-icon icon="edit" color="#798DA6" width="24" height="24" />
            </clear-button>
            <div v-else-if="isEditing" class="editing-actions">
                <white-button @click="cancelEdit">Cancel</white-button>
                <dark-button @click="saveEdit">Save</dark-button>
            </div>
        </template>
        <template slot="default">
            <div v-if="postReno" class="adjustment">
                <h6>Total Adjustment</h6>
                <div class="adjustment-slider">
                    <b-slider v-model="adjustment" :min="0" :max="200" />
                    <b-input v-model="adjustment" type="number" />
                </div>
            </div>
            <div class="value_grid">
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>AVM estimated price</h6>
                    <h6>
                        {{ priceFormat(getAvmTransactionEstimations.asking_price_q50) }}
                    </h6>
                </div>
                <div class="value_grid-entry">
                    <h6>{{ $t('valuation.market_value') }}</h6>
                    <h6 v-if="postReno || !isEditing">
                        {{ priceFormat(market_value) }}
                    </h6>
                    <div v-else class="priceInput">
                        <b-input
                            v-model="market_value"
                            type="number"
                            icon="currency-eur"
                            size="is-small"
                        />
                    </div>
                </div>
                <div class="value_grid-entry">
                    <h6>{{ $t('valuation.forced_sale_value') }}</h6>
                    <h6 v-if="postReno || !isEditing">
                        {{ priceFormat(forced_sale_value) }}
                    </h6>
                    <div v-else class="priceInput">
                        <b-input
                            v-model="forced_sale_value"
                            type="number"
                            icon="currency-eur"
                            size="is-small"
                        />
                    </div>
                </div>
                <div class="value_grid-entry">
                    <h6>{{ $t('valuation.rental_value') }}</h6>
                    <h6 v-if="postReno || !isEditing">
                        {{ priceFormat(rental_value, false) }}
                    </h6>
                    <div v-else class="priceInput">
                        <b-input
                            v-model="rental_value"
                            type="number"
                            icon="currency-eur"
                            size="is-small"
                        />
                    </div>
                </div>
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>{{ $t('valuation.reconstruction_value') }}</h6>
                    <h6>
                        {{ priceFormat(reconstruction_value) }}
                    </h6>
                </div>
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>{{ $t('valuation.cadastral_income') }}</h6>
                    <h6>
                        {{ priceFormat(cadastral_income, false) + $t('unit.euro/year') }}
                    </h6>
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '../../elements/GridCard'
import WhiteButton from '../../elements/WhiteButton.vue'
import DarkButton from '../../elements/DarkButton.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations } from 'vuex'
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'ValueCard',
    components: {
        GridCard,
        ClearButton,
        WhiteButton,
        DarkButton,
    },
    props: {
        postReno: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        cadastral_income: null,
        reconstruction_value: null,
        reconstruction_value_loaded: false,
        analyticsShown: false,
        adjustment: 0,
        market_value: null,
        forced_sale_value: null,
        rental_value: null,
        isEditing: false,
        prevVals: {
            market_value: null,
            forced_sale_value: null,
            rental_value: null,
        },
    }),
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getAvmTransactionEstimations',
            'getAvmRentalEstimations',
            'getFeatures',
            'getValuation',
        ]),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        title() {
            return this.$t(
                `valuer_tool.valuation.value_${this.postReno ? 'post' : 'pre'}_reno`
            )
        },
    },
    watch: {
        adjustment(val) {
            if (this.postReno) {
                this.market_value = this.getValuation.market_value * (1 + +val / 100)
                this.forced_sale_value =
                    this.getValuation.forced_sale_value * (1 + +val / 100)
                this.rental_value = this.getValuation.rental_value * (1 + +val / 100)
                this.SET_VALUATION({ renovation_adjustment: +val })
            }
        },
        market_value(val) {
            if (this.postReno) {
                this.SET_VALUATION({
                    market_value_post_renovation: val,
                })
            }
        },
        forced_sale_value(val) {
            if (this.postReno) {
                this.SET_VALUATION({
                    forced_sale_value_post_renovation: val,
                })
            }
        },
        rental_value(val) {
            if (this.postReno) {
                this.SET_VALUATION({
                    rental_value_post_renovation: val,
                })
            }
        },
        'getValuation.market_value': function(val) {
            if (this.postReno) {
                this.market_value =
                    this.getValuation.market_value * (1 + this.adjustment / 100)
            }
        },
        'getValuation.forced_sale_value': function(val) {
            if (this.postReno) {
                this.forced_sale_value =
                    this.getValuation.forced_sale_value * (1 + this.adjustment / 100)
            }
        },
        'getValuation.rental_value': function(val) {
            if (this.postReno) {
                this.rental_value =
                    this.getValuation.rental_value * (1 + this.adjustment / 100)
            }
        },
    },
    mounted() {
        if (!this.is_plot) {
            this.getReconstructionValue()
        }
        let suffix = this.postReno ? '_post_renovation' : ''

        this.market_value =
            this.getValuation['market_value' + suffix] ??
            this.getValuation.market_value ??
            this.getAvmTransactionEstimations.asking_price_q50
        this.forced_sale_value =
            this.getValuation['forced_sale_value' + suffix] ??
            this.getValuation.forced_sale_value ??
            this.getForcedSaleValue()
        this.rental_value =
            this.getValuation['rental_value' + suffix] ??
            this.getValuation.rental_value ??
            this.getAvmRentalEstimations.asking_price_q50

        if (this.postReno) {
            this.adjustment = this.getValuation.renovation_adjustment
        } else {
            this.cadastral_income = this.getValuation.cadastral_income
        }
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_VALUATION']),
        priceFormat(x, thousands = true) {
            return utils.priceFormat(x, thousands)
        },
        getReconstructionValue() {
            if (this.is_plot) return 'N/A'
            let url = utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                'estimate',
                'reconstruction',
                this.getFeatures.building_id,
            ])
            url += `?f_building_type=${this.getFeatures.f_building_type}&f_living_area=${this.getFeatures.f_living_area}`
            this.$axios.get(url).then((res) => {
                this.reconstruction_value = res.data.reconstruction_value
                    ? Math.round(res.data.reconstruction_value / 1000) * 1000
                    : null
                this.reconstruction_value_loaded = true
            })
        },
        editValues() {
            this.prevVals.market_value = this.market_value
            this.prevVals.forced_sale_value = this.forced_sale_value
            this.prevVals.rental_value = this.rental_value
            this.isEditing = true
        },
        cancelEdit() {
            this.market_value = this.prevVals.market_value
            this.forced_sale_value = this.prevVals.forced_sale_value
            this.rental_value = this.prevVals.rental_value
            this.prevVals.market_value = null
            this.prevVals.forced_sale_value = null
            this.prevVals.rental_value = null
            this.isEditing = false
        },
        saveEdit() {
            this.SET_VALUATION({
                market_value: this.market_value,
                forced_sale_value: this.forced_sale_value,
                rental_value: this.rental_value,
            })
            this.prevVals.market_value = null
            this.prevVals.forced_sale_value = null
            this.prevVals.rental_value = null

            this.isEditing = false
        },
        getForcedSaleValue() {
            return utils.forced_sale_value(
                this.getAvmTransactionEstimations.asking_price_q50
            )
        },
    },
}
</script>
<style lang="scss" scoped>
.edit-button {
    padding: 0;

    &:hover {
        svg path {
            fill: #001837 !important;
        }
    }
}

.editing-actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.adjustment {
    padding: 24px;
    padding-top: 0;
    margin-bottom: 24px;
    border-bottom: 1px solid #d6e2e9;

    &-slider {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;

        & > *:first-child {
            flex-basis: 85%;
        }

        * {
            flex-grow: 0;
        }
    }
}

.value_grid {
    padding: 24px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-entry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h6 {
            color: #001837;
            font-size: 14px;
            font-weight: 700;
        }

        &--grey {
            opacity: 0.5;
        }
    }
}
</style>

<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('energy.floor.question') }}
                <Tooltip :html="$t('energy.floor.tooltip_popup')" />
            </h1>
        </div>

        <div class="ers-page-section">
            <yes-no v-model="floor_attributes[0].has_insulation" :idk-enabled="true" />
        </div>

        <div v-if="floor_attributes[0].has_insulation === true" class="ers-page-section">
            <h1 class="has-text-weight-medium has-text-black input-instructions">
                {{ $t('energy.floor.question_2') }}
            </h1>
            <year-selector
                v-model="floor_attributes[0].renovation_year"
                :minimum="getFeatures.f_construction_year || 1800"
                :label="$t('energy.shared.insulation_year')"
            />
        </div>

        <div class="column is-12 mt-2">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    @click="changePage(false)"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        changePage(true)
                    "
                />
                <b-button
                    v-if="showNextButton"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="changePage(true)"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import YesNo from '../../elements/YesNo.vue'
import YearSelector from '../../elements/YearSelector.vue'
import SubmitButton from '../../elements/SubmitButton.vue'
import Tooltip from '../../elements/Tooltip.vue'

export default {
    name: 'FloorInsulation',
    components: {
        YesNo,
        SubmitButton,
        YearSelector,
        Tooltip,
    },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        floor_attributes: [
            {
                has_renovation: null,
                has_insulation: null,
                renovation_year: null,
            },
        ],
        isFirstPass: true,
    }),
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getFloorInsulation',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
        showNextButton() {
            if (
                this.floor_attributes[0].has_insulation === false ||
                this.floor_attributes[0].has_insulation === 'idk'
            )
                return true
            if (
                this.floor_attributes[0].has_insulation &&
                this.floor_attributes[0].has_renovation === false
            )
                return true
            if (
                this.floor_attributes[0].has_insulation &&
                // this.floor_attributes[0].has_renovation &&
                this.floor_attributes[0].renovation_year >=
                    this.getFeatures.f_construction_year
            )
                return true
            return false
        },
    },
    mounted() {
        const prevValue = this.getFloorInsulation[0]
        if (prevValue.has_insulation !== null) {
            this.isFirstPass = false
        }
        this.floor_attributes[0].has_insulation = prevValue.has_insulation

        if (prevValue.has_renovation !== null) {
            this.isFirstPass = false
        }
        this.floor_attributes[0].has_renovation = prevValue.has_renovation

        if (!prevValue.renovation_year) {
            this.floor_attributes[0].renovation_year = this.getFeatures.f_construction_year
        } else {
            this.floor_attributes[0].renovation_year = prevValue.renovation_year
        }

        // https://stackoverflow.com/a/56571537/16901626
        this.$watch(
            () => this.floor_attributes[0].has_insulation,
            function(val) {
                if (val === false || val === 'idk') {
                    this.floor_attributes[0].has_renovation = false
                    this.floor_attributes[0].renovation_year = null
                    if (this.isFirstPass) {
                        this.changePage(true)
                    } else {
                        this.isFirstPass = true
                    }
                } else {
                }
            }
        )
        this.$watch(
            () => this.floor_attributes[0].has_renovation,
            function(val) {
                if (val === false) {
                    this.floor_attributes[0].renovation_year = this.getFeatures.f_construction_year
                    if (this.isFirstPass) {
                        this.changePage(true)
                    } else {
                        this.isFirstPass = true
                    }
                }
            }
        )
    },
    methods: {
        ...mapMutations('ersStore', ['SET_FEATURES']),
        ...mapActions('ersStore', ['set_and_submit_feature', 'track_event']),
        changePage(isNextPage) {
            const nextPage = isNextPage ? 'ers.window-glazing' : 'ers.wall-insulation'
            const feature = {
                floor_attributes: this.floor_attributes,
            }
            if (this.$config.TRACK) {
                this.track_event({
                    route: this.$route,
                    event: 'floor_insulation',
                    data: {
                        value: this.floor_attributes[0].has_insulation,
                    },
                })
            }

            this.set_and_submit_feature({
                feature,
                nextPage,
            })
            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
    },
}
</script>

<style lang="scss">
@import '@/shared/assets/style/buefy.scss';
.grid-center {
    justify-self: center;
    align-self: center;
    text-align: center;
}

.insulation-grid {
    display: grid;
    grid-template-columns: minmax(240px, max-content) repeat(4, auto);
    grid-template-rows: repeat(4, auto);
    gap: 1rem;
}

.glazing-grid {
    display: grid;
    grid-template-columns: minmax(240px, max-content) repeat(1, auto);
    grid-template-rows: repeat(2, auto);
    gap: 0.5rem 1rem;
    align-items: end;
}

.attic-grid {
    display: grid;
    grid-template-columns: minmax(240px, max-content) repeat(2, auto);
    grid-template-rows: auto;
    gap: 1rem;

    .heated-attic-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        h4 {
            margin-right: 1rem;
        }
    }
}

.basement-grid {
    display: grid;
    grid-template-columns: minmax(240px, max-content) auto;
    grid-template-rows: auto;
    gap: 1rem;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 1rem 1.5rem;
    background: #d6e2e9;
}

.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valuer_tool-default-layout"},[(_vm.getFeatures && _vm.getFeatures.building_id)?_c('div',{staticClass:"valuer_tool-default-layout-map_container"},[_c('re-map',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"level":_vm.getFeatures.level,"region":_vm.getFeatures.region,"center":{
                lat: _vm.getFeatures.f_lat,
                lng: _vm.getFeatures.f_lng,
            },"map-scene2d":_vm.get_map_scene2d,"read-only":true,"focus-layer":'building',"layers-control-visible":false,"options":{
                zoomControl: true,
                attributionControl: false,
                scrollWheelZoom: false,
                attributionControl: false,
                scrollWheelZoom: false,
                doubleClickZoom: true,
                boxZoom: true,
                dragging: true,
            },"aerial-tile-visible":false,"cadastral-tile-visible":false},on:{"update:mapScene2d":function($event){return _vm.SET_MAP_SCENE2D($event)}}})],1):_vm._e(),_c('div',{staticClass:"valuer_tool-default-layout-grid"},[_c('h1',{staticClass:"valuer_tool-title"},[_vm._v("Surroundings")]),_c('flood-map'),_c('multi-choice-card',{attrs:{"title":"Environment","feature":"environment"}}),_c('multi-choice-card',{attrs:{"title":"Level of indirect traffic","feature":"indirect_traffic_level"}}),_c('multi-choice-card',{attrs:{"title":"Proximity of indirect street traffic","feature":"environment"}}),_c('h1',{staticClass:"valuer_tool-title"},[_vm._v("Mobility")]),_c('multi-choice-card',{attrs:{"title":"Public transport","feature":"public_transport"}}),_c('multi-choice-card',{attrs:{"title":"Education","feature":"education"}}),_c('multi-choice-card',{attrs:{"title":"Recreation, sports, culture","feature":"recreation"}}),_c('multi-choice-card',{attrs:{"title":"Shops & services","feature":"shops"}}),_c('multi-choice-card',{attrs:{"title":"Healthcare","feature":"healthcare"}}),_c('h1',{staticClass:"valuer_tool-title"},[_vm._v("Other")]),_c('other-impacting-card',{attrs:{"type":"positive","feature":"other_positive"}}),_c('other-impacting-card',{attrs:{"type":"negative","feature":"other_negative"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<i18n lang="json">
    {
        "en-BE": {
            "appointmentStatus": {
                "new": "Appointment has been created",
                "pending_confirmation": "Waiting for expert's confirmation",
                "confirmed": "The expert has confirmed the appointment",
                "cancelled": "Appointment has been cancelled."
            },
            "paymentNeeded": {
                "true": "€{amountToPay}",
                "false": "No extra payment needed"
            },
            "paymentStatus": {
                "pending": "The payment is processing…",
                "succeeded": "The payment has been successfully received.",
                "cancelled": "The payment has been cancelled.",
                "refunded": "The payment has been refunded."
            }
        }
    }    
</i18n>
<template>
    <div>
        <b-message v-if="error" type="is-danger">
            {{ error }}
        </b-message>
        <div v-if="displayList">
            <div v-if="appointments == undefined">
                <b-loading :is-full-page="false" active></b-loading>
            </div>
            <div v-else-if="appointments.length == 0">
                No upcoming appointments.
            </div>
            <table v-else class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="appointment in appointments" :key="appointment.id">
                        <td>
                            {{ new Date(appointment.start_time).toLocaleString() }}
                            {{ appointment.valuation_request_ref }}
                        </td>
                        <td>{{ $t('appointmentStatus.' + appointment.status) }}</td>
                        <td>
                            <b-button
                                mt="1"
                                size="is-small"
                                type="is-primary"
                                icon-left="eye"
                                tag="router-link"
                                :to="{
                                    name: 'valuation.ovm.summary',
                                    query: {
                                        valuation_request_ref:
                                            appointment.valuation_request
                                                .valuation_request_ref,
                                    },
                                }"
                            ></b-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="container">
            <div class="appointment-summary">
                <div v-if="appointments == undefined">
                    <b-loading :is-full-page="false" :active="true"></b-loading>
                </div>
                <div v-else-if="appointments.length == 0">
                    No appointment has been scheduled for this request yet.
                </div>
                <template v-else>
                    <h3 class="title is-size-3 mt-3">Appointment summary</h3>
                    <div>
                        <strong>Address:</strong>
                        <a :href="addressGoogleMaps" target="_blank">
                            {{ getAddress.full_address }}
                        </a>
                    </div>
                    <div>
                        <strong>Date:</strong>
                        <span>{{ appointmentStartTime.toLocaleString() }}</span>
                    </div>
                    <div>
                        <strong>Status:</strong>
                        <span>
                            {{ $t('appointmentStatus.' + latestAppointment.status) }}
                        </span>
                    </div>
                    <template v-if="borrower">
                        <h3 class="title is-size-3 mt-3">Borrower information</h3>
                        <div>
                            <strong>Name:</strong>
                            <span>
                                {{ borrower.first_name }} {{ borrower.last_name }}
                            </span>
                        </div>
                        <div>
                            <strong>Email:</strong>
                            <a :href="'mailto:' + borrower.email">{{ borrower.email }}</a>
                        </div>
                        <div>
                            <strong>Phone number:</strong>
                            <a :href="'tel:' + borrower.phone">{{ borrower.phone }}</a>
                        </div>
                    </template>
                    <template v-if="paymentSummary">
                        <h3 class="title is-size-3 mt-3">Payment information</h3>
                        <div>
                            <strong>Status:</strong>
                            <span>
                                {{ $t('paymentStatus.' + paymentSummary.status) }}
                            </span>
                        </div>
                        <div>
                            <strong>Amount paid:</strong>
                            <span>€{{ paymentSummary.total_paid_euro }}</span>
                        </div>
                        <div>
                            <strong>Amount due:</strong>
                            <span>
                                {{
                                    $t(
                                        'paymentNeeded.' +
                                            paymentSummary.is_payment_needed,
                                        {
                                            amountToPay: paymentSummary.amount_due_euro,
                                        }
                                    )
                                }}
                            </span>
                        </div>
                    </template>
                </template>
                <nav-buttons :next-step="nextStep" :prev-step="prevStep">
                    <template
                        v-if="
                            latestAppointment &&
                                latestAppointment.status === 'pending_confirmation' &&
                                canConfirm
                        "
                    >
                        <b-button
                            type="is-primary"
                            :loading="loadingConfirmation"
                            @click="
                                () =>
                                    updateLatestAppointment(
                                        { status: 'confirmed' },
                                        false
                                    )
                            "
                        >
                            Confirm appointment
                        </b-button>
                        <b-button
                            :loading="loadingConfirmation"
                            @click="
                                () =>
                                    updateLatestAppointment(
                                        { status: 'cancelled' },
                                        false
                                    )
                            "
                        >
                            Cancel appointment
                        </b-button>
                    </template>
                </nav-buttons>
            </div>
            <section
                v-if="hasRole('dispatcher', 'ovm')"
                class="box mt-3 dispatcher-options"
            >
                <h3 class="title is-size-3 mt-3">Dispatcher Tools</h3>
                <form v-if="latestAppointment">
                    <h4 class="title is-size-4 mt-3">Edit appointment details</h4>
                    <p class="my-3">
                        <strong>Currently assigned:</strong>
                        {{
                            latestAppointment.valuer
                                ? getNameForUserId(latestAppointment.valuer.id)
                                : 'No valuer assigned'
                        }}
                    </p>

                    <b-field horizontal>
                        <b-checkbox v-model="skipNotifications">
                            Skip notifications
                        </b-checkbox>
                    </b-field>
                    <b-field horizontal label="Valuer">
                        <b-select
                            :value="
                                latestAppointment.valuer
                                    ? latestAppointment.valuer.id
                                    : null
                            "
                            @input="
                                (val) => updateLatestAppointment({ valuer_id: val }, true)
                            "
                        >
                            <option
                                v-for="valuer in valuerUsers"
                                :key="valuer.id"
                                :value="valuer.id"
                            >
                                {{ valuer.first_name }} {{ valuer.last_name }} &lt;{{
                                    valuer.id
                                }}&gt;
                            </option>
                        </b-select>
                    </b-field>
                    <b-field horizontal label="Appointment status">
                        <b-select
                            :value="latestAppointment.status"
                            :disabled="loadingConfirmation"
                            @input="
                                (val) => updateLatestAppointment({ status: val }, true)
                            "
                        >
                            <option
                                v-for="status in [
                                    'new',
                                    'pending_confirmation',
                                    'confirmed',
                                    'cancelled',
                                ]"
                                :key="status"
                                :value="status"
                            >
                                {{ $t('appointmentStatus.' + status) }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field horizontal label="Start time">
                        <b-datetimepicker
                            icon="calendar-today"
                            :value="latestAppointment.start_time"
                            :disabled="loadingConfirmation"
                            rounded
                            placeholder="Click to select..."
                            @input="
                                (val) =>
                                    updateLatestAppointment({ start_time: val }, true)
                            "
                        ></b-datetimepicker>
                    </b-field>
                    <b-field horizontal label="End time">
                        <b-datetimepicker
                            icon="calendar-today"
                            :value="latestAppointment.end_time"
                            :disabled="loadingConfirmation"
                            rounded
                            placeholder="Click to select..."
                            @input="
                                (val) => updateLatestAppointment({ end_time: val }, true)
                            "
                        ></b-datetimepicker>
                    </b-field>
                    <b-notification
                        v-if="latestAppointment.end_time <= latestAppointment.start_time"
                        type="is-danger"
                    >
                        End time should be later than start time
                    </b-notification>
                </form>
                <div>
                    <h4 class="title is-size-4 mt-3">Create new appointment</h4>
                    <b-button @click="createAppointment">
                        Create appointment
                    </b-button>
                    <p>
                        <em>Any existing appointment will be automatically cancelled.</em>
                    </p>
                </div>
                <div v-if="paymentSummary">
                    <h4 class="title is-size-4 mt-3">
                        Payment information
                        <b-tag v-if="paymentSummary.is_payment_needed" type="is-warning">
                            Payment needed
                        </b-tag>
                        <b-tag v-else type="is-success">No payment needed</b-tag>
                    </h4>
                    <b-field horizontal label="Total fee">
                        <b-input
                            v-model="totalFeeEuro"
                            :disabled="loadingPayment"
                            type="number"
                        ></b-input>
                        <b-button
                            type="is-primary"
                            :disabled="totalFeeEuro === paymentSummary.total_fee_euro"
                            @click="() => updateValReq({ total_fee_euro: totalFeeEuro })"
                        >
                            Update
                        </b-button>
                    </b-field>
                    <b-field horizontal label="Total paid">
                        {{ paymentSummary.total_paid_euro }}
                    </b-field>
                    <b-field horizontal label="Amount still due">
                        {{ paymentSummary.amount_due_euro }}
                    </b-field>
                    <b-field
                        v-if="paymentHistory !== null"
                        horizontal
                        label="Payment status"
                    >
                        <b-select
                            :disabled="loadingPayment"
                            :value="paymentSummary.status"
                            @input="
                                (val) =>
                                    updatePayment(paymentHistory[0], {
                                        status: val,
                                    })
                            "
                        >
                            <option
                                v-for="status in [
                                    'pending',
                                    'succeeded',
                                    'cancelled',
                                    'refunded',
                                ]"
                                :key="status"
                                :value="status"
                            >
                                {{ $t('paymentStatus.' + status) }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field v-if="paymentSummary && paymentSummary.is_payment_needed">
                        <b-button
                            :disabled="loadingPayment"
                            type="is-primary"
                            @click="postPayment"
                        >
                            Post full payment
                        </b-button>
                    </b-field>
                </div>
                <div v-if="appointments && appointments.length > 1">
                    <h4
                        class="title is-size-4 mt-3"
                        @click="showAllAppointments = !showAllAppointments"
                    >
                        Previous appointment records
                        <b-icon
                            :icon="showAllAppointments ? 'menu-down' : 'menu-up'"
                        ></b-icon>
                    </h4>
                    <ul v-if="showAllAppointments">
                        <li
                            v-for="appointment in appointments.slice(1)"
                            :key="appointment.id"
                        >
                            {{ appointment.cronofy_event_id }} -
                            {{ appointment.status }} -
                            {{ appointment.start_time.toLocaleString() }} -
                            {{
                                appointment.valuer
                                    ? getNameForUserId(appointment.valuer.id)
                                    : 'No valuer assigned'
                            }}
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import NavButtons from './NavButtons.vue'

export default {
    name: 'OvmSummary',
    components: {
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
        displayList: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
    data: () => ({
        appointments: undefined,
        paymentSummary: null,
        loadingConfirmation: false,
        error: null,
        valuerUsers: null,
        skipNotifications: false,
        showAllAppointments: false,
        totalFeeEuro: null,
        loadingPayment: false,
        paymentHistory: null,
    }),
    computed: {
        ...mapGetters('auth', ['userId', 'hasRole']),
        ...mapGetters('valuationStore', ['getRequestRef', 'ovmInfo', 'borrower']),
        ...mapGetters('valuationStore/Address', ['getAddress']),
        ...mapGetters('auth', ['hasRole']),
        latestAppointment() {
            return this.appointments?.length ? this.appointments[0] : null
        },
        appointmentStartTime() {
            return new Date(this.latestAppointment?.start_time)
        },
        addressGoogleMaps() {
            const urlEncodedAddress = encodeURIComponent(this.getAddress.full_address)
            return 'https://www.google.com/maps?q=' + urlEncodedAddress
        },
        canConfirm() {
            return this.hasRole('valuer', 'ovm') || this.hasRole('dispatcher', 'ovm')
        },
    },
    mounted() {
        // TODO: some serious clean-up to use proper async code:
        this.getAppointments()
        if (this.getRequestRef) this.getPaymentSummary()
        // TODO: replace this by proper Store action:
        if (!this.displayList && this.hasRole('dispatcher', 'ovm')) {
            this.getValuerUsers()
            this.getPaymentHistory()
        }
    },
    methods: {
        async getAppointments() {
            this.loadingConfirmation = true
            let url = ''
            if (this.getRequestRef) {
                url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'appointment',
                    'request',
                    this.getRequestRef,
                ])
            } else {
                url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'appointment',
                    'user',
                    this.$route.query.id || this.userId,
                ])
            }
            this.$axios
                .get(url)
                .then((res) => {
                    this.appointments = res.data
                    for (const appointment of this.appointments) {
                        appointment.start_time = new Date(appointment.start_time)
                        appointment.end_time = new Date(appointment.end_time)
                    }
                })
                .catch((err) => {
                    this.error = err.response.data
                })
                .finally(() => {
                    this.loadingConfirmation = false
                })
        },
        // Move this to a Store action:
        getPaymentSummary() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
                'summary',
            ])

            this.loadingPayment = true
            this.$axios
                .get(url)
                .then((res) => {
                    this.paymentSummary = res.data
                    this.totalFeeEuro = this.paymentSummary.total_fee_euro
                })
                .catch((err) => {
                    this.error = err.response.data
                })
                .finally(() => {
                    this.loadingPayment = false
                })
        },
        getPaymentHistory() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
            ])

            this.$axios
                .get(url)
                .then((res) => {
                    this.paymentHistory = res.data
                })
                .catch((err) => {
                    this.error = err.response.data
                })
        },
        getValuerUsers() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, ['users'])

            this.$axios
                .get(url, {
                    params: {
                        roles: 'valuer',
                    },
                })
                .then((res) => {
                    this.valuerUsers = res.data
                })
                .catch((err) => {
                    this.error = err.response.data
                })
        },
        getNameForUserId(id) {
            if (!this.valuerUsers) return null
            const user = this.valuerUsers.find((user) => user.id === id)
            if (!user) return `Unknown user <${id}>`
            return `${user.first_name} ${user.last_name} <${user.id}>`
        },
        updateLatestAppointment(data, skipValidation) {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'event',
                this.latestAppointment.cronofy_event_id,
            ])

            this.loadingConfirmation = true

            this.$axios
                .put(url, data, {
                    params: {
                        skip_validation: skipValidation,
                        skip_notifications: this.skipNotifications,
                    },
                })
                .catch((err) => {
                    this.error = err.response.data
                })
                .finally(() => {
                    this.getAppointments()
                    this.loadingConfirmation = false
                })
        },
        updatePayment(payment, data) {
            if (
                data.status === 'pending' &&
                payment.status === 'succeeded' &&
                payment.payment_intent_id
            ) {
                if (
                    !confirm(
                        'Attempting to set a payment status back to `pending` will likely be reversed if the Stripe transaction was successful.'
                    )
                )
                    return
            }

            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
                'payment',
                `${payment.payment_id}`,
            ])

            this.$axios
                .patch(url, data)
                .then((res) => {
                    this.paymentHistory = null
                    this.getPaymentSummary()
                    this.getPaymentHistory()
                })
                .catch((err) => {
                    this.error = err.response.data
                })
        },
        postPayment() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'request',
                this.getRequestRef,
            ])

            this.$axios
                .post(url, {
                    amount_euro: this.paymentSummary.amount_due_euro,
                    status: 'succeeded',
                })
                .then((res) => {
                    this.getPaymentSummary()
                    this.getPaymentHistory()
                })
                .catch((err) => {
                    this.error = err.response.data
                })
        },
        updateValReq(data) {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'ovm',
                'request',
                this.getRequestRef,
            ])

            this.paymentSummary = null
            this.$axios
                .put(url, data)
                .then((res) => {
                    this.getPaymentSummary()
                })
                .catch((err) => {
                    this.error = err.response.data
                })
        },
        createAppointment() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'request',
                this.getRequestRef,
            ])

            this.$axios
                .post(url, {
                    start_time: new Date(),
                    end_time: new Date(),
                })
                .then((res) => {
                    this.getAppointments()
                })
                .catch((err) => {
                    this.error = err.response.data
                })
        },
    },
}
</script>

<style lang="scss">
.appointment-summary {
    max-width: 600px;
    margin: auto;
    strong {
        margin-right: 0.5rem;
    }
}

.dispatcher-options {
    background-color: #d0efff !important;
}

.dispatcher-options > form,
.dispatcher-options > div {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid #b3d7ff;
}
.dispatcher-options ul li {
    // display bullet points
    list-style-type: disc;
    list-style-position: inside;
}
</style>

<i18n>
{
  "nl-BE": {
    "title": "Welk type pand wordt gefinancierd?",
    "tooltip_text": "Financiert de klant een ééngezinswoning of een appartement?",
    "destructive_safeguard": {
        "title": "Opgelet: indien u verdergaat met deze wijziging, wordt het input formulier gereset.",
        "content": "Bent u zeker dat u wilt verdergaan?",
        "cancel": "Annuleren",
        "confirm": "Bevestig"
    },
    "previous": "Vorige",
    "next": "Volgende"
  },
  "en-BE": {
    "title": "What type of property is being financed?",
    "tooltip_text": "Is the Borrower financing a single-family home or an apartment?",
    "destructive_safeguard": {
        "title": "Warning: this change will reset the rest of your input.",
        "content": "Are you sure you want to proceed with that change?",
        "cancel": "Cancel",
        "confirm": "Confirm"
    },
    "previous": "Previous",
    "next": "Next"
  },
  "fr-BE": {
    "title": "Quel est le type de bien financé ?",
    "tooltip_text": "Est-que le client finance une maison unifamiliale ou un appartement?",
    "destructive_safeguard": {
        "title": "Attention : ce changement va réinitialiser le reste de votre demande.",
        "content": "Êtes-vous sûr de vouloir procéder à ce changement ?",
        "cancel": "Annuler",
        "confirm": "Confirmer"
    },
    "previous": "Précédent",
    "next": "Suivant"
  }
}
</i18n>

<template>
    <div>
        <div class="container section is-fluid">
            <div class="columns">
                <div
                    v-if="
                        !allowedBuildingTypes.includes('new_house') &&
                            !allowedBuildingTypes.includes('new_apartment')
                    "
                    class="column"
                >
                    <title-with-tooltip :label="$t('tooltip_text')">
                        <template #title>
                            {{ $t('title') }}
                        </template>
                    </title-with-tooltip>
                    <building-type-select
                        ref="parent"
                        :building-type.sync="buildingType"
                        :allowed-building-types="allowedBuildingTypes"
                        :value="buildingType"
                        class="pb-4"
                    />
                </div>
                <div v-else class="column">
                    <building-type-split
                        ref="parent"
                        :building-type.sync="buildingType"
                        :allowed-building-types="allowedBuildingTypes"
                        :value="buildingType"
                        class="pb-4"
                    />
                    <div class="is-flex is-justify-content-space-between pt-5 is-btn-box">
                        <b-button
                            icon-left="arrow-left"
                            type="is-dark"
                            tag="router-link"
                            size="is-medium"
                            :to="prevLink"
                        >
                            <span>{{ $t('previous') }}</span>
                        </b-button>
                        <b-button
                            v-if="buildingType != null"
                            tag="router-link"
                            size="is-medium"
                            type="is-primary"
                            icon-right="arrow-right"
                            :to="{
                                name: 'valuation.request',
                                params: {
                                    lang: $route.params.lang,
                                    action: 'address',
                                },
                                query: $route.query,
                            }"
                        >
                            <span>{{ $t('next') }}</span>
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            v-model="isDestructiveSave"
            trap-focus
            has-modal-card
            @close="closeDestructiveSaveModal"
        >
            <div class="card modal-card p-5 destructive-save-modal">
                <h4 class="is-size-5 has-text-weight-bold has-text-black">
                    {{ $t('destructive_safeguard.title') }}
                </h4>
                <p>
                    {{ $t('destructive_safeguard.content') }}
                </p>
                <div class="btn-group">
                    <b-button @click="closeDestructiveSaveModal">
                        {{ $t('destructive_safeguard.cancel') }}
                    </b-button>
                    <b-button type="is-primary" @click="next_page(true)">
                        {{ $t('destructive_safeguard.confirm') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import BuildingTypeSelect from '@/components/elements/BuildingTypeSelect.vue'
import BuildingTypeSplit from '@/components/elements/BuildingTypeSplit.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'BuildingType',
    components: { BuildingTypeSelect, BuildingTypeSplit },
    data() {
        return {
            isDestructiveSave: false,
            buildingType: null,
        }
    },
    computed: {
        ...mapGetters(['flow_type']),
        ...mapGetters('valuationStore/Address', ['getFeatures', 'getAddress']),
        allowedBuildingTypes() {
            return this.$config.ALLOWED_BUILDING_TYPES
        },
        prevLink() {
            if (this.flow_type === 'avm_demo') {
                return {
                    name: 'valuation.request',
                    params: {
                        lang: this.$route.params.lang,
                        action: 'start',
                    },
                    query: {},
                }
            }
            return {
                name: 'valuation.request',
                params: {
                    lang: this.$route.params.lang,
                    action: 'enter',
                },
                query: {},
            }
        },
    },
    watch: {
        buildingType(val) {
            if (val !== this.getFeatures.f_building_type && val !== null) {
                this.next_page()
            }
        },
    },
    mounted() {
        this.buildingType = this.getFeatures.f_building_type
    },
    methods: {
        ...mapMutations('valuationStore/Address', [
            'SET_FEATURES',
            'SET_OTHER_DATA',
            'RESET_FEATURES',
            'RESET_ADDRESS',
        ]),
        closeDestructiveSaveModal() {
            this.buildingType = this.getFeatures.f_building_type
            this.isDestructiveSave = false
        },
        next_page(force = false) {
            if (
                !force &&
                this.getAddress.full_address &&
                !utils.isEmptyStr(this.getAddress.full_address)
            ) {
                this.isDestructiveSave = true
            } else {
                this.isDestructiveSave = false
                this.$axios
                    .patch(
                        utils
                            .val_urls(this.$config)
                            .request_ref(
                                this.$store.state.valuationStore.valuation_request_ref
                            ),
                        {
                            features: { f_building_type: this.buildingType },
                            other_data: {
                                extra_info_edited: false,
                            },
                        }
                    )
                    .then((response) => {
                        this.SET_OTHER_DATA({ extra_info_edited: false })
                        this.SET_FEATURES({ f_building_type: this.buildingType })
                        this.RESET_FEATURES()
                        this.RESET_ADDRESS()
                        this.$router.push({
                            name: 'valuation.request',
                            params: {
                                lang: this.$route.params.lang,
                                action: 'address',
                            },
                            query: this.$route.query,
                        })
                    })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.is {
    &-text {
        &_line {
            font-family: Lato;
            font-style: normal;
            font-size: 18px;
            line-height: 22px;
        }
    }
    &-btn-box {
        max-width: 760px;
        @media screen and (max-width: 1023px) {
            max-width: 100%;
        }
    }
}
</style>

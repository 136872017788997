<template>
    <div
        class="multiple-choice small"
        :class="{ small, 'is-justify-content-start': answers.length < 3, wrap }"
    >
        <div
            v-for="answer in answers"
            :key="`answer_${answer.value}`"
            class="answer"
            :class="{
                active: choice === answer.value,
                small,
            }"
            @click="updateValue(answer.value)"
        >
            <div v-if="answer.picto" class="icon-container">
                <img :src="answer.picto" />
            </div>
            <div v-else-if="answer.icon" class="icon-container">
                <svg-icon
                    :icon="answer.icon"
                    height="42"
                    width="42"
                    scale=".8"
                    :color="choice === answer.value ? '#0096ff' : '#798DA6'"
                />
            </div>
            <h3 :style="{ marginInline: center ? 'auto' : 'unset' }">
                {{ answer.name ? answer.name : $t(`value.${answer.value}`) }}
                <b-tooltip
                    v-if="answer.tooltip"
                    :label="answer.tooltip"
                    multilined
                    size="is-large"
                >
                    <div class="tooltip-qmark"><span>?</span></div>
                </b-tooltip>
            </h3>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MultiChoice',
    model: {
        prop: 'choice',
        event: 'select',
    },
    props: {
        answers: {
            type: Array,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        wrap: {
            type: Boolean,
            default: false,
        },
        choice: {
            type: [Boolean, Number, String],
            required: false,
            default: null,
        },
        stacked: {
            type: Boolean,
            default: true,
        },
        center: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('select', value)
        },
    },
}
</script>
<style lang="scss" scoped>
.multiple-choice {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        gap: 20px;
    }
    align-items: flex-start;
    justify-content: space-between;

    .wrap {
        flex-wrap: wrap;
    }

    .answer {
        min-height: 6.5rem;
        flex-grow: 1;
        width: 100%;
        padding: 1.5rem;
        @media screen and (min-width: 768px) {
            width: 28%;
            padding: 40px;
        }
        border-radius: 5px;
        background: #fff;
        border: 1px solid #d6e2e9;
        box-shadow: 0px 2px 14px rgba(0, 47, 80, 0.1);
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        flex-grow: auto;

        transition: all 0.3s ease-in-out;

        .icon-container {
            height: 3.5rem;
            width: 3.5rem;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(121, 141, 166, 0.1);
            border: 1px solid rgba(121, 141, 166, 0.2);
            aspect-ratio: 1;

            &.idk {
                svg {
                    position: relative;
                    left: 2px;
                }
            }
        }

        &:hover {
            box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);
        }

        h3 {
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;

            transition: all 0.3s ease-in-out;

            color: #001837;
        }

        &.stacked {
            flex-direction: column;
        }

        &.small {
            min-width: fit-content;
            padding-top: 20px;
            padding-bottom: 20px;
            flex-grow: 1;
        }

        &.active {
            border: 1px solid #0096ff;
            box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);

            h3 {
                color: #0096ff;
            }

            .icon-container {
                background: rgba(0, 150, 255, 0.1);
                border: 1px solid rgba(0, 150, 255, 0.2);
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        p {
            color: #798da6;
            font-family: Satoshi;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3125rem; /* 150% */
        }
    }
}
</style>

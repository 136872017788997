import { render, staticRenderFns } from "./ValuationRequest.vue?vue&type=template&id=c6cdaddc&scoped=true"
import script from "./ValuationRequest.vue?vue&type=script&lang=js"
export * from "./ValuationRequest.vue?vue&type=script&lang=js"
import style0 from "@/assets/style/index.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./ValuationRequest.vue?vue&type=style&index=1&id=c6cdaddc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6cdaddc",
  null
  
)

/* custom blocks */
import block0 from "./ValuationRequest.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
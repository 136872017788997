<i18n lang="json">
    {
        "en-BE": {
            "status": {
                "pending_confirmation": "Waiting for expert's confirmation, you'll be informed by email when the expert has confirmed the appointment.",
                "confirmed": "The expert has confirmed the appointment."
            }
        }
    }    
    </i18n>

<template>
    <div class="container">
        <h2 class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold">
            Expert confirmation
        </h2>
        <div v-if="appointmentStatus" class="expert-confirmation">
            <p>{{ $t('status.' + appointmentStatus) }}</p>
            <nav-buttons :next-step="nextStep" :prev-step="prevStep"></nav-buttons>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import NavButtons from '../NavButtons.vue'

export default {
    name: 'ExpertConfirmation',
    components: {
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
    },
    data: () => ({
        appointments: [],
    }),
    computed: {
        ...mapGetters('valuationStore', ['getRequestRef']),
        latestAppointment() {
            return this.appointments.length ? this.appointments[0] : null
        },
        appointmentStatus() {
            return this.latestAppointment?.status
        },
    },
    async mounted() {
        await this.getAppointments()
    },
    methods: {
        async getAppointments() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'request',
                this.getRequestRef,
                'event',
            ])
            const res = await this.$axios.get(url)

            if (res.status === 200) {
                this.appointments = res.data
            }
        },
    },
}
</script>

<style>
.expert-confirmation {
    max-width: 480px;
    margin: auto;
}
.button-flex {
    display: flex;
    justify-content: space-between;
}
</style>

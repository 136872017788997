<i18n lang="json">
    {
        "en-BE": {
            "info": "Info",
            "edit": "Edit",
            "cancel": "Cancel",
            "appointment": "Appointment",
            "view_request": "View request",
            "dvm": "DVM",
            "ovm": "OVM",
            "automatic_valuation": "Automatic valuation",
            "web_report": "Web report",
            "pdf_report": "PDF report",
            "downloading": "Downloading...",
            "take_ownership": "Take ownership"
        },
        "fr-BE": {
            "info": "Info",
            "edit": "Modifier",
            "cancel": "Annuler",
            "appointment": "Rendez-vous",
            "view_request": "Voir la demande",
            "dvm": "DVM",
            "ovm": "OVM",
            "automatic_valuation": "Valorisation automatique",
            "web_report": "Web report",
            "pdf_report": "PDF report",
            "downloading": "Downloading...",
            "take_ownership": "Prendre en charge"
        },
        "nl-BE": {
            "info": "Info",
            "edit": "Bewerken",
            "cancel": "Annuleren",
            "appointment": "Afspraak",
            "view_request": "Aanvraag bekijken",
            "dvm": "DVM",
            "ovm": "OVM",
            "automatic_valuation": "Automatische waardering",
            "web_report": "Web report",
            "pdf_report": "PDF report",
            "downloading": "Downloading...",
            "take_ownership": "Aanvraag overnemen"
        }
    }
</i18n>

<template>
    <div class="buttons is-centered">
        <b-button
            v-for="(a, i) in actions"
            :key="i"
            size="is-small"
            :type="a.type"
            :icon-left="a.icon"
            :target="a.target"
            :tag="a.to ? 'router-link' : a.href ? 'a' : 'button'"
            :disabled="a.disabled && a.disabled"
            :href="a.href ? a.href : undefined"
            :to="a.to ? a.to : undefined"
            :loading="a.loading"
            @click="a.click ? a.click() : undefined"
        >
            {{ $t(a.label) }}
        </b-button>
        <summary-modal ref="summaryModal" :unsubmit="true" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios.js'

import SummaryModal from '@/apps/ERS/components/pages/result/SummaryModal'

export default {
    name: 'ActionButtons',
    components: { SummaryModal },
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        now: Date.now(),
        disabledDownloads: false,
        ersEditLoading: false,
    }),
    computed: {
        ...mapGetters('auth', ['hasRole', 'accessibleModules']),
        isDispatchDashboard() {
            return !!this.$config.DISPATCHED_DEPLOYMENTS?.length
        },
        visibleReports() {
            if (
                ['rock.estate', 'rockestate.be'].includes(
                    this.$store.state.auth.organization
                )
            ) {
                return ['avm_pdf', 'dvm_pdf', 'avm_web', 'dvm_web', 'ovm_web', 'ovm_pdf']
            } else {
                return this.$config.REPORT_VISIBLE
            }
        },
        showOwnershipButton() {
            return (
                this.hasRole('substitute') &&
                !this.hasRole('valuer', 'dvm') &&
                this.$config.SUBSTITUTE_FEATURE_ENABLED
            )
        },
        reportUrl() {
            const lang = this.row.last_submission_lang || this.$route.params.lang
            const baseString = this.isDispatchDashboard
                ? `//${this.row.deployment}.rock.estate`
                : ''
            let valuation_request_ref = this.isDispatchDashboard
                ? this.row.display_request_ref
                : this.row.valuation_request_ref
            return (
                baseString +
                `${utils.urlJoin(this.$config.VALUATION_API_URL, '/report')}?` +
                `valuation_request_ref=${valuation_request_ref}` +
                `&lang=${lang}`
            )
        },
        valuationActions() {
            return [
                // {
                //     label: 'info',
                //     icon: 'help-circle',
                //     show:  this.row.status === 'needs-review',
                //     click:  () => this.view_details(this.row),
                // },
                {
                    label: 'edit',
                    type: 'is-warning',
                    icon: 'pencil',
                    target: this.isDispatchDashboard ? '_blank' : null,
                    show: this.row.owner.is_self && this.row.status === 'draft',
                    href: this.isDispatchDashboard
                        ? `https://${this.row.deployment}.rock.estate/valuation/${this.$route.params.lang}/request/extra-info?valuation_request_ref=${this.row.display_request_ref}`
                        : null,
                    to: !this.isDispatchDashboard
                        ? {
                              name: 'valuation.edit',
                              query: {
                                  valuation_request_ref: this.row.valuation_request_ref,
                              },
                          }
                        : null,
                },
                {
                    label: 'edit',
                    type: 'is-warning',
                    icon: 'pencil',
                    show:
                        this.row.owner.is_self &&
                        this.row.valuation_type !== 'ovm' &&
                        this.row.status === 'submitted' &&
                        this.now - this.row.modified_at < 1000 * 60 * 60,
                    click: () => this.unsubmit(this.row),
                },
                {
                    label: 'cancel',
                    type: 'is-danger',
                    icon: 'cancel',
                    show:
                        (!this.isDispatchDashboard && this.hasRole('valuer', 'dvm')) ||
                        (this.row.owner.is_self &&
                            (this.row.status === 'draft' ||
                                (this.row.status === 'submitted' &&
                                    this.now - this.row.modified_at < 1000 * 60 * 60))),
                    click: () => this.cancel(this.row),
                },
                {
                    label: 'appointment',
                    type: 'is-primary',
                    icon: 'eye',
                    show: this.row.valuation_type === 'ovm',
                    to:
                        this.row.borrower &&
                        this.row.borrower.is_self &&
                        this.row.status === 'submitted'
                            ? {
                                  name: 'valuation.ovm.borrower',
                                  query: {
                                      step: this.row.ovm_status,
                                      valuation_request_ref: this.row
                                          .valuation_request_ref,
                                  },
                              }
                            : {
                                  name: 'valuation.ovm.summary',
                                  query: {
                                      valuation_request_ref: this.row
                                          .valuation_request_ref,
                                  },
                              },
                },
                {
                    label: 'view_request',
                    icon: 'eye',
                    show:
                        (this.row.owner.is_self || this.hasRole('reporter')) &&
                        !this.hasRole('valuer') &&
                        ['submitted', 'needs-review', 'valued', 'exotic'].includes(
                            this.row.status
                        ),
                    to: {
                        name: 'valuation.request',
                        params: {
                            action:
                                this.row.valuation_type === 'ovm' ? 'ovm' : 'extra-info',
                        },
                        query: {
                            valuation_request_ref: this.row.valuation_request_ref,
                        },
                    },
                },
                {
                    label: 'dvm',
                    type: 'is-primary',
                    icon: 'eye',
                    target: this.isDispatchDashboard ? '_blank' : null,
                    show:
                        this.hasRole('valuer', 'dvm') &&
                        this.row.valuation_type === 'dvm' &&
                        // this.row.valuer?.is_self &&
                        [
                            'submitted',
                            'needs-review',
                            'valued',
                            'awaiting-onsite',
                        ].includes(this.row.status),
                    href: this.isDispatchDashboard
                        ? `https://${this.row.deployment}.rock.estate/valuation/${this.$route.params.lang}/dvm?valuation_request_ref=${this.row.display_request_ref}`
                        : null,
                    to: !this.isDispatchDashboard
                        ? {
                              name: 'valuation.dvm',
                              query: {
                                  valuation_request_ref: this.row.valuation_request_ref,
                              },
                          }
                        : null,
                },
                // We'll keep the old valuer tool around until the new one is feature complete
                {
                    label: 'ovm',
                    type: 'is-primary',
                    icon: 'eye',
                    target: this.isDispatchDashboard ? '_blank' : null,
                    show:
                        this.hasRole('valuer', 'ovm') &&
                        this.row.valuation_type === 'ovm' &&
                        this.row.valuer?.is_self &&
                        ['submitted', 'valued'].includes(this.row.status),
                    href: this.isDispatchDashboard
                        ? `https://${this.row.deployment}.rock.estate/valuation/${this.$route.params.lang}/valuer-app?valuation_request_ref=${this.row.display_request_ref}`
                        : null,
                    to: !this.isDispatchDashboard
                        ? {
                              name: 'valuation.valuer-app',
                              query: {
                                  valuation_request_ref: this.row.valuation_request_ref,
                              },
                          }
                        : null,
                },
                // {
                //     label: 'automatic_valuation',
                //     type: 'is-success',
                //     icon: 'eye',
                //     show:  this.row.status === 'submitted' || this.row.status === 'valued',
                //     to:  ({
                //         name: 'valuation.avm',
                //         params: { step: 1 },
                //         query: {
                //             valuation_request_ref: this.row.valuation_request_ref,
                //         },
                //     }),
                // },
                {
                    label: 'web_report',
                    icon: 'file-document-outline',
                    type: 'is-success',
                    show:
                        (this.row.owner.is_self ||
                            this.hasRole('reporter') ||
                            this.hasRole('valuer')) &&
                        this.showReport(this.row.status, 'web'),
                    to: {
                        name: 'valuation.report',
                        query: {
                            valuation_request_ref: this.row.valuation_request_ref,
                            static: 'true',
                        },
                    },
                },
                {
                    label: this.disabledDownloads ? 'downloading' : 'pdf_report',
                    type: 'is-success',
                    icon: 'download',
                    show:
                        (this.row.owner.is_self ||
                            this.hasRole('reporter') ||
                            this.hasRole('valuer')) &&
                        this.showReport(this.row.status, 'pdf'),
                    href: this.reportUrl,
                    click: () => {
                        this.disabledDownloads = true
                    },
                    disabled: this.disabledDownloads,
                },
                {
                    label: 'take_ownership',
                    type: 'is-warning',
                    icon: 'account-convert',
                    show: this.showOwnershipButton && !this.row.owner.is_self,
                    click: () => this.$emit('ownership'),
                },
            ]
        },
        ersActions() {
            return [
                {
                    label: 'dashboard.reference_listing.view_button',
                    type: 'is-primary',
                    show: this.row.status === 'submitted',
                    to: {
                        name: 'ers.processing',
                        params: {
                            lang: this.$route.params.lang,
                        },
                        query: {
                            ref: this.row.valuation_request_ref,
                        },
                    },
                },
                {
                    label: 'dashboard.reference_listing.edit_button',
                    type: 'is-warning',
                    icon: 'pencil',
                    loading: this.ersEditLoading,
                    show:
                        (this.row.status === 'draft' || this.row.status === 'failed') &&
                        this.row.owner.is_self,
                    click: () =>
                        this.ers_edit_draft(
                            this.row.valuation_request_ref,
                            this.row.status
                        ),
                },
                {
                    label: 'take_ownership',
                    type: 'is-warning',
                    icon: 'account-convert',
                    show: this.showOwnershipButton && !this.row.owner.is_self,
                    click: () => this.$emit('ownership'),
                },
                {
                    label: 'web_report',
                    type: 'is-success',
                    icon: 'file-document-outline',
                    show: this.row.status === 'valued' && this.row.owner.is_self,
                    to: {
                        name: 'ers.result',
                        params: {
                            lang: this.$route.params.lang,
                        },
                        query: {
                            ref: this.row.valuation_request_ref,
                        },
                    },
                },
            ]
        },
        actions() {
            if (this.row.valuation_type === 'ers')
                return this.ersActions.filter((a) => a.show)
            return this.valuationActions.filter((a) => a.show)
        },
    },
    methods: {
        unsubmit() {
            axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_status(this.row.valuation_request_ref),
                    null,
                    {
                        params: { action: 'unsubmit' },
                    }
                )
                .then(() => this.edit_draft())
        },
        edit_draft() {
            this.$router.push({
                name: 'valuation.request',
                params: {
                    action: 'extra-info',
                    lang: this.$route.params.lang,
                },
                query: {
                    valuation_request_ref: this.row.valuation_request_ref,
                },
            })
        },
        async ers_edit_draft(ref, status) {
            this.ersEditLoading = true
            await this.$store.dispatch('ersStore/fetch_features', ref)
            const res = await axios.get(
                utils.urlJoin(this.$config.VALUATION_API_URL, ['request', ref])
            )
            if (status === 'draft') {
                const lastVisitedPage = res.data.other_data.last_page_info.name
                if (lastVisitedPage) {
                    this.$router.push({
                        name: lastVisitedPage,
                        query: {
                            ref,
                        },
                    })
                }
            } else if (status === 'failed') {
                this.$refs.summaryModal.open()
            }
            this.ersEditLoading = false
        },
        cancel() {
            // TODO: confirm dialog
            axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_status(this.row.valuation_request_ref),
                    null,
                    {
                        params: { action: 'cancel' },
                    }
                )
                .then((_) => this.$emit('update', { status: 'cancelled' }))
        },
        showReport(status, variant) {
            return (
                (status === 'valued' && this.visibleReports.includes('dvm_' + variant)) ||
                (status === 'submitted' && this.visibleReports.includes('avm_' + variant))
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.buttons {
    a,
    button {
        flex-grow: 1;
    }
}
</style>

<template>
    <grid-card is-full :title="$t('features.f_garden_common')">
        <template slot="header">
            <label class="flex-label">
                <h5>{{ $t('valuer_tool.exterior.garden.question') }}</h5>
                <b-checkbox v-model="has_garden" />
            </label>
        </template>
        <template v-if="has_garden" slot="default">
            <div class="grid_card-content_grid three-cols">
                <div class="content_grid-item content_grid-item--full">
                    <label>{{ $t('dvm_features.garden_common_arrangement') }}</label>
                    <multi-choice
                        feature="garden_common_arrangement"
                        :options="arrangementValues"
                        @select="selectArrangement"
                    />
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import MultiChoice from '@/apps/Valuation/components/ValuerTool/elements/MultiChoice.vue'
import { mapGetters, mapMutations } from 'vuex'
import utils from '@/shared/plugins/utils'

export default {
    name: 'CommonGarden',
    components: {
        GridCard,
        MultiChoice,
    },
    data: () => ({
        has_garden: false,
        garden_common_arrangement: null,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getFeatures', 'getDVMFeatures']),
        arrangementValues() {
            return utils
                .generateValues(
                    'garden_common_arrangement',
                    this.garden_common_arrangement
                )
                .map((item) => ({ ...item, name: this.$t(`value.${item.value}`) }))
        },
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        has_garden(val) {
            this.SET_FEATURES({
                f_garden_common: val,
            })
        },
        garden_common_arrangement(val) {
            this.SET_DVM_FEATURES({
                garden_common_arrangement: val,
            })
        },
    },
    mounted() {
        this.has_garden = this.getFeatures?.f_garden_common
        this.garden_common_arrangement =
            this.getDVMFeatures?.garden_common_arrangement || null
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_DVM_FEATURES', 'SET_FEATURES']),
    },
}
</script>

<template>
    <grid-card title="Value" no-padding>
        <template slot="header">
            <white-button class="analytics_buttn" @click="openAnalytics">
                <span>Analytics</span>
                <svg-icon icon="chart" height="16" scale="1" color="#001837" />
            </white-button>
        </template>
        <template slot="default">
            <div class="cadastral-container">
                <b-field :label="$t('valuation.cadastral_income')">
                    <b-input v-model="cadastral_income" />
                </b-field>
            </div>
            <div class="prices_grid">
                <div class="prices_grid-item">
                    <h4>{{ $t('valuation.market_value') }}</h4>
                    <p class="prices_grid-item-price--blue">
                        {{ priceFormat(getAvmTransactionEstimations.asking_price_q50) }}
                    </p>
                </div>
                <div class="prices_grid-item">
                    <h4>{{ $t('valuation.forced_sale_value') }}</h4>
                    <p>{{ priceFormat(forced_sale_value) }}</p>
                </div>
                <div class="prices_grid-item">
                    <h4>{{ $t('valuation.rental_value') }}</h4>
                    <p>
                        {{ priceFormat(getAvmRentalEstimations.asking_price_q50, false) }}
                    </p>
                </div>
                <div class="prices_grid-item">
                    <h4>{{ $t('valuation.reconstruction_value') }}</h4>
                    <p v-if="reconstruction_value_loaded">
                        {{ priceFormat(reconstruction_value) }}
                    </p>
                    <b-skeleton
                        height="32px"
                        width="100%"
                        :rounded="true"
                        :active="!reconstruction_value_loaded"
                    />
                </div>
            </div>
        </template>
        <b-modal v-model="analyticsShown" width="fit-content">
            <analytics />
        </b-modal>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import WhiteButton from '@/apps/Valuation/components/ValuerTool/elements/WhiteButton.vue'
import Analytics from './Analytics.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Value',
    components: {
        GridCard,
        WhiteButton,
        Analytics,
    },
    data: () => ({
        cadastral_income: null,
        reconstruction_value: null,
        reconstruction_value_loaded: false,
        analyticsShown: false,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getAvmTransactionEstimations',
            'getAvmRentalEstimations',
            'getFeatures',
            'getValuation',
        ]),
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        forced_sale_value() {
            return utils.forced_sale_value(
                this.getAvmTransactionEstimations.asking_price_q50
            )
        },
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        cadastral_income(val) {
            this.SET_VALUATION({
                cadastral_income: +val,
            })
        },
    },
    mounted() {
        if (!this.is_plot) {
            this.getReconstructionValue()
        }
        this.cadastral_income = this.getValuation.cadastral_income
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_VALUATION']),
        priceFormat(x, thousands = true) {
            return utils.priceFormat(x, thousands)
        },
        getReconstructionValue() {
            if (this.is_plot) return 'N/A'
            let url = utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                'estimate',
                'reconstruction',
                this.getFeatures.building_id,
            ])
            url += `?f_building_type=${this.getFeatures.f_building_type}&f_living_area=${this.getFeatures.f_living_area}`
            this.$axios.get(url).then((res) => {
                this.reconstruction_value = res.data.reconstruction_value
                    ? Math.round(res.data.reconstruction_value / 1000) * 1000
                    : null
                this.reconstruction_value_loaded = true
            })
        },
        openAnalytics() {
            this.analyticsShown = true
        },
        closeAnalytics() {
            this.analyticsShown = false
        },
    },
}
</script>
<style lang="scss" scoped>
.analytics_buttn {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: fit-content;

    span {
        color: #001837;
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
    }
}
.cadastral-container {
    padding: 24px;
    padding-top: 0;
}
.prices_grid {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    gap: 1px;
    background: #d6e2e9;
    padding-top: 1px;

    &-item {
        padding: 24px;
        display: flex;
        flex-direction: column;
        background: white;

        h4 {
            color: #798da6;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
        }

        p {
            font-size: 24px;
            font-weight: 700;
        }

        &-price--blue {
            color: #009cff;
        }
    }
}
</style>

<i18n>
{
    "en-BE":{
        "environment": {
            "title": "Environment (not shown on the report)",
            "below-avg": "Below average",
            "avg": "Average",
            "above-avg": "Above average"
        },
        "total_influence": "Total Deviation",
        "avm_features_influence": "AVM Features Deviation",
        "market_correction": "Market Correction",
        "dvm_adjustment": "Total DVM Adjustment",
        "apply_influence": "Apply Features Deviation",
        "update_remarks": "Update Internal Remarks with Deviations",
        "overridden_influence": "Overridden Deviation"
    },
    "fr-BE":{
        "environment": {
            "title": "Environnement (not shown on the report)",
            "below-avg": "Sous la moyenne",
            "avg": "Dans la moyenne",
            "above-avg": "Au dessus de la moyenne"
        },
        "total_influence": "Déviation totale",
        "avm_features_influence": "Déviation des critères AVM",
        "market_correction": "Correction marchande",
        "dvm_adjustment": "Ajustement DVM total",
        "apply_influence": "Appliquer les ajustements",
        "update_remarks": "Ajouter les ajustements aux remarques internes",
        "overridden_influence": "Deviation manuelle"
    },
    "nl-BE":{
        "environment": {
            "title": "Environment (not shown on the report)",
            "below-avg": "Below average",
            "avg": "Average",
            "above-avg": "Above average"
        },
        "total_influence": "Total Deviation",
        "avm_features_influence": "AVM Features Deviation",
        "market_correction": "Market Correction",
        "dvm_adjustment": "Total DVM Adjustment",
        "apply_influence": "Apply Features Deviation",
        "update_remarks": "Update Internal Remarks with Deviations",
        "overridden_influence": "Overridden Deviation"
    }
}
</i18n>
<template>
    <div>
        <h1 class="is-size-4 has-text-black has-text-weight-bold">DVM</h1>
        <div class="is-w100 is-grid">
            <template v-if="is_building">
                <h3 class="has-text-right">
                    {{ $t('dvm_features.style_of_house') }}
                </h3>
                <div class="is-flex radio-button-group">
                    <b-radio-button
                        v-model="dvm_features.style_of_house"
                        size="is-small"
                        type="is-primary"
                        native-value="traditional"
                    >
                        <span>{{ $t('value.traditional') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.style_of_house"
                        size="is-small"
                        type="is-primary"
                        native-value="modern"
                    >
                        <span>{{ $t('value.modern') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.style_of_house"
                        size="is-small"
                        type="is-primary"
                        native-value="contemporary"
                    >
                        <span>{{ $t('value.contemporary') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.style_of_house"
                        size="is-small"
                        type="is-primary"
                        native-value="mediterranean"
                    >
                        <span>{{ $t('value.mediterranean') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.style_of_house"
                        size="is-small"
                        type="is-primary"
                        native-value="other"
                    >
                        <span>{{ $t('value.other') }}</span>
                    </b-radio-button>
                </div>
                <div class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.style_of_house"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 class="has-text-right">
                    {{ $t('dvm_features.exterior_state') }}
                </h3>
                <div class="is-flex radio-button-group">
                    <b-radio-button
                        v-model="dvm_features.exterior_state"
                        size="is-small"
                        type="is-primary"
                        native-value="maintenance_required"
                    >
                        <span>{{ $t('value.maintenance_required') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.exterior_state"
                        size="is-small"
                        type="is-primary"
                        native-value="good"
                    >
                        <span>{{ $t('value.good') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.exterior_state"
                        size="is-small"
                        type="is-primary"
                        native-value="very_good"
                    >
                        <span>{{ $t('value.very_good') }}</span>
                    </b-radio-button>
                </div>
                <div class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.exterior_state"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 class="has-text-right">
                    {{ $t('dvm_features.facade_material') }}
                </h3>
                <div class="is-flex radio-button-group">
                    <b-checkbox-button
                        v-model="dvm_features.facade_material"
                        size="is-small"
                        type="is-primary"
                        native-value="brick"
                    >
                        <span>{{ $t('value.brick') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.facade_material"
                        size="is-small"
                        type="is-primary"
                        native-value="cinder_blocks"
                    >
                        <span>{{ $t('value.cinder_blocks') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.facade_material"
                        size="is-small"
                        type="is-primary"
                        native-value="wood"
                    >
                        <span>{{ $t('value.wood') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.facade_material"
                        size="is-small"
                        type="is-primary"
                        native-value="plaster"
                    >
                        <span>{{ $t('value.plaster') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.facade_material"
                        size="is-small"
                        type="is-primary"
                        native-value="natural_stone"
                    >
                        <span>{{ $t('value.natural_stone') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.facade_material"
                        size="is-small"
                        type="is-primary"
                        native-value="metal"
                    >
                        <span>{{ $t('value.metal') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.facade_material"
                        size="is-small"
                        type="is-primary"
                        native-value="concrete"
                    >
                        <span>{{ $t('value.concrete') }}</span>
                    </b-checkbox-button>
                </div>
                <div class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.facade_material"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 class="has-text-right">
                    {{ $t('dvm_features.roof_material') }}
                </h3>
                <div class="is-flex radio-button-group">
                    <b-checkbox-button
                        v-model="dvm_features.roof_material"
                        size="is-small"
                        type="is-primary"
                        native-value="tiles"
                    >
                        <span>{{ $t('value.tiles') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.roof_material"
                        size="is-small"
                        type="is-primary"
                        native-value="bitumen_roofing"
                    >
                        <span>{{ $t('value.bitumen_roofing') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.roof_material"
                        size="is-small"
                        type="is-primary"
                        native-value="metal"
                    >
                        <span>{{ $t('value.metal') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.roof_material"
                        size="is-small"
                        type="is-primary"
                        native-value="slate_shingles"
                    >
                        <span>{{ $t('value.slate_shingles') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.roof_material"
                        size="is-small"
                        type="is-primary"
                        native-value="black_concrete_tiles"
                    >
                        <span>{{ $t('value.black_concrete_tiles') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.roof_material"
                        size="is-small"
                        type="is-primary"
                        native-value="thatched"
                    >
                        <span>{{ $t('value.thatched') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.roof_material"
                        size="is-small"
                        type="is-primary"
                        native-value="asbestos"
                    >
                        <span>{{ $t('value.asbestos') }}</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="dvm_features.roof_material"
                        size="is-small"
                        type="is-primary"
                        native-value="green_roof"
                    >
                        <span>{{ $t('value.green_roof') }}</span>
                    </b-checkbox-button>
                </div>
                <div class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.roof_material"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 v-if="!is_apartment" class="has-text-right">
                    {{ $t('dvm_features.n_roof_windows') }}
                </h3>
                <b-input
                    v-if="!is_apartment"
                    v-model="dvm_features.n_roof_windows"
                    type="number"
                    size="is-small"
                />
                <div v-if="!is_apartment" class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.n_roof_windows"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 v-if="!is_apartment" class="has-text-right">
                    {{ $t('dvm_features.n_roof_dormers') }}
                </h3>
                <b-input
                    v-if="!is_apartment"
                    v-model="dvm_features.n_roof_dormers"
                    type="number"
                    size="is-small"
                />
                <div v-if="!is_apartment" class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.n_roof_dormers"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 v-if="!is_apartment" class="has-text-right">
                    {{ $t('dvm_features.solar_panel_area') }}
                </h3>
                <b-input
                    v-if="!is_apartment"
                    v-model="dvm_features.solar_panel_area"
                    type="number"
                    size="is-small"
                />
                <div v-if="!is_apartment" class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.solar_panel_area"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 class="has-text-right">
                    {{ $t('dvm_features.f_building_listed') }}
                </h3>
                <div class="is-flex radio-button-group">
                    <b-radio-button
                        v-model="dvm_features.f_building_listed"
                        size="is-small"
                        type="is-primary"
                        :native-value="true"
                    >
                        <span>{{ $t('value.yes') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.f_building_listed"
                        size="is-small"
                        type="is-primary"
                        :native-value="false"
                    >
                        <span>{{ $t('value.no') }}</span>
                    </b-radio-button>
                </div>
                <div class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.f_building_listed"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 class="has-text-right">
                    {{ $t('dvm_features.garden_orientation') }}
                </h3>
                <div class="is-flex radio-button-group">
                    <b-radio-button
                        v-model="dvm_features.garden_orientation"
                        size="is-small"
                        type="is-primary"
                        native-value="n"
                    >
                        <span>{{ $t('value.n') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.garden_orientation"
                        size="is-small"
                        type="is-primary"
                        native-value="ne"
                    >
                        <span>{{ $t('value.ne') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.garden_orientation"
                        size="is-small"
                        type="is-primary"
                        native-value="e"
                    >
                        <span>{{ $t('value.e') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.garden_orientation"
                        size="is-small"
                        type="is-primary"
                        native-value="se"
                    >
                        <span>{{ $t('value.se') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.garden_orientation"
                        size="is-small"
                        type="is-primary"
                        native-value="s"
                    >
                        <span>{{ $t('value.s') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.garden_orientation"
                        size="is-small"
                        type="is-primary"
                        native-value="sw"
                    >
                        <span>{{ $t('value.sw') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.garden_orientation"
                        size="is-small"
                        type="is-primary"
                        native-value="w"
                    >
                        <span>{{ $t('value.w') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="dvm_features.garden_orientation"
                        size="is-small"
                        type="is-primary"
                        native-value="nw"
                    >
                        <span>{{ $t('value.nw') }}</span>
                    </b-radio-button>
                </div>
                <div class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.garden_orientation"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 class="has-text-right">
                    {{ $t('dvm_features.f_swimming_pool') }}
                </h3>
                <div class="is-flex radio-button-group">
                    <b-radio-button
                        v-model="features.f_swimming_pool"
                        size="is-small"
                        type="is-primary"
                        :native-value="true"
                    >
                        <span>{{ $t('value.yes') }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="features.f_swimming_pool"
                        size="is-small"
                        type="is-primary"
                        :native-value="false"
                    >
                        <span>{{ $t('value.no') }}</span>
                    </b-radio-button>
                </div>
                <div class="is-flex is-align-items-center">
                    <b-input
                        v-model="influence.outsidePool"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
                <h3 v-if="features.f_swimming_pool" class="has-text-right">
                    {{ $t('dvm_features.f_swimming_pool_area') }}
                </h3>
                <b-input
                    v-if="features.f_swimming_pool"
                    v-model="features.f_swimming_pools_area"
                    size="is-small"
                />
                <div v-if="features.f_swimming_pool" />
            </template>
            <h3 class="has-text-right">
                {{ $t('dvm_features.direct_traffic_level') }}
            </h3>
            <div class="is-flex radio-button-group">
                <b-radio-button
                    v-model="dvm_features.direct_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="low"
                >
                    <span>{{ $t('value.low') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.direct_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="medium"
                >
                    <span>{{ $t('value.medium') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.direct_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="high"
                >
                    <span>{{ $t('value.high') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="influence.direct_traffic_level"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.indirect_traffic_level') }}
            </h3>
            <div class="is-flex radio-button-group">
                <b-radio-button
                    v-model="dvm_features.indirect_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="low"
                >
                    <span>{{ $t('value.low') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.indirect_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="medium"
                >
                    <span>{{ $t('value.medium') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.indirect_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="high"
                >
                    <span>{{ $t('value.high') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="influence.indirect_traffic_level"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.surroundings') }}
            </h3>
            <div class="is-flex radio-button-group">
                <b-radio-button
                    v-model="dvm_features.surroundings"
                    size="is-small"
                    type="is-primary"
                    native-value="urban"
                >
                    <span>{{ $t('value.urban') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.surroundings"
                    size="is-small"
                    type="is-primary"
                    native-value="rural"
                >
                    <span>{{ $t('value.rural') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.surroundings"
                    size="is-small"
                    type="is-primary"
                    native-value="isolated"
                >
                    <span>{{ $t('value.isolated') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input v-model="influence.surroundings" type="number" size="is-small" />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.other_positive') }}
            </h3>
            <div class="is-flex radio-button-group">
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="classified_facade"
                    size="is-small"
                >
                    {{ $t('value.classified_facade') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="green_neighbourhood"
                    size="is-small"
                >
                    {{ $t('value.green_neighbourhood') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="sea_view"
                    size="is-small"
                >
                    {{ $t('value.sea_view') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="no_direct_traffic"
                    size="is-small"
                >
                    {{ $t('value.no_direct_traffic') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="exceptional_view"
                    size="is-small"
                >
                    {{ $t('value.exceptional_view') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="other"
                    size="is-small"
                >
                    {{ $t('value.other') }}
                </b-checkbox-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="influence.other_positive"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <template v-if="dvm_features.other_positive.includes('other')">
                <h3 class="has-text-right">{{ $t('value.other') }}:</h3>
                <b-input v-model="dvm_features.other_positive_details" size="is-small" />
                <div />
            </template>
            <h3 class="has-text-right">
                {{ $t('dvm_features.other_negative') }}
            </h3>
            <div class="is-flex radio-button-group">
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="tram"
                    size="is-small"
                >
                    {{ $t('value.tram') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="train"
                    size="is-small"
                >
                    {{ $t('value.train') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="metro"
                    size="is-small"
                >
                    {{ $t('value.metro') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="traffic"
                    size="is-small"
                >
                    {{ $t('value.traffic') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="busy"
                    size="is-small"
                >
                    {{ $t('value.busy_area') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="other"
                    size="is-small"
                >
                    {{ $t('value.other') }}
                </b-checkbox-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="influence.other_negative"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <template v-if="dvm_features.other_negative.includes('other')">
                <h3 class="has-text-right">{{ $t('value.other') }}:</h3>
                <b-input v-model="dvm_features.other_negative_details" size="is-small" />
                <div />
            </template>
            <h3 class="has-text-right">
                {{ $t('environment.title') }}
            </h3>
            <div class="is-flex radio-button-group">
                <b-radio-button
                    v-model="dvm_features.environment"
                    size="is-small"
                    type="is-primary"
                    native-value="below-avg"
                >
                    <span>{{ $t('environment.below-avg') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.environment"
                    size="is-small"
                    type="is-primary"
                    native-value="avg"
                >
                    <span>{{ $t('environment.avg') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.environment"
                    size="is-small"
                    type="is-primary"
                    native-value="above-avg"
                >
                    <span>{{ $t('environment.above-avg') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input v-model="influence.environment" type="number" size="is-small" />
                <span class="ml-1">%</span>
            </div>
            <div />
            <h3 class="has-text-right">{{ $t('total_influence') }}</h3>
            <div class="is-flex is-align-items-center">
                <b-input
                    :value="influence_sum + avm_influence"
                    type="number"
                    size="is-small"
                    disabled
                />
                <span class="ml-1">%</span>
            </div>
            <div />
            <h3 class="has-text-right">{{ $t('market_correction') }}</h3>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="influence.marketCorrection"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">{{ $t('dvm_adjustment') }}</h3>
            <div class="is-flex is-align-items-center">
                <b-button
                    type="is-primary"
                    size="is-small"
                    class="mr-2"
                    @click="applyInfluence"
                >
                    {{ $t('apply_influence') }}
                </b-button>
                <b-slider
                    :value="+total_influence"
                    size="is-small"
                    type="is-primary"
                    :min="-100"
                    :max="200"
                    @change="setInfluence"
                    @dragging="setInfluence"
                />
            </div>
            <b-field type="is-black" class="is-flex is-align-items-center mb-0">
                <b-input v-model="total_influence" type="number" size="is-small" />
                <span class="ml-1">%</span>
            </b-field>
            <div />
            <h3 class="has-text-right">Override prices</h3>
            <b-switch v-model="price_override_status" />
            <!-- <b-switch :value="price_override_status" @input="togglePriceOverride" /> -->

            <div />
            <h3 class="has-text-right">AVM estimated price</h3>
            <b-input size="is-small" :value="withSpaces(prices.avm_price)" disabled />

            <template v-if="price_override_status && !prices_reloading">
                <div />
                <h3 class="has-text-right">{{ $t('valuation.market_value') }}</h3>
                <b-input
                    size="is-small"
                    :value="prices_override.market_value"
                    @input="(e) => updatePrice(e, 'market_value')"
                />
                <div />
                <h3 class="has-text-right">{{ $t('valuation.forced_sale_value') }}</h3>
                <b-input
                    size="is-small"
                    :value="prices_override.forced_sale_value"
                    @input="(e) => updatePrice(e, 'forced_sale_value')"
                />
                <template v-if="is_building">
                    <div />
                    <h3 class="has-text-right">{{ $t('valuation.rental_value') }}</h3>
                    <b-input
                        size="is-small"
                        :value="prices_override.rental_value"
                        @input="(e) => updatePrice(e, 'rental_value')"
                    />
                </template>
                <div />
                <h3 class="has-text-right">{{ $t('valuation.reconstruction_value') }}</h3>
                <b-input
                    size="is-small"
                    :value="prices_override.reconstruction_value"
                    @input="(e) => updatePrice(e, 'reconstruction_value')"
                />
            </template>
            <template v-else>
                <div />
                <h3 class="has-text-right">{{ $t('valuation.market_value') }}</h3>
                <b-input size="is-small" :value="withSpaces(market_value)" disabled />
                <div />
                <h3 class="has-text-right">{{ $t('valuation.forced_sale_value') }}</h3>
                <b-input
                    size="is-small"
                    :value="withSpaces(forced_sale_value)"
                    disabled
                />
                <template v-if="is_building">
                    <div />
                    <h3 class="has-text-right">{{ $t('valuation.rental_value') }}</h3>
                    <b-input size="is-small" :value="withSpaces(rental_value)" disabled />
                </template>
                <div />
                <h3 class="has-text-right">{{ $t('valuation.reconstruction_value') }}</h3>
                <b-input
                    size="is-small"
                    :value="withSpaces(prices.reconstruction_value)"
                    disabled
                />
            </template>
            <div class="bottom-buttons mb-2">
                <b-button
                    v-show="price_override_status"
                    size="is-small"
                    type="is-primary"
                    @click="resetPriceOverride"
                >
                    Set prices according to deviation
                </b-button>
                <b-button size="is-small" type="is-warning" @click="setInternalRemarks">
                    {{ $t('update_remarks') }}
                </b-button>
            </div>
        </div>
        <b-field :label="$t('dvm_features.remarks')" class="mb-4 mx-0 valuer-remarks">
            <b-input v-model="remarks" type="textarea" rows="6" lazy />
        </b-field>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import debounce from 'lodash/debounce'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'DesktopValue',
    props: {
        prices: {
            type: Object,
            required: true,
        },
        reconstructionValue: {
            type: Number,
            default: 0,
        },
        updateRemarks: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            features: {
                f_swimming_pool: false,
                f_swimming_pools_area: 0,
            },
            dvm_features: {
                style_of_house: null,
                exterior_state: null,
                facade_material: [],
                roof_material: [],

                n_roof_windows: 0,
                n_roof_dormers: 0,
                solar_panel_area: 0,

                f_building_listed: false,
                garden_orientation: null,
                outsidePool: false,
                pool_size: 0,
                direct_traffic_level: null,
                indirect_traffic_level: null,
                surroundings: null,
                environment: null,
                other_positive: [],
                other_positive_details: '',
                other_negative: [],
                other_negative_details: '',
            },
            influence: {
                style_of_house: 0,
                exterior_state: 0,
                facade_material: 0,
                roof_material: 0,

                n_roof_windows: 0,
                n_roof_dormers: 0,
                solar_panel_area: 0,

                f_building_listed: 0,
                garden_orientation: 0,
                outsidePool: 0,
                direct_traffic_level: 0,
                indirect_traffic_level: 0,
                surroundings: 0,
                environment: 0,
                other_positive: 0,
                other_negative: 0,
                marketCorrection: 0,
            },
            remarks: '',
            total_influence: 0,
            price_override_status: false,
            prices_reloading: false,
            prices_override: {
                market_value: 0,
                forced_sale_value: 0,
                rental_value: 0,
                reconstruction_value: 0,
            },
        }
    },
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getDVMFeatures',
            'getPricesOverride',
            'getValuation',
            'getFeatures',
        ]),
        is_apartment() {
            return utils.is_apartment(this.features.f_building_type)
        },
        is_house() {
            return utils.is_house(this.features.f_building_type)
        },
        is_building() {
            return utils.is_building(this.features.f_building_type)
        },
        influence_sum() {
            let dev = 0
            for (const [key, value] of Object.entries(this.influence)) {
                if (
                    ![
                        'marketCorrection',
                        'epc',
                        'year',
                        'living',
                        'building',
                        'parcel',
                        'garden',
                        'sides',
                    ].includes(key)
                )
                    dev += +value
            }
            return dev
        },
        market_value() {
            return Math.round(
                this.prices.market_value * (1 + parseInt(this.total_influence) / 100)
            )
        },
        forced_sale_value() {
            return (
                Math.round(
                    (this.prices.forced_sale_value *
                        (1 + parseInt(this.total_influence) / 100)) /
                        1000
                ) * 1000
            )
        },
        rental_value() {
            if (!this.is_building) return 0
            return (
                Math.round(
                    (this.prices.rental_value *
                        (1 + parseInt(this.total_influence) / 100)) /
                        10
                ) * 10
            )
        },
        avm_influence() {
            let { epc, year, living, building, parcel, garden, sides } = this.influence

            if (isNaN(parseInt(epc))) {
                epc = 0
            } else {
                epc = parseInt(epc)
            }
            if (isNaN(parseInt(year))) {
                year = 0
            } else {
                year = parseInt(year)
            }
            if (isNaN(parseInt(living))) {
                living = 0
            } else {
                living = parseInt(living)
            }
            if (isNaN(parseInt(building))) {
                building = 0
            } else {
                building = parseInt(building)
            }
            if (isNaN(parseInt(parcel))) {
                parcel = 0
            } else {
                parcel = parseInt(parcel)
            }
            if (isNaN(parseInt(garden))) {
                garden = 0
            } else {
                garden = parseInt(garden)
            }
            if (isNaN(parseInt(sides))) {
                sides = 0
            } else {
                sides = parseInt(sides)
            }

            return epc + year + living + building + parcel + garden + sides
        },
    },
    watch: {
        dvm_features: {
            deep: true,
            handler(val) {
                this.SET_DVM_FEATURES(val)
            },
        },
        influence: {
            deep: true,
            handler(val) {
                this.SET_INFLUENCE(val)
            },
        },
        total_influence(val) {
            this.SET_TOTAL_INFLUENCE(val)
        },
        price_override_status(val) {
            this.togglePriceOverride(val)
        },
        remarks(val) {
            this.SET_VALUATION({ remarks: val })
        },
        features: {
            deep: true,
            handler(val) {
                this.SET_FEATURES(val)
            },
        },
    },
    mounted() {
        this.price_override_status = this.getValuation.override_price
        this.influence = this.getValuation.influence
        this.total_influence = this.getValuation.total_influence
        this.remarks = this.getValuation.remarks
        this.dvm_features = this.getDVMFeatures
        this.features = this.getFeatures

        if (!this.price_override_status) {
            let prices = {
                market_value: this.market_value,
                forced_sale_value: this.forced_sale_value,
                reconstruction_value: this.reconstructionValue,
                rental_value: this.rental_value,
            }

            this.SET_PRICES_OVERRIDE({ ...prices })
        }

        this.prices_override = this.getPricesOverride
    },
    methods: {
        ...mapMutations('valuationStore/Address', [
            'SET_DVM_FEATURES',
            'SET_INFLUENCE',
            'SET_TOTAL_INFLUENCE',
            'SET_VALUATION',
            'SET_PRICES_OVERRIDE',
            'SET_SPECIFIC_PRICE_OVERRIDE',
            'SET_PRICES_OVERRIDE_STATE',
            'SET_FEATURES',
        ]),
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
        toggleElement(category, element) {
            this[category][element] = !this[category][element]
        },
        applyInfluence() {
            this.total_influence =
                this.influence_sum +
                +this.influence.marketCorrection +
                +this.avm_influence
        },
        setInfluence: debounce(function(value) {
            this.total_influence = +value
        }, 500),
        resetPriceOverride() {
            let prices = {
                market_value: this.market_value,
                forced_sale_value: this.forced_sale_value,
                rental_value: this.rental_value,
                reconstruction_value: this.prices.reconstruction_value,
            }

            this.SET_PRICES_OVERRIDE({ ...prices })
            this.prices_override = this.getPricesOverride
            this.prices_reloading = true
            this.prices_reloading = false
        },
        updatePrice(e, key) {
            let data = { key, price: +e }
            this.SET_SPECIFIC_PRICE_OVERRIDE(data)
        },
        togglePriceOverride(e) {
            this.SET_PRICES_OVERRIDE_STATE(e)
        },
        setInternalRemarks() {
            let remarks = 'DVM Deviations:\n'

            if (
                this.influence.style_of_house ||
                this.influence.exterior_state ||
                this.influence.facade_material ||
                this.influence.roof_material ||
                (this.is_house && this.influence.n_roof_windows) ||
                (this.is_house && this.influence.n_roof_dormers) ||
                (this.is_house && this.influence.solar_panel_area) ||
                this.influence.f_building_listed ||
                this.influence.garden_orientation ||
                this.influence.outsidePool
            ) {
                remarks += `Building Characteristics:\n`
            }

            if (this.influence.style_of_house) {
                remarks += `${this.$t('dvm_features.style_of_house')}: ${this.$t(
                    'value.' + this.dvm_features.style_of_house
                )} => ${this.influence.style_of_house}%`
                remarks += '\n'
            }

            if (this.influence.exterior_state) {
                remarks += `${this.$t('dvm_features.exterior_state')}: ${this.$t(
                    'value.' + this.dvm_features.exterior_state
                )} => ${this.influence.exterior_state}%`
                remarks += '\n'
            }

            if (this.influence.facade_material) {
                remarks += `${this.$t('dvm_features.facade_material')}: ${this.$t(
                    'value.' + this.dvm_features.facade_material
                )} => ${this.influence.facade_material}%`
                remarks += '\n'
            }

            if (this.influence.roof_material) {
                remarks += `${this.$t('dvm_features.roof_material')}: ${this.$t(
                    'value.' + this.dvm_features.roof_material
                )} => ${this.influence.roof_material}%`
                remarks += '\n'
            }

            if (!this.is_apartment) {
                if (this.influence.n_roof_windows) {
                    remarks += `${this.$t('dvm_features.n_roof_windows')}: ${
                        this.dvm_features.n_roof_windows
                    } => ${this.influence.n_roof_windows}%`
                    remarks += '\n'
                }

                if (this.influence.n_roof_dormers) {
                    remarks += `${this.$t('dvm_features.n_roof_dormers')}: ${
                        this.dvm_features.n_roof_dormers
                    } => ${this.influence.n_roof_dormers}%`
                    remarks += '\n'
                }

                if (this.influence.solar_panel_area) {
                    remarks += `${this.$t('dvm_features.solar_panel_area')}: ${
                        this.dvm_features.solar_panel_area
                    } => ${this.influence.solar_panel_area}%`
                    remarks += '\n'
                }
            }

            if (this.influence.f_building_listed) {
                remarks += `${this.$t('dvm_features.f_building_listed')}: ${
                    this.dvm_features.f_building_listed
                        ? this.$t('value.yes')
                        : this.$t('value.no')
                } => ${this.influence.f_building_listed}%`
                remarks += '\n'
            }

            if (this.influence.garden_orientation) {
                remarks += `${this.$t('dvm_features.garden_orientation')}: ${this.$t(
                    'value.' + this.dvm_features.garden_orientation
                )} => ${this.influence.garden_orientation}%`
                remarks += '\n'
            }

            if (this.influence.outsidePool) {
                remarks += `${this.$t('dvm_features.f_swimming_pool')}: ${
                    this.features.f_swimming_pool
                        ? this.$t('value.yes')
                        : this.$t('value.no')
                } (${this.features.f_swimming_pools_area}sqm) => ${
                    this.influence.outsidePool
                }%`
                remarks += '\n'
            }

            if (
                this.influence.surroundings ||
                this.influence.direct_traffic_level ||
                this.influence.indirect_traffic_level ||
                this.influence.other_positive ||
                this.influence.other_negative
            ) {
                remarks += `\nEnvironmental Characteristics:\n`
            }

            if (this.influence.surroundings) {
                remarks += `${this.$t('dvm_features.surroundings')}: ${this.$t(
                    'value.' + this.dvm_features.surroundings
                )} => ${this.influence.surroundings}%`
                remarks += '\n'
            }

            if (this.influence.direct_traffic_level) {
                remarks += `${this.$t('dvm_features.direct_traffic_level')}: ${this.$t(
                    'value.' + this.dvm_features.direct_traffic_level
                )} => ${this.influence.direct_traffic_level}%`
                remarks += '\n'
            }

            if (this.influence.indirect_traffic_level) {
                remarks += `${this.$t('dvm_features.indirect_traffic_level')}: ${this.$t(
                    'value.' + this.dvm_features.indirect_traffic_level
                )} => ${this.influence.indirect_traffic_level}%`
                remarks += '\n'
            }

            if (this.influence.other_positive) {
                let positives = ''

                for (const [key, value] of Object.entries(
                    this.dvm_features.other_positive
                )) {
                    if (key != 'otherDetails') {
                        if (value && key != 'other')
                            positives += `${this.$t('value.' + value)}, `
                        if (key == 'other' && value)
                            positives += `${this.dvm_features.other_positive.otherDetails}`
                    }
                }

                if (!utils.isEmptyStr(positives)) {
                    remarks += `${this.$t(
                        'dvm_features.other_positive'
                    )}: ${positives} => ${this.influence.other_positive}%`
                    remarks += '\n'
                }
            }

            if (this.influence.other_negative) {
                let negatives = ''

                for (const [key, value] of Object.entries(
                    this.dvm_features.other_negative
                )) {
                    if (key != 'otherDetails') {
                        if (value && key != 'other')
                            negatives += `${this.$t('value.' + value)}, `
                        if (key == 'other' && value)
                            negatives += `${this.dvm_features.other_negative.otherDetails}`
                    }
                }

                if (!utils.isEmptyStr(negatives)) {
                    remarks += `${this.$t(
                        'dvm_features.other_negative'
                    )}: ${negatives} => ${this.influence.other_negative}%`
                    remarks += '\n'
                }
            }

            if (this.influence.environment) {
                remarks += `${this.$t('environment.title')}: ${this.$t(
                    'environment.' + this.dvm_features.environment
                )} => ${this.influence.environment}%`
                remarks += '\n'
            }

            remarks += '\nDeviations total:\n'
            remarks += `=> ${this.$t('total_influence')}: ${this.influence_sum}%`
            remarks += '\n'
            remarks += `=> ${this.$t('avm_features_influence')}: ${this.avm_influence}%`
            if (this.influence.marketCorrection) {
                remarks += '\n'
                remarks += `=> ${this.$t('market_correction')}: ${
                    this.influence.marketCorrection
                }%`
            }
            if (
                this.total_influence !=
                +this.influence_sum +
                    +this.influence.marketCorrection +
                    +this.avm_influence
            ) {
                remarks += '\n'
                remarks += `   => ${this.$t('overridden_influence')}: ${
                    this.total_influence
                }%`
            } else {
                remarks += '\n'
                remarks += `   => ${this.$t('dvm_adjustment')}: ${this.total_influence}%`
            }

            this.updateRemarks(remarks)
        },
    },
}
</script>
<style lang="scss">
.is-grid {
    display: grid;
    grid-template-columns: 300px auto 75px;
    grid-template-rows: repeat(12, auto);
    gap: 2rem 0.5rem;
    align-items: center;

    .is-full-grid-width {
        grid-column: 1 / span 3;
    }

    .bottom-buttons {
        display: flex;
        flex-direction: row;
        grid-column: 1 / -1;
        justify-content: flex-end;
        gap: 1rem;
    }

    .radio-button-group {
        flex-wrap: wrap;

        & > * {
            .button {
                border-radius: 0 !important;
                border-left-width: 0px;
            }

            &:first-child {
                .button {
                    border-radius: 2px 0 0 2px !important;
                    border-left-width: 1px;
                }
            }

            &:last-child {
                .button {
                    border-radius: 0 2px 2px 0 !important;
                }
            }
        }
    }
}
</style>

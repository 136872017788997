var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"result-page"},[(_vm.getSimulationStatus === 'finished')?_c('div',{staticClass:"result-page__content _content"},[_c('h1',{staticClass:"result-page__title"},[_vm._v(" "+_vm._s(_vm.$t('energy.result.header.title'))+" ")]),_c('p',{staticClass:"result-page__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('energy.result.header.subtitle'))+" ")]),_c('div',{staticClass:"address"},[_c('div',{staticClass:"address__details"},[_vm._m(0),_c('div',{staticClass:"address__text"},[_c('div',{staticClass:"text__street"},[_vm._v(" "+_vm._s(_vm.getAddress.streetname)+" "+_vm._s(_vm.getAddress.streetnumber)+" ")]),_c('div',{staticClass:"text__city"},[_vm._v(" "+_vm._s(_vm.getAddress.postalcode)+" "+_vm._s(_vm.getAddress.municipality)+" ")])])]),(_vm.getIsOwner)?_c('Button',{staticClass:"edit-bttn",on:{"btn-click":_vm.openSummaryModal}},[_c('span',[_vm._v(_vm._s(_vm.$t('energy.result.button.edit_input')))])]):_vm._e()],1),_c('div',{staticClass:"_content__results results"},[_c('div',{staticClass:"results__epc"},[_c('div',{staticClass:"address"},[_c('div',{staticClass:"address__details"},[_vm._m(1),_c('div',{staticClass:"address__text"},[_c('div',{staticClass:"text__street"},[_vm._v(" "+_vm._s(_vm.getAddress.streetname)+" "+_vm._s(_vm.getAddress.streetnumber)+" ")]),_c('div',{staticClass:"text__city"},[_vm._v(" "+_vm._s(_vm.getAddress.postalcode)+" "+_vm._s(_vm.getAddress.municipality)+" ")])])])]),_c('epc-score',{attrs:{"region":_vm.getRegion,"label":_vm.getCurrentEpcLabel,"score":Math.round(_vm.getCurrentScenario.epc_ind),"percent":_vm.getEpcPercents}}),_c('consumption')],1),_c('div',{staticClass:"page_breaker"}),_c('package-selector'),_c('div',{staticClass:"results__renovations renovations",style:({
                    marginTop: _vm.getSelectedPackage !== 'budget' ? '3rem' : '0',
                })},[(_vm.getScenariosAvailable)?_c('renovation-grid'):_vm._e(),_c('div',{staticClass:"renovations__summary",class:{
                        'renovations__summary--no-renovations': !_vm.getScenariosAvailable,
                    }},[(_vm.getScenariosAvailable)?_c('Summary'):_vm._e(),(_vm.getScenariosAvailable)?_c('property-value'):_vm._e(),(_vm.getScenariosAvailable)?_c('Savings'):_vm._e(),_c('div',{staticClass:"_content__buttons"},[_c('Button',{staticClass:"download-bttn",attrs:{"href":_vm.report_url,"color":"rgba(0, 24, 55, 0.7)","bg-color":"white"}},[_c('svg-icon',{attrs:{"icon":"download","width":"1.25rem","height":"1.25rem","color":"rgba(0, 24, 55, 0.7)"}}),_vm._v(" "+_vm._s(_vm.$t('energy.result.button.download'))+" ")],1),(_vm.getIsOwner && _vm.getScenariosAvailable)?_c('Button',{staticClass:"save-bttn",attrs:{"color":_vm.saveNotificationActive
                                    ? 'var(--color-success)'
                                    : 'rgba(0, 24, 55, 0.7)',"bg-color":"white"},on:{"btn-click":_vm.saveResults}},[_c('svg-icon',{attrs:{"icon":_vm.saveNotificationActive ? 'check' : 'save',"width":"1.25rem","height":"1.25rem","color":_vm.saveNotificationActive
                                        ? 'var(--color-success)'
                                        : 'rgba(0, 24, 55, 0.7)'}}),_vm._v(" "+_vm._s(_vm.$t('energy.result.button.save'))+" ")],1):_vm._e(),(_vm.getIsOwner)?_c('Button',{staticClass:"share-bttn",attrs:{"href":_vm.mailto_link,"bg-color":"rgba(0, 24, 55, 0.7)","color":"rgba(0, 24, 55, 0.7)"},on:{"btn-click":_vm.track_share}},[_vm._v(" "+_vm._s(_vm.$t('energy.result.button.share'))+" "),_c('svg-icon',{attrs:{"icon":"share","width":"1.25rem","height":"1.25rem","color":"rgba(0, 24, 55, 0.7)"}})],1):_vm._e()],1)],1)],1)],1),_c('p',{staticClass:"result-page__footnote"},[_vm._v(_vm._s(_vm.$t('energy.result.footnote')))])]):_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('summary-modal',{ref:"summaryModal",attrs:{"unsubmit":true}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address__icon icon"},[_c('img',{staticClass:"icon__img",attrs:{"src":require("@/assets/icons/map-marker.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address__icon icon"},[_c('img',{staticClass:"icon__img",attrs:{"src":require("@/assets/icons/map-marker.svg"),"alt":""}})])
}]

export { render, staticRenderFns }
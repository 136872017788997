<template>
    <div class="py-2">
        <div v-if="items.length || !filterNull">
            <div
                class="section-header level has-text-weight-bold has-text-black has-text-left is-size-6"
            >
                <div class="level-left icon-text">
                    <img
                        v-if="name !== 'warnings' && icon"
                        class="icon is-medium mr-2"
                        :src="`${publicPath}${icon}.svg`"
                    />
                    <span
                        v-if="name === 'warnings'"
                        class="icon is-medium mr-2 has-text-warning"
                    >
                        <i class="mdi mdi-alert mdi-24px"></i>
                    </span>

                    <span>{{ $t('section.' + name) }}</span>
                </div>
            </div>
            <div v-if="orientation === 'vertical'">
                <div v-if="columns === 1">
                    <section-item
                        v-for="item in items"
                        :key="item.name"
                        :item="item"
                        :orientation="'horizontal'"
                    ></section-item>
                </div>
                <div v-if="columns === 2">
                    <div class="columns">
                        <div class="column is-6">
                            <section-item
                                v-for="item in items_half_1"
                                :key="item.name"
                                :item="item"
                                :orientation="'horizontal'"
                            ></section-item>
                        </div>
                        <div class="column is-6">
                            <section-item
                                v-for="item in items_half_2"
                                :key="item.name"
                                :item="item"
                                :orientation="'horizontal'"
                            ></section-item>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="section-grid">
                    <section-item
                        v-for="item in items"
                        :key="item.name"
                        class="section-grid-item"
                        :orientation="'vertical'"
                        :item="item"
                    ></section-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { config } from '@/shared/assets/config/config'

import SectionItem from '@/shared/components/SectionItem.vue'

import { mapGetters } from 'vuex'

export default {
    name: 'Section',
    components: {
        SectionItem,
    },
    props: {
        name: { type: String, default: 'general_info' },
        columns: { type: Number, default: 1 },
        orientation: { type: String, default: 'vertical' },
        filterBuildingType: { type: String, default: null },
        filterNull: { type: Boolean, default: true },
    },
    data() {
        return {
            publicPath: this.$config.FRONTEND_URL,
        }
    },
    computed: {
        ...mapGetters([
            'getFeatures',
            'getValuation',
            'getDVMFeatures',
            'getOVMFeatures',
        ]),
        items() {
            let filtered_config = config.filter((item) =>
                item.sections.includes(this.name)
            )
            if (this.filterBuildingType) {
                filtered_config = filtered_config.filter((item) =>
                    item.building_type_scope.includes(this.filterBuildingType)
                )
            }
            if (this.filterNull) {
                filtered_config = filtered_config.filter((item) => {
                    if (item.type === 'multi_enum_open') {
                        return (
                            this.getDVMFeatures[item.name]?.length ??
                            this.getOVMFeatures[item.name]?.length
                        )
                    }
                    if (item.location === 'features')
                        return (
                            typeof this.getFeatures[item.name] !== 'undefined' &&
                            this.getFeatures[item.name] !== null
                        )
                    if (item.location === 'dvm_features')
                        return (
                            typeof this.getDVMFeatures[item.name] !== 'undefined' &&
                            this.getDVMFeatures[item.name] !== null
                        )
                    if (item.location === 'ovm_features')
                        return (
                            typeof this.getOVMFeatures[item.name] !== 'undefined' &&
                            this.getOVMFeatures[item.name] !== null
                        )
                    if (item.location === 'valuation')
                        return (
                            typeof this.getValuation[item.name] !== 'undefined' &&
                            this.getValuation[item.name] !== null &&
                            this.getValuation[item.name] !== 0
                        )
                    return false
                })
            }
            return filtered_config
        },
        icon() {
            let section_item = config.filter(
                (item) => (item.location === 'section') & (item.name === this.name)
            )[0]
            if (section_item.icon) {
                return section_item.icon
            } else {
                return null
            }
        },
        items_half_1() {
            let items = this.items
            let half = Math.ceil(items.length / 2)
            return items.slice(0, half)
        },
        items_half_2() {
            let items = this.items
            let half = Math.floor(items.length / 2)
            if (items.length === 1) {
                return []
            } else {
                return items.slice(-half)
            }
        },
    },
}
</script>

<style lang="scss">
.section-header,
.level:not(:last-child) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
}
.section-header {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
}
.section-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-gap: 2px;
}
.section-grid-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>

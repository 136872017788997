var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"bubble",staticClass:"bubble",class:{ 'bubble--top': _vm.isTop },style:({
        left: _vm.left,
        top: _vm.top,
        position: _vm.position,
        'margin-left': _vm.marginLeft,
        'margin-right': _vm.marginRight,
    })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
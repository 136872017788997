<i18n lang="json">
{
    "en-BE": {
        "status": {
            "pending_confirmation": "Waiting for expert's confirmation",
            "confirmed": "The expert has confirmed the appointment"
        },
        "Find a time slot": "Find a time slot with our expert",
        "errors": {
            "no_valuers_in_region": "There are no valuers available in your region. Please contact us to find a solution."
        }
    }
}    
</i18n>

<template>
    <div class="container">
        <h2 class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold">
            {{ showUrl ? 'Make an appointment' : 'Appointment details' }}
        </h2>
        <div class="appointment-making">
            <b-loading :is-full-page="true" :active="loading" />
            <div
                v-for="(e, i) in errors"
                :key="i"
                class="error-message p-2 has-text-centered"
            >
                <p>{{ $t('errors.' + e) }}</p>
            </div>
            <template v-if="!showUrl">
                <div>Date: {{ appointmentStartTime.toLocaleString() }}</div>
                <div>Status: {{ $t('status.' + appointmentStatus) }}</div>
            </template>
            <nav-buttons :next-step="nextStep" :prev-step="prevStep">
                <b-button
                    v-if="!loading && showUrl"
                    type="is-primary"
                    class="mx-3"
                    :loading="loadingRts"
                    @click="getRtsUrl()"
                >
                    {{ $t('Find a time slot') }}
                </b-button>
            </nav-buttons>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import NavButtons from '../NavButtons.vue'

export default {
    name: 'AppointmentMaking',
    components: {
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
    },
    data: () => ({
        appointments: [],
        loading: true,
        loadingRts: false,
        errors: [],
    }),
    computed: {
        ...mapGetters('valuationStore', ['getRequestRef']),
        latestAppointment() {
            return this.appointments.length ? this.appointments[0] : null
        },
        appointmentStatus() {
            return this.latestAppointment?.status
        },
        showUrl() {
            return (
                !this.latestAppointment ||
                ['cancelled', 'rts_requested', 'new'].includes(this.appointmentStatus)
            )
        },
        appointmentStartTime() {
            return new Date(this.latestAppointment?.start_time)
        },
    },
    async mounted() {
        await this.getAppointments()
    },
    methods: {
        async getAppointments() {
            this.loading = true
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'request',
                this.getRequestRef,
                'event',
            ])

            try {
                const res = await this.$axios.get(url)
                this.appointments = res.data
            } finally {
                this.loading = false
            }
        },
        async getRtsUrl() {
            this.errors = []

            const now = Date.now()
            const inTwoDays = new Date(now + 2 * 24 * 60 * 60 * 1000)
            const inTwoWeeks = new Date(now + 2 * 7 * 24 * 60 * 60 * 1000)

            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'request',
                this.getRequestRef,
                'get_rts_uri',
            ])
            this.loadingRts = true

            try {
                const res = await this.$axios.get(url, {
                    params: {
                        start_time: inTwoDays.toISOString(),
                        end_time: inTwoWeeks.toISOString(),
                        origin_uri: window.location.href,
                    },
                })

                window.location.assign(res.data.rts_uri)
            } catch (e) {
                if (e.response && 'errors' in e.response.data) {
                    this.errors = e.response.data.errors
                }
            } finally {
                this.loadingRts = false
            }
        },
    },
}
</script>

<style lang="scss">
.appointment-making {
    max-width: 480px;
    margin: auto;
}
.button-flex {
    display: flex;
    justify-content: space-between;
}
.error-message {
    background-color: #fdd;
    border: 1px solid #f00;
    border-radius: 4px;
    margin: 0.5rem;
}
</style>

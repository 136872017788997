<template>
    <b-modal v-model="isOpen" class="ers-modal">
        <div class="ers-tooltip-modal">
            <div class="ers-tooltip-modal-title">
                <div>
                    <h1>{{ $t('energy.summary.title') }}</h1>
                    <p v-if="getValidations.errors.length === 0">
                        {{ $t('energy.summary.subtitle') }}
                    </p>
                    <div v-else class="error-message has-text-danger">
                        <h3 v-if="getValidations.notice.constructionYear">
                            {{ $t('energy.summary.construction_year_error') }}
                        </h3>
                        <h3 v-if="getValidations.notice.fuel">
                            {{ $t('energy.summary.fuel_error') }}
                        </h3>
                        <h3 v-if="getValidations.notice.epc">
                            {{ $t('energy.summary.answers_needs_to_be_fixed') }}
                        </h3>
                        <ul style="padding: 1rem 2rem;">
                            <li
                                v-for="error in getValidations.errors"
                                :key="error"
                                class="has-text-danger"
                                style="list-style-type: circle;"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                </div>
                <img
                    src="@/assets/icons/bttn-close-black.svg"
                    class="ers-modal-close-button"
                    alt=""
                    @click="isOpen = false"
                />
            </div>
            <div class="address">
                <div class="address__icon icon">
                    <img src="@/assets/icons/map-marker.svg" alt="" class="icon__img" />
                </div>
                <div class="address__info">
                    <div class="address__text">
                        <div class="text__street">
                            {{ getAddress.streetname }} {{ getAddress.streetnumber }}
                        </div>
                        <div class="text__city">
                            {{ getAddress.postalcode }} {{ getAddress.municipality }}
                        </div>
                    </div>
                </div>
            </div>
            <h1 class="ers-modal-cards-title">{{ $t('steps.insulation') }}</h1>
            <div class="ers-tooltip-cards">
                <div
                    v-for="card in getEnergyCards"
                    :key="card.title"
                    class="summary-card"
                    :class="{
                        'summary-card--error': getValidations.errors.includes(card.title),
                    }"
                >
                    <div class="summary-card-text">
                        <h1>
                            {{ card.title }}
                        </h1>
                        <p>
                            {{ card.answer() }}
                        </p>
                    </div>
                    <div
                        class="summary-card-icon-container"
                        @click="goTo(card.routeName)"
                    >
                        <img
                            src="@/assets/icons/ic-edit-grey.svg"
                            class="summary-card-edit-bttn"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <h1 class="ers-modal-cards-title">{{ $t('steps.consumption') }}</h1>
            <div class="ers-tooltip-cards">
                <div
                    v-for="card in getConsumptionsCards"
                    :key="card.title"
                    class="summary-card"
                    :class="{
                        'summary-card--error': getValidations.errors.includes(card.title),
                    }"
                >
                    <div class="summary-card-text">
                        <h1>
                            {{ card.title }}
                        </h1>
                        <p>
                            {{ card.answer() }}
                        </p>
                    </div>
                    <div
                        class="summary-card-icon-container"
                        @click="goTo(card.routeName)"
                    >
                        <img
                            src="@/assets/icons/ic-edit-grey.svg"
                            class="summary-card-edit-bttn"
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <div
                v-if="
                    !($route.name === 'ers.result') && getValidations.errors.length === 0
                "
                class="ers-modal-footer"
            >
                <div class="ers-modal-submit-bttn" @click="goToProcessing">
                    <span>{{ $t('ers_report.submit_button_budget') }}</span>
                    <img src="@/assets/icons/ic-arrow-right.svg" alt="" srcset="" />
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
    name: 'SummaryModal',
    props: {
        unsubmit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            isSubmitButton: true,
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getWallInsulation',
            'getRoofInsulation',
            'getFloorInsulation',
            'getHeatingFuel',
            'getAddress',
            'getHeatingFuel',
            'getRequestRef',
        ]),
        getIsFuelElectricity() {
            return this.getHeatingFuel === 'electricity'
        },
        getValidations() {
            const {
                f_construction_year,
                floor_attributes,
                wall_attributes,
                roof_attributes,
                heating_attributes,
                window_type,
                estimated_consumptions,
                known_consumptions,
                has_epc_certificate,
                energy_consumption_known,
                is_epc_number,
                is_epc_label,
            } = this.getFeatures
            let errors = []
            let notice = {}
            if (
                wall_attributes[0].renovation_year &&
                f_construction_year > wall_attributes[0].renovation_year
            ) {
                errors.push(this.$t('steps.walls'))
                notice = {
                    ...notice,
                    constructionYear: true,
                }
            }
            if (
                roof_attributes[0].renovation_year &&
                f_construction_year > roof_attributes[0].renovation_year
            ) {
                errors.push(this.$t('steps.roof'))
                notice = {
                    ...notice,
                    constructionYear: true,
                }
            }
            if (
                floor_attributes[0].renovation_year &&
                f_construction_year > floor_attributes[0].renovation_year
            ) {
                errors.push(this.$t('steps.floor'))
                notice = {
                    ...notice,
                    constructionYear: true,
                }
            }
            if (
                heating_attributes[0].installation_year &&
                f_construction_year > heating_attributes[0].installation_year
            ) {
                errors.push(this.$t('steps.heating'))
                notice = {
                    ...notice,
                    constructionYear: true,
                }
            }
            if (!known_consumptions.length && !estimated_consumptions.length) {
                if (!this.getIsFuelElectricity) {
                    errors.push(this.$t('energy.summary.heating_consumption'))
                }
                errors.push(this.$t('energy.summary.electricity_consumption'))
                notice = {
                    ...notice,
                    fuel: true,
                }
            }

            if (!energy_consumption_known) {
                estimated_consumptions.map((cons) => {
                    if (!cons.estimate) {
                        if (!this.getIsFuelElectricity) {
                            errors.push(this.$t('energy.summary.heating_consumption'))
                        }
                        errors.push(this.$t('energy.summary.electricity_consumption'))
                        notice = {
                            ...notice,
                            fuel: true,
                        }
                    }
                })
            } else {
                known_consumptions.map((cons) => {
                    if (cons.amount === null) {
                        if (!this.getIsFuelElectricity) {
                            errors.push(this.$t('energy.summary.heating_consumption'))
                        }
                        errors.push(this.$t('energy.summary.electricity_consumption'))
                        notice = {
                            ...notice,
                            fuel: true,
                        }
                    }
                })
            }

            if (has_epc_certificate && !is_epc_label && !is_epc_number) {
                errors.push(this.$t('energy.summary.epc'))
                notice = {
                    ...notice,
                    epc: true,
                }
            }

            return {
                errors: [...new Set(errors)],
                notice,
            }
        },
        getConsumptionsCards() {
            const {
                known_consumptions,
                estimated_consumptions,
                energy_consumption_known,
            } = this.getFeatures
            if (energy_consumption_known === null) return
            const elecCard = () => {
                let answer
                if (energy_consumption_known && known_consumptions.length) {
                    const { amount, unit } = known_consumptions.find((cons) =>
                        ['electricity'].includes(cons.consumption_fuel)
                    )
                    if (answer !== null) {
                        answer = `${amount} ${unit}`
                    }
                } else if (!energy_consumption_known && estimated_consumptions.length) {
                    answer = estimated_consumptions.find((cons) =>
                        ['electricity'].includes(cons.consumption_fuel)
                    ).estimate
                    if (answer !== null) {
                        answer = this.$t(`energy.summary.${answer}`)
                    }
                }
                return {
                    title: this.$t('energy.summary.electricity_consumption'),
                    answer: () => answer,
                    routeName: 'ers.consumers',
                }
            }

            const heatingCard = () => {
                if (this.getIsFuelElectricity) {
                    return null
                }
                let answer
                if (energy_consumption_known === false) {
                    if (!estimated_consumptions.length) {
                        answer = null
                    } else {
                        answer = estimated_consumptions.find(
                            (cons) => cons.consumption_fuel === this.getHeatingFuel
                        ).estimate
                        if (answer !== null) {
                            answer = this.$t(`energy.summary.${answer}`)
                        }
                    }
                } else {
                    if (known_consumptions.length) {
                        const { amount, unit } = known_consumptions.find(
                            (cons) => cons.consumption_fuel === this.getHeatingFuel
                        )
                        if (answer !== null) {
                            answer = `${amount} ${unit}`
                        }
                    }
                }
                return {
                    title: this.$t('energy.summary.heating_consumption'),
                    answer: () => answer,
                    routeName: 'ers.consumers',
                }
            }

            const answers = [
                {
                    title: this.$t('energy.summary.energy_consumption_known'),
                    answer: () => {
                        return `${
                            this.getFeatures.energy_consumption_known
                                ? this.$t('common.yes')
                                : this.$t('common.no')
                        }`
                    },
                    routeName: 'ers.energy-consumption',
                },
                {
                    title: this.$t('energy.summary.residents'),
                    answer: () => {
                        return `${this.getFeatures.n_residents}`
                    },
                    routeName: 'ers.consumers',
                },
                heatingCard(),
                elecCard(),
                {
                    title: this.$t('energy.summary.big_consumer'),
                    answer: () =>
                        this.getFeatures.has_big_energy_consumers
                            ? this.$t('common.yes')
                            : this.$t('common.no'),
                    routeName: 'ers.big-consumers',
                },
            ]
            return answers.filter((card) => card !== null)
        },
        getEnergyCards() {
            const buildingDimensionCard = () => {
                const {
                    f_neighbour_type,
                    f_top_floor,
                    f_percent_of_roof_flat,
                } = this.getFeatures
                const houseType =
                    f_neighbour_type === 'isolated'
                        ? this.$t('energy.building_dimensions.facade_type_2')
                        : f_neighbour_type === 'row_end'
                        ? this.$t('energy.building_dimensions.facade_type_1')
                        : this.$t('energy.building_dimensions.facade_type_0')
                if (+this.getFeatures.effective_level < 2) {
                    return {
                        title: this.$t('steps.building_dimensions'),
                        answer: () =>
                            `${this.$t(
                                'energy.building_dimensions.floors.label'
                            )} ${f_top_floor}, ${parseInt(
                                f_percent_of_roof_flat * 100
                            )}% ${this.$t(
                                'building_dimensions_page.is_flat'
                            )}, ${houseType}`,
                        routeName: 'ers.building-dimensions',
                    }
                } else if (+this.getFeatures.effective_level < 3) {
                    return {
                        title: this.$t('steps.building_dimensions'),
                        answer: () =>
                            `${this.$t(
                                'energy.building_dimensions.floors.label'
                            )} ${f_top_floor}, ${parseInt(
                                f_percent_of_roof_flat * 100
                            )}% ${this.$t('building_dimensions_page.is_flat')}`,
                        routeName: 'ers.building-dimensions',
                    }
                } else {
                    return null
                }
            }
            const answers = [
                {
                    title: this.$t('steps.attic'),
                    answer: () => {
                        if (!this.getFeatures.has_attic) {
                            return this.$t('common.no')
                        } else if (this.getFeatures.is_attic_heated) {
                            return this.$t('energy.summary.heated')
                        } else {
                            return this.$t('energy.summary.not_heated')
                        }
                    },
                    routeName: 'ers.attic',
                },
                {
                    title: this.$t('steps.basement'),
                    answer: () =>
                        this.getFeatures.has_basement
                            ? this.$t('common.yes')
                            : this.$t('common.no'),
                    routeName: 'ers.basement',
                },
                buildingDimensionCard(),
                {
                    title: this.$t('steps.living_area'),
                    answer: () => this.getFeatures.f_living_area + this.$t('unit.m2'),
                    routeName: 'ers.living-area',
                },
                {
                    title: this.$t('energy.summary.epc'),
                    answer: () => {
                        const {
                            has_epc_certificate,
                            is_epc_label,
                            is_epc_number,
                            f_epc_label,
                            f_epc_numeric,
                        } = this.getFeatures
                        if (!has_epc_certificate) {
                            return this.$t('common.no')
                        } else {
                            if (is_epc_number) {
                                return f_epc_numeric
                            } else {
                                return f_epc_label
                            }
                        }
                    },
                    routeName: !this.getFeatures.has_epc_certificate
                        ? 'ers.epc'
                        : 'ers.epc-score',
                },
                {
                    title: this.$t('steps.f_construction_year'),
                    answer: () => this.getFeatures.f_construction_year,
                    routeName: 'ers.construction-year',
                },
                {
                    title: this.$t('steps.walls'),
                    answer: () => {
                        const answer = this.getWallInsulation[0]
                        if (answer.has_insulation === 'idk')
                            return this.$t('common.i_dont_know')
                        else if (answer.has_insulation) {
                            return `${this.$t('energy.summary.insulation_year')}: ${
                                answer.renovation_year
                            }`
                        } else {
                            return this.$t('common.no')
                        }
                    },
                    routeName: 'ers.wall-insulation',
                },
                {
                    title: this.$t('steps.roof'),
                    answer: () => {
                        const answer = this.getRoofInsulation[0]
                        if (answer.has_insulation === 'idk')
                            return this.$t('common.i_dont_know')
                        else if (answer.has_insulation) {
                            return `${this.$t('energy.summary.insulation_year')}: ${
                                answer.renovation_year
                            }`
                        } else {
                            return this.$t('common.no')
                        }
                    },
                    routeName: 'ers.roof-insulation',
                },
                {
                    title: this.$t('steps.floor'),
                    answer: () => {
                        const answer = this.getFloorInsulation[0]
                        if (answer.has_insulation === 'idk')
                            return this.$t('common.i_dont_know')
                        else if (answer.has_insulation) {
                            return `${this.$t('energy.summary.insulation_year')}: ${
                                answer.renovation_year
                            }`
                        } else {
                            return this.$t('common.no')
                        }
                    },
                    routeName: 'ers.floor-insulation',
                },
                {
                    title: this.$t('steps.windows'),
                    answer: () => this.$t(`value.${this.getFeatures.window_type}`),
                    routeName: 'ers.window-glazing',
                },
                {
                    title: this.$t('steps.heating'),
                    answer: () => {
                        const isSubType =
                            this.getFeatures.heating_attributes[0].subtype !== null
                        const fuel = this.getHeatingFuel
                            ? this.$t(`value.${this.getHeatingFuel}`)
                            : this.$t(`value.${'electricity'}`)
                        const type = this.$t(
                            `value.${
                                this.getFeatures.heating_attributes[0].heating_type
                            }${
                                isSubType
                                    ? ':' + this.getFeatures.heating_attributes[0].subtype
                                    : ''
                            }`
                        )
                        const installationYear = this.getFeatures.heating_attributes[0]
                            .installation_year

                        if (fuel === type) {
                            return `${fuel}: ${installationYear}`
                        }

                        return `${fuel} ${type ? '- ' + type : ''}: ${installationYear}`
                    },
                    routeName: 'ers.heating',
                },
                {
                    title: this.$t('steps.solar_panels'),
                    answer: () =>
                        this.getFeatures.photovoltaic_available
                            ? this.$t('common.yes')
                            : this.$t('common.no'),
                    routeName: 'ers.solar-panels',
                },
            ]
            return answers.filter((card) => card !== null)
        },
    },

    methods: {
        ...mapActions('ersStore', ['unsubmit_request', 'track_event']),

        open() {
            this.isOpen = true
        },

        goTo(name) {
            if (this.unsubmit) {
                this.unsubmit_request(name)
                if (this.$config.TRACK) {
                    this.track_event({ event: 'edit_questionnaire', route: this.$route })
                }
            }
            this.$router.push({
                name: name,
                params: {
                    lang: this.$route.params.lang,
                },
                query: {
                    ref: this.getRequestRef,
                },
            })
            this.isOpen = false
        },
        goToProcessing() {
            this.$store.dispatch('ersStore/submitRequest', this.$i18n.locale)
            this.isOpen = false
            if (this.$route.name === 'ers.processing') {
                this.$router.go()
            }

            this.$router.push({
                name: 'ers.processing',
                params: {
                    lang: this.$route.params.lang,
                },
                query: {
                    ...this.$route.query,
                    ref: this.getRequestRef,
                },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.error-message {
    padding-block-start: 1rem;
}

.ers-tooltip-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    min-width: 100%;
}

.ers-modal-cards-title {
    color: #001837;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.summary-card {
    display: flex;
    padding: 0.75rem;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 0.25rem;
    border: 1px solid #d6e2e9;
    gap: 6rem;
    &--error {
        border-color: #f14668 !important;

        .summary-card-text {
            p {
                color: #f14668 !important;
            }
        }
    }
    &-edit-bttn {
        width: 1rem;
        height: 1rem;
        margin: auto;
    }
    &-icon-container {
        display: flex;
        width: 2rem;
        height: 2rem;
        border-radius: 2.5rem;
        background: rgba(121, 141, 166, 0.15);
        cursor: pointer;
    }
    &-text {
        h1 {
            color: #001837;
            font-family: Satoshi;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            padding-block-start: 0.25rem;
            color: #001837;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}

.address {
    display: flex;
    gap: 1rem;
}

.address__icon {
    margin-block: auto;
    width: 2.5rem !important;
    height: 2.5rem !important;
}

.address__text {
    color: #001837;
    font-family: Satoshi;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.address__details {
    display: flex;
    gap: 50px;
}
</style>

<style lang="scss">
.ers-modal {
    .modal-background {
        background: rgba(0, 24, 55, 0.4);
    }

    .modal-content {
        button {
            display: none;
        }
        max-width: fit-content !important;
    }
}

.ers-tooltip-modal-container {
    display: inline;
    max-width: fit-content;
}

.ers-tooltip-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.ers-tooltip-modal {
    display: flex;
    max-width: 43.75rem;
    padding: 2rem 2rem 2.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    border-radius: 0.3125rem;
    background: #fff;
    margin: auto;
    position: relative;
}

.ers-tooltip-modal-title {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        h1 {
            color: #001837;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            color: #798da6;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 1.3125rem */
            max-width: 28rem;
            margin-block-start: 0.5rem;
        }
    }
}

.ers-modal-close-button {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.ers-modal-footer {
    min-width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.ers-modal-submit-bttn {
    cursor: pointer;
    margin-inline-start: auto;
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.3125rem;
    background: #001837;
    color: #fff;
}
</style>

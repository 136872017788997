<template>
    <div class="column is-6 m-3 map-container p-0">
        <div v-if="isLoading" class="column map-loading__content">
            <div class="map-loading__icon">
                <img src="@/assets/icons/ic-eye.svg" alt="" srcset="" />
            </div>
            <p class="map-loading__text">
                {{
                    !getAddress.streetname
                        ? $t('energy.address.map.placeholder')
                        : $t('energy.address.is_loading')
                }}
            </p>
        </div>
        <div v-else class="address-map">
            <div class="header">
                <div v-if="!selectAction" class="header__title">
                    {{
                        +getAddress.effective_level > 2
                            ? $t('energy.address.map.confirm_3d')
                            : $t('energy.address.map.confirm_2d')
                    }}
                </div>
                <div v-else class="header__title">
                    {{ $t('energy.address.map.select_building') }}
                </div>
                <div class="header__subtitle">
                    {{ address }}
                </div>
            </div>
            <div v-if="getMapScene3d.map_data_ready && +getAddress.effective_level > 2">
                <renderer
                    class="ers-map-renderer"
                    :building-id="getMapScene3d.building_id"
                    :solar-potential="false"
                    :map-scene3d="getMapScene3d"
                    :show-attributions="false"
                    @update:mapScene3d="SET_MAP_SCENE3D($event)"
                />
            </div>
            <div v-else>
                <re-map
                    class="ers-map-renderer"
                    :building-id="getAddress.building_id"
                    :parcel-ids="getMapScene2d.parcel_ids"
                    :level="getAddress.effective_level"
                    :center="{
                        lat: getMapScene2d.center.lat,
                        lng: getMapScene2d.center.lng,
                    }"
                    :map-scene2d="getMapScene2d"
                    :flood-visible="false"
                    :read-only="!selectAction"
                    size="fit"
                    :layers-control-visible="false"
                    @update:buildingId="update_buildingid_and_address"
                ></re-map>
            </div>
            <div v-if="!selectAction" class="map-container-card">
                <div class="map-container-card__buttons">
                    <b-button
                        class="address-map-button-no"
                        size="is-medium"
                        type="is-primary"
                        @click="handleAnswer(false)"
                    >
                        <span>
                            {{ $t('common.no') }}
                        </span>
                    </b-button>

                    <b-button
                        class="address-map-button-yes"
                        size="is-medium"
                        type="is-primary"
                        @click="handleAnswer(true)"
                    >
                        <span>
                            {{ $t('common.yes') }}
                        </span>
                    </b-button>
                </div>
            </div>
            <div v-else class="map-container-card">
                <div class="map-container-card__buttons">
                    <b-button
                        class="address-map-button-no address-map-button-cant-find"
                        size="is-medium"
                        type="is-primary"
                        @click="handleBuildingNotFound()"
                    >
                        <span>
                            {{ $t('energy.address.map.cant_find') }}
                        </span>
                    </b-button>

                    <b-button
                        class="address-map-button-yes"
                        size="is-medium"
                        type="is-primary"
                        @click="handleAnswer(true)"
                    >
                        <span>
                            {{ $t('global.action.confirm') }}
                        </span>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReMap from '@/components/common/Map.vue'
import utils from '@/shared/plugins/utils'
import Renderer from '@/apps/ERS/components/3DComponents/Renderer3D.vue'

import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    name: 'AddressMap',
    components: { Renderer, ReMap },
    props: {
        address: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isAddressesModalActive: false,
            buildingAddresses: [],
            isLoading: true,
            height: 100,
            map_zoom: 18,
            active: true,
            selectAction: false,
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getAddress',
            'getFeatures',
            'getMapScene2d',
            'getMapScene3d',
            'getRequestRef',
            'getBuildingType',
            'getEffectiveLevel',
        ]),
        // get map  init. zoom level with condiont
        noBuildingMatch() {
            return this.getFeatures.level < '2'
        },
        map_data_ready() {
            return this.getMapScene2d.map_data_ready
        },
        map3d_data_ready() {
            return this.getMapScene3d.map_data_ready
        },
        next_possible() {
            switch (this.$route.params.action) {
                case 'select-building':
                    return (
                        this.map_data_ready &&
                        this.$store.state.ersStore.features.building_id !== null &&
                        !this.$store.state.loading
                    )
                case 'confirm-building':
                    return (
                        (this.map_data_ready && !this.$store.state.loading) ||
                        !this.isPredraft
                    )
                default:
                    return true
            }
        },
        tooltip_label() {
            return this.$t('tooltip-for-' + this.$route.params.action)
        },
        tile_url() {
            return `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=${this.$config.MAPBOX_TOKEN}`
        },
    },
    watch: {
        building_id() {
            this.update_map()
        },
        map_data_ready(newVal) {
            this.isLoading = !newVal
        },
        map3d_data_ready(newVal) {
            this.isLoading = !newVal
        },
        getEffectiveLevel(newVal) {
            if (+newVal >= 2) {
                this.selectAction = false
            }
        },
    },
    mounted() {},
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS']),
        ...mapMutations('ersStore', [
            'TOGGLE_SECONDARY_BUILDING_ID',
            'SELECT_MAIN_BUILDING_ID',
            'TOGGLE_PARCEL',
            'SET_PARCEL_IDS',
            'SET_FEATURES',
            'SET_MAP_SCENE2D',
            'SET_MAP_SCENE3D',
            'SET_ADDRESS_TEXT',
            'SET_EFFECTIVE_LEVEL',
        ]),
        ...mapActions('ersStore', [
            'fetch_map_data',
            'auto_select_parcels',
            'fetch_map_scene3d',
            'track_event',
        ]),
        handleAnswer(choice) {
            if (choice) return this.$emit('next-page')

            const currentLevel = +this.getAddress.effective_level

            if (currentLevel > 2) {
                this.isLoading = true

                const newLevel = +currentLevel - 1
                this.SET_EFFECTIVE_LEVEL(newLevel)
                this.$emit('reset-map', newLevel)
            } else {
                this.selectAction = true
            }
            if (this.$config.TRACK) {
                this.track_event({
                    route: this.$route,
                    event: 'not_my_building',
                    data: {
                        current_level: currentLevel,
                    },
                })
            }
        },
        handleBuildingNotFound() {
            if (this.$config.TRACK) {
                this.track_event({
                    route: this.$route,
                    event: 'cant_find_building',
                })
            }
            this.$emit('building-not-found')
        },
        main(building_id) {
            return (
                this.$store.state.ersStore.features.building_ids.length === 1 &&
                this.$store.state.ersStore.features.building_ids[0] === building_id
            )
        },
        secondary(building_id) {
            return (
                this.$store.state.ersStore.features.secondary_building_ids.indexOf(
                    building_id
                ) != -1
            )
        },
        selected_parcel(parcel_id) {
            return this.$store.state.ersStore.features.parcel_ids.indexOf(parcel_id) != -1
        },
        unhappyFlow() {
            switch (this.$route.params.action) {
                case 'confirm-building':
                    this.$router.push({
                        name: 'ers.map',
                        params: {
                            lang: this.$route.params.lang,
                            action: 'select-building',
                        },
                        query: this.$route.query,
                    })
                    break
                case 'select-building':
                    this.SET_FEATURES({
                        buildingnotfound: true,
                    })
                    this.$router.push({
                        name: 'ers.out-of-bounds',
                        params: {
                            lang: this.$route.params.lang,
                        },
                        query: this.$route.query,
                    })
                    break
            }
        },
        update_map() {
            this.fetch_map_data().then(() => {
                if (this.isPredraft) {
                    this.auto_select_parcels()
                }
                if (typeof this.$refs.parcel_features !== 'undefined') {
                    this.$refs.parcel_features.mapObject.eachLayer((layer) => {
                        this.$refs.parcel_features.mapObject.resetStyle(layer)
                    })
                }
            })
        },
        update_buildingid_and_address(id) {
            this.isLoading = true
            // FIND address(es) linked to the selected building
            var newBuildingIds = this.getMapScene2d.address_geojson.features.filter(
                (el) =>
                    el.properties.building_id === id && !el.properties.duplicate_location
            )
            if (newBuildingIds.length === 1) {
                // 1 address for the building
                // => UPDATE ADDRESS AND BUILDING_ID
                this.SELECT_MAIN_BUILDING_ID(id)
                this.SET_ADDRESS_TEXT(newBuildingIds[0].properties)
                this.$emit('update-address')
            } else if (newBuildingIds.length > 1) {
                // ++ addresses for the building
                // SHOW "Multiple Addresses for this building" modal + ask to select one
                this.buildingAddresses = newBuildingIds
                this.isAddressesModalActive = true
            } else {
                this.SELECT_MAIN_BUILDING_ID(id)
                // 0 address for that building
                // => SHOW "No address" + ask to fill address form again
                // (Pre-filled with data they entered on the previous screen? Possible to get that info back
                // even if we clicked on another building before this one?)
                // If not : pre-filled with the current selected address
            }
        },
        setAddress(address) {
            this.SELECT_MAIN_BUILDING_ID(address.properties.building_id)
            this.SET_ADDRESS_TEXT(address.properties)
            this.buildingAddresses = []
            this.isAddressesModalActive = false
        },
        cancelMultipleAddresses(address) {
            this.buildingAddresses = []
            this.isAddressesModalActive = false
        },
    },
}
</script>

<style lang="scss">
.ers-map-renderer {
    min-height: 20rem;
    canvas {
        max-width: 100% !important;
        max-height: 100% !important;
    }
}

.address-map-button {
    &-yes {
        background: #001837 !important;
    }
    &-no {
        background: #ffffff !important;
        color: rgba(0, 24, 55, 0.7) !important;
        border: 1px solid #d6e2e9 !important;
    }

    &-cant-find {
        margin-inline: auto 0.75rem !important;
    }
}
</style>

<style lang="scss" scoped>
.map-container {
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    background: #fff;
    display: flex;
    min-height: 26.125rem;
}

.address-map {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.map-loading {
    &__content {
        display: grid;
        place-items: center;
        gap: 1.5rem;
        margin-block: auto;
    }
    &__text {
        max-width: 230px;
        text-align: center;
    }
    &__icon {
        display: flex;
        width: 4rem;
        height: 4rem;
        padding: 0.625rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;

        border-radius: 3.75rem;
        background: rgba(0, 156, 255, 0.2);
    }
}

.header {
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
    border-bottom: 1px solid #d6e2e9;
    &__title {
        color: #001837;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    &__subtitle {
        color: rgba(0, 24, 55, 0.5);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.map-container-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 1.5rem;
    &__text {
        color: #001837;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    &__buttons {
        min-width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
</style>

<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        class="ers-content section results-page is-fluid pt-0 pb-0 mb-0 mt-0"
        :class="[$vssWidth < 768 && 'pt-0']"
    >
        <div class="sticky-column">
            <div class="column is-12 mt-0">
                <h1 v-if="simulation.status == 'started'" class="title">
                    {{ $t('processing_page.title_started') }}
                </h1>
                <h1 v-if="simulation.status == 'finished'" class="title">
                    {{ $t('processing_page.title_finished') }}
                </h1>
                <h1
                    v-if="['failed', 'expired'].includes(simulation.status)"
                    class="title"
                >
                    {{ $t('processing_page.title_failed') }}
                </h1>
                <h1
                    v-if="simulation.status == 'pending'"
                    class="pending-title title"
                    v-html="
                        $t(`processing_page.title_pending_${pending_range}`, {
                            waiting: pending_time,
                        })
                    "
                />
            </div>
            <div class="buttons-wrapper">
                <b-progress
                    v-if="simulation.status === 'started'"
                    class="large"
                    type="is-primary"
                    :value="progress_value"
                ></b-progress>
                <b-button
                    v-if="simulation.status === 'finished'"
                    tag="router-link"
                    :to="{
                        name: 'ers.result',
                        params: { lang: $route.params.lang },
                        query: {
                            ...this.$route.query,
                        },
                    }"
                    type="is-primary"
                    :value="progress_value"
                >
                    <span>{{ $t('processing_page.check_results') }}</span>
                </b-button>
                <b-button
                    v-if="['failed', 'expired'].includes(simulation.status)"
                    tag="router-link"
                    :to="{ name: 'ers' }"
                    type="is-primary"
                    :value="progress_value"
                >
                    <span>{{ $t('dashboard.new_request.title') }}</span>
                </b-button>
                <b-button
                    v-if="['failed', 'expired'].includes(simulation.status)"
                    type="is-primary"
                    @click="
                        $emit('open-summary-modal', {
                            unsubmit: true,
                        })
                    "
                >
                    <span>{{ $t('energy.result.button.edit_input') }}</span>
                </b-button>
            </div>
            <img
                v-if="['started', 'pending'].includes(simulation.status)"
                src="@/assets/img/ers/house_scanning.gif"
                class="house-img"
            />
            <img
                v-else-if="simulation.status == 'finished'"
                src="@/assets/img/ers/house_basic.gif"
                class="house-img"
            />
            <img
                v-else-if="['failed', 'expired'].includes(simulation.status)"
                src="@/assets/img/ers/house_reconstruction.gif"
                class="house-img"
            />
            <img v-else src="@/assets/img/ers/house_basic.gif" class="house-img" />
        </div>
    </div>
</template>
<script>
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import Renderer from '@/shared/components/3DComponents/Renderer3D.vue'
import { config } from '@/shared/assets/config/config'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Submitted',
    data: () => ({
        solar_question: null,
        now: Date.now(),
        shown_questions_indices: [0],
        current_question_index: 0,
        questions: [],
        notification_ref: null,
        simulation: {
            started_at: null,
            finished_at: null,
            status: 'not_started', // "started", "finished", "failed", "pending"
            wait_time: 0,
        },
    }),
    computed: {
        ...mapGetters('ersStore', ['getMapScene3d', 'getFeatures']),
        showLoaderManipulation() {
            return false
            return this.$config.SHOW_LOADER_MANIPULATION
        },
        progress_value: function() {
            let progress = (this.now - this.simulation.started_at) / 1000
            return progress <= 100 ? progress : undefined
        },
        quizz_score: function() {
            return this.questions
                .map((question) => question.correct === question.selected)
                .reduce((a, b) => a + b, 0)
        },
        result_page_baseURL() {
            return this.$config.ERS_REPORT_BASE_URL
        },
        quizz_phase: function() {
            let ratio = this.quizz_score / this.questions.length
            if (ratio <= 0.34) {
                return 'discovery'
            } else if (ratio <= 0.67) {
                return 'learning'
            } else {
                return 'expert'
            }
        },
        solarAnswers() {
            let answers = Object.keys(
                this.$t(
                    `energy_quizz.solar.q${
                        this.getFeatures.photovoltaic_available ? 'b' : 'a'
                    }`
                )
            )
                .filter((key) => key.startsWith('a') && !key.endsWith('_tooltip'))
                .map((key) =>
                    this.$t(
                        `energy_quizz.solar.q${
                            this.getFeatures.photovoltaic_available ? 'b' : 'a'
                        }.${key}`
                    )
                )

            return answers
        },
        ing_redirect_link() {
            return `/api/valuation/go/epc-simulator/${this.$route.params.lang}`
        },
        pending_time() {
            if (this.simulation.wait_time > 120)
                return Math.round(this.simulation.wait_time / 60)
            return Math.round(this.simulation.wait_time)
        },
        pending_range() {
            if (this.simulation.wait_time > 120) return 'long'
            if (this.simulation.wait_time > 15) return 'medium'
            return 'short'
        },
        retryCodes() {
            return [599, 429, 504, 401]
        },
    },
    watch: {
        notification_ref(val) {
            if (val) {
                this.check_queue()
            }
        },
    },
    mounted() {
        var self = this
        setInterval(function() {
            self.now = Date.now()
        }, 100)

        setTimeout(this.check_simulation, 1500)
    },
    methods: {
        ...mapMutations('ersStore', ['SET_SIMULATION', 'SET_MAP_SCENE3D']),
        getSolarClass(index) {
            if (this.solar_question !== null && this.solar_question === index)
                return 'is-primary is-light'
            return 'is-primary'
        },
        getClass(a_index, index, __) {
            let question = this.questions[index]
            if (question.selected === null) {
                return 'is-primary'
            } else if (
                a_index === question.selected &&
                question.selected === question.correct
            ) {
                return 'is-success'
            } else if (a_index === question.correct) {
                return 'is-success is-light'
            } else if (a_index === question.selected) {
                return 'is-danger'
            } else {
                return 'is-light'
            }
        },
        select_solar_answer(index) {
            this.solar_question = index
            setTimeout(() => {
                const scroller =
                    this.$vssWidth > 768 ? document.querySelector('.quizz') : window
                scroller.scrollTo(0, document.querySelector('.quizz').scrollHeight)
            }, 50)
        },
        select_answer(index, a_index) {
            this.questions[index].selected = a_index
            if (index === this.current_question_index) {
                this.current_question_index += 1
                if (this.current_question_index < this.questions.length) {
                    this.shown_questions_indices.push(this.current_question_index)
                }
            }
            setTimeout(() => {
                const scroller =
                    this.$vssWidth > 768 ? document.querySelector('.quizz') : window
                scroller.scrollTo(0, document.querySelector('.quizz').scrollHeight)
            }, 50)
        },
        reset() {
            this.questions.forEach((question) => {
                question.selected = null
            })
            this.current_question_index = 0
            this.shown_questions_indices = [0]
        },
        expand() {
            this.questions.forEach((question) => {
                question.selected = 0
            })
            this.current_question_index = 5
            this.shown_questions_indices = [0, 1, 2, 3, 4, 5]
        },
        fake_fail() {
            this.process_notification([
                {
                    callback_sent_at: new Date(),
                    callback_response_at: new Date(),
                    callback_response_status_code: 500,
                },
            ])
        },
        fake_start() {
            this.process_notification([
                {
                    callback_sent_at: new Date(),
                    callback_response_at: null,
                    callback_response_status_code: null,
                },
            ])
        },
        fake_finish() {
            this.process_notification([
                {
                    callback_sent_at: new Date(),
                    callback_response_at: new Date(),
                    callback_response_status_code: 200,
                },
            ])
        },
        check_simulation() {
            axios
                .get(
                    utils.urlJoin(
                        this.$config.VALUATION_API_URL,
                        `request/${this.$store.state.ersStore.request_reference}/notifications`
                    )
                )
                .then((response) => {
                    let notifications = response.data.filter(
                        (notification) => notification.name === 'submission'
                    )
                    notifications.forEach((notification) => {
                        notification.created_at = new Date(notification.created_at)
                    })
                    const frontend_submission_time = this.$store.state.ersStore
                        .frontend_submission_time
                    if (frontend_submission_time) {
                        // filter notifications that were created after the submission
                        notifications = notifications.filter(
                            (notifications) =>
                                notifications.created_at > frontend_submission_time
                        )
                    }
                    notifications = notifications.sort(
                        (a, b) => a.created_at - b.created_at
                    )
                    this.process_notification(notifications)
                })
        },
        check_queue() {
            axios
                .put(
                    utils.urlJoin(
                        this.$config.VALUATION_API_URL,
                        `/notification/${this.notification_ref}/resend`
                    )
                )
                .then((response) => {
                    this.process_queue(response.data)
                })
                .catch((e) => {
                    console.log({ e })
                    if (e.response.data.status) {
                        this.process_queue(e.response.data)
                    }
                })
        },
        process_queue(queue) {
            if (
                ['notification_in_queue', 'notification_pending'].includes(queue.status)
            ) {
                this.simulation.status = 'pending'

                setTimeout(
                    !!queue.wait_time ? this.check_queue : this.check_simulation,
                    5000
                )

                this.simulation.wait_time = queue.wait_time
            } else if (['bad_request', 'notification_sent'].includes(queue.status)) {
                this.check_simulation()
            } else if (['server_error', 'notification_expired'].includes(queue.status)) {
                this.simulation.status = 'failed'
                this.open_snackbar(this.simulation.status)
            } else {
                this.simulation.status = 'pending'
                this.check_simulation()
            }
        },
        process_notification(notifications) {
            if (notifications.length === 0) {
                setTimeout(this.check_simulation, 500)
                return
            }
            let last_notification = notifications[notifications.length - 1]

            if (last_notification.callback_sent_at) {
                this.simulation.started_at = new Date(last_notification.callback_sent_at)
                // this.simulation.status = 'started'
            }

            if (
                last_notification.callback_response_at &&
                new Date(last_notification.callback_response_at) >
                    new Date(last_notification.callback_sent_at)
            ) {
                this.simulation.finished_at = new Date(
                    last_notification.callback_response_at
                )

                if (last_notification.callback_response_status_code === 200) {
                    this.simulation.status = 'finished'
                    this.notification_ref = last_notification.ref
                    this.open_snackbar(this.simulation.status)
                } else if (
                    this.retryCodes.includes(
                        last_notification.callback_response_status_code
                    )
                ) {
                    this.notification_ref = last_notification.ref
                    this.check_queue()
                } else {
                    this.simulation.status = 'failed'
                    this.open_snackbar(this.simulation.status)
                }
            } else {
                this.notification_ref = last_notification.ref
                this.check_queue()
            }
        },
        open_snackbar(status) {
            if (this.$vssWidth > 768) return
            if (status === 'finished') {
                this.$buefy.snackbar.open({
                    message: this.$t('processing_page.results_ready'),
                    type: 'is-primary',
                    position: 'is-bottom',
                    actionText: 'Back to top',
                    indefinite: true,
                    onAction: () => {
                        window.scrollTo(0, 0)
                    },
                    cancelText: 'OK',
                })
            } else if (['failed'].includes(status)) {
                this.$buefy.snackbar.open({
                    message: this.$t('processing_page.something_went_wrong'),
                    type: 'is-primary',
                    position: 'is-bottom',
                    actionText: 'Back to the dashboard',
                    indefinite: true,
                    onAction: () => {
                        this.$router.push({
                            name: 'dashboard',
                        })
                    },
                    cancelText: 'OK',
                })
            }
        },
    },
}
</script>

<style scoped lang="scss">
.answer-item {
    display: grid;
    grid-template-columns: 42px auto;
    gap: 1rem;
    margin-bottom: 1rem;

    &_text {
        align-self: center;
        line-height: 15px;
    }
}

.sticky-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    * {
        text-align: center;
    }
}

.buttons-wrapper {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.renderer {
    width: 90vw !important;
    height: calc(90vw / 1.5) !important;
    @media screen and (min-width: 768px) {
        width: 40vw !important;
        height: calc(40vw / 1.5) !important;
    }
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
<style lang="scss">
@import '@/shared/assets/style/buefy.scss';
.pending-title {
    i {
        color: $primary;
        font-style: normal;
    }
}
</style>

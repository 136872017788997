<template>
    <div class="floorplan_layout">
        <div class="floorplan_layout-header">
            <h1 class="floorplan_layout-header-breadcrumbs">
                <span>Floors</span>
            </h1>
            <b-button
                type="is-primary"
                :icon-right="is_floors_empty ? 'plus' : 'pencil'"
                @click="openFloorsModal"
            >
                {{ is_floors_empty ? 'Add floors' : 'Edit floors' }}
            </b-button>
        </div>
        <empty-list v-if="is_floors_empty" type="floors" />
        <div v-else class="floors">
            <div
                v-for="(floor, i) in floors"
                :key="`floor_${i}`"
                class="floor"
                @click.self="goToFloor(floor.level)"
            >
                <div class="floor-left_side">
                    <h2>Floor {{ floor.level }}</h2>
                    <div class="floor-rooms_badge">
                        <h3>{{ floor.rooms.length }}</h3>
                    </div>
                </div>
                <div class="floor-right_side">
                    <span class="floor-area_field">
                        <label>{{ $t('features.f_living_area') }}</label>
                        <b-input v-model="floors[i].living_area" type="number" />
                    </span>
                    <icon-button icon="delete" @click="openDeleteModal(i)" />
                    <img :src="chevron" class="chevron" />
                </div>
            </div>
        </div>
        <b-modal
            v-if="is_delete_modal_open"
            v-model="is_delete_modal_open"
            destroy-on-hide
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            @close="closeDeleteModal"
        >
            <div class="delete_modal">
                <div class="delete_modal-header">
                    <h4 class="delete_modal-header-title">
                        Delete floor {{ floors[deletingFloor].level }}
                    </h4>
                </div>
                <div v-if="deletingRooms.length" class="delete_modal-content">
                    <h3>Please select where to transfer the deleted floor's room</h3>
                    <div class="floors">
                        <div
                            class="floor"
                            :class="{ 'floor--active': targetFloorIndex === null }"
                            @click="setTargetFloor(null)"
                        >
                            <div class="floor-left_side">
                                <h2>
                                    Delete floor and attached rooms without transferring
                                    them
                                </h2>
                            </div>
                        </div>
                        <template v-for="(floor, i) in floors">
                            <div
                                v-if="i !== deletingFloor"
                                :key="`floor_${i}`"
                                class="floor"
                                :class="{ 'floor--active': targetFloorIndex === i }"
                                @click="setTargetFloor(i)"
                            >
                                <div class="floor-left_side">
                                    <h2>
                                        {{ newFloorLevel(floor) }}
                                    </h2>
                                    <div class="floor-rooms_badge">
                                        <h3>{{ floor.rooms.length }}</h3>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="delete_modal-footer">
                    <b-button @click="closeDeleteModal">Cancel</b-button>
                    <b-button type="is-primary" @click="deleteFloor">
                        Delete floor
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-if="is_floors_modal_open"
            v-model="is_floors_modal_open"
            destroy-on-hide
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            class="modal"
            @close="closeFloorsModal"
        >
            <div class="floors_modal">
                <div class="floors_modal-header">
                    <h4 class="floors_modal-header-title">
                        {{ is_floors_empty ? 'Add floors' : 'Edit floors' }}
                    </h4>
                </div>
                <div class="floors_modal-content">
                    <b-field :label="$t('features.f_bottom_floor')">
                        <b-input v-model="f_bottom_floor" type="number" />
                    </b-field>
                    <b-field :label="$t('features.f_top_floor')">
                        <b-input v-model="f_top_floor" type="number" />
                    </b-field>
                </div>
                <div class="floors_modal-footer">
                    <b-button @click="closeFloorsModal">Cancel</b-button>
                    <b-button type="is-primary" @click="confirmFloors">Save</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import IconButton from '@/components/elements/IconButton.vue'
import EmptyList from './EmptyList.vue'
import utils from '@/shared/plugins/utils'
import chevron from '@/shared/assets/icons/chevron.svg'

export default {
    name: 'Floors',
    components: {
        EmptyList,
        IconButton,
    },
    data: () => ({
        f_bottom_floor: null,
        f_top_floor: null,
        floors: [],
        is_floors_modal_open: false,
        deletingFloor: null,
        targetFloorIndex: null,
        deletingRooms: [],
        is_delete_modal_open: false,
        chevron,
    }),
    computed: {
        ...mapGetters('valuationStore/Address', ['getOVMFeatures', 'getFeatures']),
        is_floors_empty() {
            return Array.isArray(this.floors) ? !this.floors.length : true
        },
    },
    mounted() {
        this.floors = this.getOVMFeatures.floors || []
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_OVM_FEATURES', 'SET_FEATURES']),
        newFloorLevel(floor) {
            let deletingLevel = this.floors[this.deletingFloor].level
            let newLevel = null
            if (deletingLevel < 0 && floor.level < deletingLevel) {
                newLevel = floor.level + 1
            } else if (deletingLevel >= 0 && floor.level > deletingLevel) {
                newLevel = floor.level - 1
            }

            let returnStr = 'Floor '
            if (typeof newLevel === 'number') {
                returnStr += `${newLevel} (previously Floor ${floor.level})`
            } else {
                returnStr += floor.level
            }
            return returnStr
        },
        openFloorsModal() {
            this.f_top_floor = this.getFeatures.f_top_floor
            this.f_bottom_floor = this.getFeatures.f_bottom_floor
            this.is_floors_modal_open = true
        },
        closeFloorsModal() {
            this.f_top_floor = null
            this.f_bottom_floor = null
            this.is_floors_modal_open = false
        },
        confirmFloors() {
            let floors
            if (this.floors.length) {
                floors = this.floors
                floors.sort((a, b) => a.level - b.level)
                floors = floors.filter(
                    (floor) =>
                        floor.level >= +this.f_bottom_floor &&
                        floor.level <= +this.f_top_floor
                )
                let previousFloors = []

                for (let i = +this.f_bottom_floor; i < floors[0].level; i++) {
                    previousFloors.push({
                        level: i,
                        rooms: [],
                        living_area: 0,
                    })
                }

                floors = [...previousFloors, ...floors]

                for (
                    let i = floors[floors.length - 1].level;
                    i < +this.f_top_floor;
                    i++
                ) {
                    floors.push({
                        level: i,
                        rooms: [],
                        living_area: 0,
                    })
                }
            } else {
                floors = []

                for (let i = +this.f_bottom_floor; i <= +this.f_top_floor; i++) {
                    floors.push({
                        level: i,
                        rooms: [],
                        living_area: 0,
                    })
                }
            }

            this.SET_FEATURES({
                f_bottom_floor: +this.f_bottom_floor,
                f_top_floor: +this.f_top_floor,
            })
            this.SET_OVM_FEATURES({
                floors,
            })
            this.floors = floors
            this.is_floors_modal_open = false
        },
        goToFloor(floor) {
            this.$router.push({
                name: 'valuation.valuer-app.interior.floorplan.rooms',
                query: {
                    ...this.$route.query,
                    floor,
                },
            })
        },
        openDeleteModal(index) {
            this.deletingFloor = index
            let rooms = []

            if (Array.isArray(this.floors[index].rooms)) {
                rooms.push(...this.floors[index].rooms)
            }

            this.deletingRooms = rooms
            this.is_delete_modal_open = true
        },
        closeDeleteModal() {
            this.is_delete_modal_open = false
            this.deletingFloor = null
            this.deletingRooms = []
            this.targetFloorIndex = null
        },
        setTargetFloor(index) {
            this.targetFloorIndex = index
        },
        deleteFloor() {
            let level = this.floors[this.deletingFloor].level
            let floors = this.floors

            if (typeof this.targetFloorIndex === 'number') {
                floors[this.targetFloorIndex].rooms.push(...this.deletingRooms)
            }

            floors.splice(this.deletingFloor, 1)

            let min, max

            if (floors.length) {
                min = max = floors[0].level
                floors.forEach((floor) => {
                    if (level < 0 && floor.level < level) {
                        floor.level++
                    } else if (level >= 0 && floor.level > level) {
                        floor.level--
                    }
                    min = Math.min(floor.level, min)
                    max = Math.max(floor.level, max)
                })
            } else {
                min = max = null
            }

            this.SET_OVM_FEATURES({
                floors,
            })
            this.SET_FEATURES({
                f_bottom_floor: min,
                f_top_floor: max,
            })
            this.floors = floors

            this.closeDeleteModal()
        },
    },
}
</script>
<style lang="scss" scoped>
.floors {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .floor {
        display: flex;
        flex-direction: row;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #d6e2e9;
        background: #fff;
        cursor: pointer;
        box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
            0px 4px 6px 0px rgba(0, 24, 55, 0.06);
        transition: all 0.2s ease-out;

        &:hover,
        &--active {
            box-shadow: 0px 1px 2px 0px rgba(0, 156, 255, 0.2),
                0px 4px 6px 0px rgba(0, 156, 255, 0.2);
            border-color: #009cff;

            h2 {
                color: #009cff !important;
            }
        }

        & > * {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        &-left_side {
            gap: 12px;

            h2 {
                color: #001837;
                font-size: 18px;
                font-weight: 700;
                transition: all 0.2s ease-out;
            }
        }

        &-rooms_badge {
            display: flex;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            background: rgba(0, 156, 255, 0.2);

            h3 {
                color: #009cff;
                font-size: 12px;
                font-weight: 700;
            }
        }

        &-area_field {
            display: flex;
            gap: 12px;
            align-items: center;

            label {
                color: #798da6;
                font-size: 12px;
                font-weight: 500;
            }
        }

        .chevron {
            transform-origin: center;
            transform: rotate(-90deg);
            height: 8px;
        }
    }
}
.floors_modal,
.delete_modal {
    height: fit-content;
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);

    & > * {
        padding: 24px;
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 24px;

        &-title {
            color: #001837;
            font-size: 20px;
            font-weight: 700;
            flex-grow: 1;
        }
    }

    &-content {
        border-top: 1px solid #d6e2e9;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        flex-shrink: 1;
        overflow: scroll;
    }

    &-footer {
        border-top: 1px solid #d6e2e9;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
}

.delete_modal {
    &-content {
        display: flex;
        flex-direction: column;
    }

    .floors {
        gap: 12px;

        .floor {
            padding: 12px;
            gap: 40px;

            & > * {
                gap: 8px;
            }

            &-left_side {
                gap: 6px;

                h2 {
                    font-size: 12px;
                }
            }

            &-rooms_badge {
                padding: 2px 8px;
                border-radius: 24px;

                h3 {
                    font-size: 8px;
                }
            }

            &-area_field {
                gap: 8px;

                label {
                    font-size: 8px;
                }
            }
        }
    }
}
</style>

<template>
    <div id="cronofy-calendar-sync"></div>
</template>

<script>
export default {
    name: 'CronofySync',
    props: {
        elementToken: {
            default: null,
            type: String,
        },
        authorizationUrl: {
            required: true,
            type: String,
        },
    },
    mounted() {
        // console.log('cronofy sync mounted', this.authorizationUrl)
        CronofyElements.CalendarSync({
            target_id: 'cronofy-calendar-sync',
            data_center: 'de',
            element_token: this.elementToken,
            authorization_url: this.authorizationUrl,
            styles: {
                prefix: 'CalendarSync',
            },
        })
    },
}
</script>

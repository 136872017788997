<template>
    <div id="sidebar">
        <sidebar-summary />
        <sidebar-section
            title="General information"
            :active="isActiveRoute('general')"
            @click="goTo('general')"
        />
        <sidebar-section
            title="Exterior"
            :active="isActiveRoute('exterior')"
            @click="goTo('exterior')"
        />
        <sidebar-section
            title="Interior"
            :active="isActiveRoute('interior')"
            @click="goTo('interior')"
        />
        <sidebar-section
            v-if="is_apartment"
            title="Common parts"
            :active="isActiveRoute('commons')"
            @click="goTo('commons')"
        />
        <sidebar-section
            title="Surroundings"
            :active="isActiveRoute('surroundings')"
            @click="goTo('surroundings')"
        />
        <!-- <sidebar-section title="Renovation" /> -->
        <sidebar-section
            title="Valuation"
            :active="isActiveRoute('value')"
            @click="goTo('value')"
        />
        <clear-button class="sidebar_buttn" @click="saveVal">
            <h5>Save valuation</h5>
            <h6>Does not submit</h6>
        </clear-button>
        <clear-button class="sidebar_buttn submit_buttn" @click="() => saveVal(true)">
            <h5>Submit valuation</h5>
        </clear-button>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SidebarSummary from './Summary.vue'
import SidebarSection from './Section.vue'
import utils from '@/shared/plugins/utils'
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'Sidebar',
    components: {
        SidebarSummary,
        SidebarSection,
        ClearButton,
    },
    computed: {
        ...mapGetters('valuationStore/Address', [
            'getFeatures',
            'getValuation',
            'getDVMFeatures',
            'getOVMFeatures',
        ]),
        ...mapGetters('valuationStore', ['getRequestRef']),
        activeRoute() {
            return this.$route.name.toLowerCase()
        },
        is_apartment() {
            return utils.is_apartment(this.getFeatures.f_building_type)
        },
    },
    methods: {
        isActiveRoute(name) {
            return this.activeRoute.startsWith('valuation.valuer-app.' + name)
        },
        goTo(target) {
            let fullTarget = 'valuation.valuer-app.' + target

            if (fullTarget.toLowerCase() !== this.activeRoute) {
                let { params, query } = this.$route
                this.$router.push({ name: fullTarget, params, query })
            }

            document
                .getElementById('valuer_tool-content')
                .scroll({ top: 0, behavior: 'smooth' })
        },
        saveVal(submit = false) {
            this.$axios
                .patch(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'request',
                        this.getRequestRef,
                    ]),
                    {
                        valuation: this.getValuation,
                        dvm_features: this.getDVMFeatures,
                        ovm_info: this.getOVMFeatures,
                        features: this.getFeatures,
                    }
                )
                .then((res) => {
                    if (submit) {
                        this.$axios
                            .put(
                                utils.urlJoin(this.$config.VALUATION_API_URL, [
                                    'request',
                                    this.getRequestRef,
                                    'status',
                                ]),
                                null,
                                { params: { action: 'submit-ovm-valuation' } }
                            )
                            .then(() => {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: 'Valuation submitted!',
                                    position: 'is-bottom',
                                    type: 'is-success',
                                })
                            })
                    } else {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Valuation saved!',
                            position: 'is-bottom',
                            type: 'is-success',
                        })
                    }
                })
        },
    },
}
</script>
<style lang="scss" scoped>
#sidebar {
    width: 300px;
    height: 100%;
    border-right: 1px solid rgba(214, 226, 233, 1);
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;

    .sidebar_buttn {
        display: flex;
        flex-direction: column;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 5px;
        background: #001837;
        width: calc(100% - 32px);
        margin: 16px;
        color: white;

        h5 {
            font-size: 14px;
            font-weight: 700;
            margin: 0;
        }

        h6 {
            font-size: 10px;
            font-weight: 500;
            margin: 0;
        }

        &:hover {
            background: #001837cf;
        }

        &.submit_buttn {
            background: #009cff;

            &:hover {
                background: #009cffcf;
            }
        }
    }
}
</style>

<i18n>
{
  "nl-BE": {
    "session_expiry_warning": {
        "text": "Uw sessie zal aflopen in {countdown} seconden.",
        "cta": "Verleng sessie"
    }
  },
  "en-BE": {
    "session_expiry_warning": {
        "text": "Your session will expire in {countdown} seconds.",
        "cta": "Continue session"
    }
  },
  "fr-BE": {
    "session_expiry_warning": {
        "text": "Votre session va expirer dans {countdown} secondes.",
        "cta": "Continuer la session"
    }
  }
}
</i18n>

<template>
    <div id="app">
        <div :class="{ offline: !online }">
            <navbar
                ref="navbar"
                :email="$store.state.auth.email"
                :fixed="$route.name === 'report'"
            ></navbar>
            <div
                class="app-content"
                :class="{ 'wide-side': hasWideSide, 'no-menu': !showMenu }"
            >
                <sidemenu v-if="showMenu" :is-wide="hasWideSide" />
                <router-view :key="$route.name === 'dvm' && $route.fullPath" />
            </div>
            <ribbon v-if="$store.state.auth.showTimeoutRibbon" type="is-warning">
                {{ $t('session_expiry_warning.text', { countdown: state.countdown }) }}
                <b-button @click="refreshJwt">
                    {{ $t('session_expiry_warning.cta') }}
                </b-button>
            </ribbon>
        </div>
        <b-notification
            v-if="!online"
            id="offline-message"
            type="is-danger is-light"
            :closable="false"
            :indefinite="true"
        >
            You are currently offline. Wait until you recover connectivity to continue.
        </b-notification>
    </div>
</template>

<script>
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

import Sidemenu from '@/components/common/Sidemenu'
import Navbar from '@/components/common/Navbar.vue'
import Ribbon from '@/components/common/Ribbon.vue'

export default {
    components: {
        Navbar,
        Ribbon,
        Sidemenu,
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Rock.estate App',
        // all titles will be injected into this template
        // titleTemplate: '%s | Rockestate estimator',
    },
    data() {
        return {
            state: {
                countdown: null,
                countdownInterval: null,
            },
            online: true,
        }
    },
    computed: {
        hasWideSide() {
            return ['appDashboard'].includes(this.$route.name)
        },
        showMenu() {
            return (
                !['login'].includes(this.$route.name) &&
                (!this.$config.CHECK_LOGIN || this.$store.state.auth.authenticated)
            )
        },
    },
    watch: {
        '$store.state.auth.showTimeoutRibbon'(val) {
            if (val === true) {
                this.startCountdown()
            } else {
                clearInterval(this.state.countdownInterval)
            }
        },
    },
    mounted() {
        console.log('Rockestate app ' + process.env.VUE_APP_VERSION)
        for (const e of ['online', 'offline', 'load']) {
            window.addEventListener(e, this.check_online)
        }

        // Check that the frontend verion matches what the backend thinks it should be
        // If it doesn't, refresh with the url containing the version number
        // This should defeat aggressive corporate caches.
        if (this.$config.AUTOREFRESH_STALE_FRONTEND) {
            axios
                .get(utils.urlJoin(this.$config.AUTH_API_URL, 'user'), {
                    params: { frontend_version: process.env.VUE_APP_VERSION_FULL },
                })
                .then(function(response) {
                    let frontend_version = response.data.frontend_version
                    if (
                        frontend_version &&
                        // Using numeric localCompare to get v1.2.0-8 < v1.2.0-10 < v2 < v10

                        frontend_version.localeCompare(
                            process.env.VUE_APP_VERSION_FULL,
                            'en',
                            { numeric: true }
                        ) === 1
                    ) {
                        console.warn(
                            `current frontend version ${process.env.VUE_APP_VERSION_FULL}` +
                                ` expecting ${frontend_version}`
                        )
                        if (window.location.toString().indexOf(frontend_version) === -1) {
                            console.warn(` ==> refreshing`)
                            window.location.replace(
                                [
                                    location.protocol,
                                    '//',
                                    location.host,
                                    location.pathname,
                                    '?v=',
                                    frontend_version,
                                ].join('') // https://stackoverflow.com/a/5817566
                            )
                        } else {
                            console.warn(' version mismatch, even after refresh')
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        if (this.$store.state.auth.showTimeoutRibbon) {
            this.startCountdown()
        }
    },
    beforeUnmount() {
        for (const e of ['online', 'offline', 'load']) {
            window.removeEventListener(e, this.check_online)
        }
    },
    methods: {
        find_cookie(cname) {
            var name = cname + '='
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i]
                while (c.charAt(0) == ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(name) == 0) {
                    var cookie_value = c.substring(name.length, c.length)
                    return cookie_value
                }
            }
            return null
        },
        set_cookie(cname, cvalue, exdays) {
            var d = new Date()
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
            var expires = 'expires=' + d.toUTCString()
            document.cookie = cname + '=' + cvalue + '; ' + expires + '; Path=/;'
        },
        refreshJwt() {
            this.$store.dispatch('auth/refresh_token')
        },
        startCountdown() {
            const self = this
            const jwt_ = utils.getCookie('re-jwt')
            console.log(jwt_)
            if (jwt_ && jwt_ !== '') {
                const parsed_ = utils.parseJwt(jwt_)
                console.log(parsed_)
                const { exp } = parsed_
                self.state.countdown = exp - Math.round(Date.now() / 1000)
            }
            this.state.countdownInterval = setInterval(() => {
                const jwt_ = utils.getCookie('re-jwt')
                if (jwt_ && jwt_ !== '') {
                    const parsed_ = utils.parseJwt(jwt_)
                    const { exp } = parsed_
                    self.state.countdown = exp - Math.round(Date.now() / 1000)
                }
            }, 1000)
        },
        check_online() {
            this.online = navigator.onLine
        },
    },
}
</script>

<style lang="scss">
* {
    font-family: 'Satoshi', sans-serif !important;
}

#app {
    -webkit-print-color-adjust: exact;
    min-height: 100vh;
    .offline {
        filter: blur(0.3rem);
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

#offline-message {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
.app-content {
    min-height: calc(100vh - 52px);
    display: grid;
    grid-template: 1fr / 64px auto;

    &.wide-side {
        grid-template-columns: auto 1fr;
    }

    &.no-menu {
        grid-template-columns: auto;
    }
}

// Make disabled fields readable on Safari. Necessary to have this long list because of Buefy
.select fieldset[disabled] select,
.select select[disabled],
.taginput [disabled].taginput-container.is-focusable,
.taginput fieldset[disabled] .taginput-container.is-focusable,
[disabled].button,
[disabled].file-cta,
[disabled].file-name,
[disabled].input,
[disabled].pagination-ellipsis,
[disabled].pagination-link,
[disabled].pagination-next,
[disabled].pagination-previous,
[disabled].textarea,
fieldset[disabled] .button,
fieldset[disabled] .file-cta,
fieldset[disabled] .file-name,
fieldset[disabled] .input,
fieldset[disabled] .pagination-ellipsis,
fieldset[disabled] .pagination-link,
fieldset[disabled] .pagination-next,
fieldset[disabled] .pagination-previous,
fieldset[disabled] .select select,
fieldset[disabled] .taginput .taginput-container.is-focusable,
fieldset[disabled] .textarea {
    -webkit-text-fill-color: #2a2a2a;
    opacity: 1;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title-with-tooltip',{attrs:{"label":_vm.$t('features.building_class_tooltip')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('features.building_class'))+" ")]},proxy:true}])}),_c('div',{staticClass:"is-flex choose-type-group",class:[
            _vm.$vssWidth <= 1123 && 'is-flex-direction-column is-justify-content-center',
        ]},[_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[_c('div',{ref:"box",class:{
                    'choose-type-box': true,
                    'not-choosen': _vm.building_class !== 'old',
                    chosen: _vm.building_class === 'old',
                    disable: !_vm.isDraft,
                },on:{"click":function($event){return _vm.change_building_class('old')}}},[_c('div',{staticClass:"type-icon home"},[_c('svg-icon',{attrs:{"icon":"home","height":"50","scale":"1.4","color":"#798DA6"}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('value.existing_building'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('value.existing_building_tooltip', { current_year: _vm.current_year - 1, }))+" ")])])])]),_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[_c('div',{ref:"box",class:{
                    'choose-type-box': true,
                    'not-choosen': _vm.building_class !== 'new',
                    chosen: _vm.building_class === 'new',
                    disable: !_vm.isDraft,
                },on:{"click":function($event){return _vm.change_building_class('new')}}},[_c('div',{staticClass:"type-icon with-stroke"},[_c('svg-icon',{attrs:{"icon":"new-construction","height":"50","scale":"1.4","color":"#798DA6","fill":false}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('value.new_building'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('value.new_building_tooltip', { current_year: _vm.current_year - 1, }))+" ")])])])]),_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[(_vm.allowedBuildingTypes.includes('construction_plot'))?_c('div',{staticClass:"choose-type-box",class:{
                    chosen: _vm.value === 'construction_plot',
                    'not-choosen': _vm.value !== 'construction_plot',
                    disable: !_vm.isDraft,
                },on:{"click":function($event){return _vm.change_building_type('construction_plot')}}},[_c('div',{staticClass:"type-icon home"},[_c('svg-icon',{attrs:{"icon":"plot","height":"50","scale":"1","color":"#798DA6","fill":false}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('value.construction_plot'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('value.construction_plot_tooltip'))+" ")])])]):_vm._e()])],1),(_vm.building_class !== null)?_c('title-with-tooltip',{attrs:{"label":_vm.$t('features.f_building_type_tooltip')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('features.f_building_type'))+" ")]},proxy:true}],null,false,4149173408)}):_vm._e(),(_vm.building_class !== null)?_c('div',{staticClass:"is-flex choose-type-group",class:[
            _vm.$vssWidth <= 1123 && 'is-flex-direction-column is-justify-content-center',
        ]},_vm._l(([
                `${_vm.building_type_prefix}house`,
                `${_vm.building_type_prefix}apartment`,
            ]),function(building_type){return _c('transition',{key:building_type,attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[(_vm.allowedBuildingTypes.includes(building_type))?_c('div',{ref:"box",refInFor:true,class:{
                    'choose-type-box': true,
                    'not-choosen': _vm.value !== building_type,
                    chosen: _vm.value === building_type,
                    disable: !_vm.isDraft,
                },on:{"click":function($event){return _vm.$emit('update:buildingType', building_type)}}},[_c('div',{staticClass:"type-icon house"},[(['house', 'new_house'].includes(building_type))?_c('svg-icon',{attrs:{"icon":`${_vm.building_type_prefix}home`,"height":"50","scale":"1.4","color":"#798DA6"}}):_vm._e(),(['apartment', 'new_apartment'].includes(building_type))?_c('svg-icon',{attrs:{"icon":`${_vm.building_type_prefix}apartment`,"height":"50","scale":"1.4","color":"#798DA6"}}):_vm._e()],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(`value.${building_type}`))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t(`value.${building_type}_tooltip`))+" ")])])]):_vm._e()])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
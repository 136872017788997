var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general_page valuer_tool-default-layout"},[(!_vm.getFullValuationStore.Address.isLoaded)?_c('b-loading',{model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}}):[(
                _vm.getFullValuationStore.Address.features &&
                    _vm.getFullValuationStore.Address.features.building_id
            )?_c('div',{staticClass:"valuer_tool-default-layout-map_container"},[_c('re-map',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFullValuationStore.Address.features.building_id,"parcel-ids":_vm.getFullValuationStore.Address.features.parcel_ids,"level":_vm.getFullValuationStore.Address.features.level,"region":_vm.getFullValuationStore.Address.features.region,"center":{
                    lat: _vm.getFullValuationStore.Address.features.f_lat,
                    lng: _vm.getFullValuationStore.Address.features.f_lng,
                },"map-scene2d":_vm.getFullValuationStore.Address.map_scene2d,"read-only":true,"focus-layer":'building',"layers-control-visible":false,"options":{
                    zoomControl: true,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    attributionControl: false,
                    scrollWheelZoom: false,
                    doubleClickZoom: true,
                    boxZoom: true,
                    dragging: true,
                },"aerial-tile-visible":false,"cadastral-tile-visible":false},on:{"update:mapScene2d":function($event){return _vm.SET_MAP_SCENE2D($event)}}})],1):_vm._e(),_c('div',{staticClass:"valuer_tool-default-layout-grid"},[_c('basic-info'),_c('state'),_c('size'),(_vm.is_apartment)?_c('apartment-features'):_vm._e(),_c('value'),_c('ref-points')],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }